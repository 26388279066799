/**********************************************************************************************************
 *   GLOBAL IMPORTS
 **********************************************************************************************************/
import axios from 'axios';
import { batchActions } from 'redux-batched-actions';
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { LOGIN_INITIAL_STATE, loginTokenCheck, requestAccounts, requestTwoFactor } from 'containers/login/action';
import { API as ACCOUNT } from 'utilities/api/account';
import { API as DASHBOARD } from 'utilities/api/dashboard';
import { API as LOGIN } from 'utilities/api/login';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { loginGetUserSuccessSideEffects } from './methods';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const APP_USER_RESET = 'app/APP_USER_RESET';
export const APP_MOUNTING = 'app/APP_MOUNTING';
export const APP_DISPLAY_STATES = 'app/APP_DISPLAY_STATES';
export const APP_UPDATE_USER = 'app/APP_UPDATE_USER';

export const APP_USER_REQUEST = 'app/APP_USER_REQUEST';
export const APP_USER_SUCCESS = 'app/APP_USER_SUCCESS';
export const APP_USER_ERROR = 'app/APP_USER_ERROR';

export const APP_COUNTRIES_REQUEST = 'app/APP_COUNTRIES_REQUEST';
export const APP_COUNTRIES_SUCCESS = 'app/APP_COUNTRIES_SUCCESS';
export const APP_COUNTRIES_ERROR = 'app/APP_COUNTRIES_ERROR';

export const APP_LOCAL_STATES_REQUEST = 'app/APP_LOCAL_STATES_REQUEST';
export const APP_LOCAL_STATES_SUCCESS = 'app/APP_LOCAL_STATES_SUCCESS';
export const APP_LOCAL_STATES_ERROR = 'app/APP_LOCAL_STATES_ERROR';

export const APP_ALERT_BANNER_REQUEST = 'app/APP_ALERT_BANNER_REQUEST';
export const APP_ALERT_BANNER_SUCCESS = 'app/APP_ALERT_BANNER_SUCCESS';

export const APP_CHANGE_LIVECHAT_VISIBILITY = 'app/APP_CHANGE_LIVECHAT_VISIBILITY';

export const APP_CLOSE_MODAL = 'app/APP_CLOSE_MODAL';
export const APP_OPEN_MODAL = 'app/APP_OPEN_MODAL';

/**********************************************************************************************************
 *   ACTIONS
 **********************************************************************************************************/

export const getCountries = () => {
    const { dispatch } = store;
    dispatch({
        type: APP_COUNTRIES_REQUEST
    });

    ACCOUNT.account.GET.country()
        .then((response) => {
            const app_countries_data = getDataFromSuccessResponse(response);

            dispatch({
                type: APP_COUNTRIES_SUCCESS,
                app_countries_data
            });
        })
        .catch((err) => {
            const app_countries_error = getErrorFromFailResponse(err);
            pushNotification(app_countries_error);

            dispatch({
                type: APP_COUNTRIES_ERROR
            });
        });
};

export const getLocalStates = () => {
    const { dispatch } = store;
    dispatch({
        type: APP_LOCAL_STATES_REQUEST
    });
    ACCOUNT.account.GET.states()
        .then((response) => {
            const rawStatesData = getDataFromSuccessResponse(response);
            const processedOptions = {
                au: [],
                nz: []
            };
            rawStatesData.forEach((item) => {
                const {
                    attributes: { country_code, state, state_code }
                } = item;

                processedOptions[country_code.toLowerCase()].push({
                    label: state,
                    value: state_code
                });
            });

            const app_local_states_data = {
                rawStatesData,
                processedOptions
            };
            dispatch({
                type: APP_LOCAL_STATES_SUCCESS,
                app_local_states_data
            });
        })
        .catch(() => {
            dispatch({
                type: APP_LOCAL_STATES_ERROR
            });
        });
};

/**
 * @param {{ onSuccess?: Function, onError?: Function }} [options]
 */
export const loadAppConfig = (options) => {
    const { dispatch } = store;

    dispatch({
        type: APP_MOUNTING,
        app_mounting: true
    });

    const loginTokenCheckSuccessCallback = () => {
        // Every time we refresh the page, we always want to fetch the user regardless of how many accounts are in the account list
        requestAccounts(true);
        options?.onSuccess?.();
    };

    const loginTokenCheckErrorCallback = (error) => {
        const response = getErrorFromFailResponse(error);

        if (response && response.code === 'ERR_ACCESS_TOKEN' && response.status === 401) {
            dispatch(
                batchActions([
                    {
                        type: APP_USER_RESET
                    },

                    {
                        type: APP_MOUNTING,
                        app_mounting: false
                    }
                ])
            );
        } else if (response && response.code === 'ERR_VERIFICATION_REQUIRED' && response.status === 401) {
            dispatch(requestTwoFactor(true));
        }

        options?.onError?.(error);
    };

    loginTokenCheck({ successCallback: loginTokenCheckSuccessCallback, errorCallback: loginTokenCheckErrorCallback });
};

/**
 *
 * @param {{ successCallback?: Function, errorCallback?: Function, finallyCallback?: Function }} parameters
 */
export function loginGetUser({ successCallback, errorCallback, finallyCallback } = {}) {
    const { dispatch } = store;

    dispatch({
        type: APP_USER_REQUEST
    });

    return LOGIN.user
        .get()
        .then((userResponse) => {
            const userData = getDataFromSuccessResponse(userResponse);

            dispatch({
                type: APP_USER_SUCCESS,
                app_user_data: {
                    ...userData.attributes,
                    id: userData.id
                }
            });

            loginGetUserSuccessSideEffects();
            successCallback?.(userResponse);
        })
        .catch((error) => {
            const errResponse = getErrorFromFailResponse(error);
            pushNotification(errResponse);
            dispatch({
                type: APP_USER_ERROR
            });

            errorCallback?.(error);
        })
        .finally(() => {
            finallyCallback?.();
        });
}

export const handleVPNLogout = (startBypassLogin) => {
    return (dispatch) => {
        axios({
            method: 'delete',
            url: '/api/logout'
        })
            .then(() => {
                dispatch({
                    type: APP_USER_RESET
                });
                startBypassLogin();
            })
            .catch((error) => {
                startBypassLogin();
            });
    };
};

export const handleStaffLogin = (params) => {
    const { dispatch } = store;
    const { token } = params;

    const handlePostBypass = (data) => {
        const { is_vpn, is_security_detail_completed, is_user_detail_completed, account_id } = data;

        dispatch({
            type: APP_UPDATE_USER,
            app_check_token_data: {
                is_vpn,
                is_user_detail_completed,
                is_security_detail_completed,
                account_id
            }
        });

        requestAccounts();

        function successCallback() {
            dispatch({
                type: APP_MOUNTING,
                app_mounting: false
            });
        }

        return loginGetUser({ successCallback });
    };

    const handleBypassLogin = () => {
        return ACCOUNT.account.GET.mercury
            .bypass(token)
            .then((response) => {
                const mercuryData = getDataFromSuccessResponse(response);
                const { attributes } = mercuryData;
                return handlePostBypass(attributes);
            })
            .catch(() => {
                handleVPNLogout(
                    () =>
                        void dispatch({
                            type: APP_MOUNTING,
                            app_mounting: false
                        })
                )(dispatch);
            });
    };

    return handleBypassLogin();
};

export const updateViewport = () => {
    const { dispatch } = store;

    const currentWidth = window.innerWidth;
    const xs = 480;
    const sm = 768;
    const md = 1024;
    const lg = 1440;
    let viewport = undefined;
    if (currentWidth <= xs) {
        viewport = 'xs';
    } else if (currentWidth > xs && currentWidth <= sm) {
        viewport = 'sm';
    } else if (currentWidth > sm && currentWidth <= md) {
        viewport = 'md';
    } else if (currentWidth > md && currentWidth <= lg) {
        viewport = 'lg';
    } else if (currentWidth > lg) {
        viewport = 'xl';
    }

    dispatch({
        type: APP_DISPLAY_STATES,
        app_viewport: viewport
    });
};

/**
 * This function is needed to make sure that the interceptor doesn't overwrite notifications that are more important. i.e. we don't need to show "Permission Denied", when we're already dispatching a notification about the reason why the permission is denied like we do with approve additional user
 */
function checkAllowErrorOverride(error) {
    /**
     * First remove any potential query parameters from the url
     */
    const url = error?.config?.url?.split('?')[0];

    /**
     * Can add additional switch inside the case statement to allow for `error.config.method` "get/post" etc...
     */
    switch (url) {
        case '/api/user/service-move/approve':
        case '/api/user/additional-user/approve':
            return false;
        default:
            return true;
    }
}

export const interceptInvalidToken = () => {
    const { dispatch } = store;

    axios.interceptors.response.use(undefined, (error) => {
        const intercept_invalid_token_error = getErrorFromFailResponse(error);

        if (
            intercept_invalid_token_error.status === 401 &&
            intercept_invalid_token_error.code === 'ERR_ACCESS_TOKEN' &&
            checkAllowErrorOverride(error)
        ) {
            dispatch(
                batchActions([
                    {
                        type: APP_USER_RESET
                    },
                    {
                        type: LOGIN_INITIAL_STATE
                    }
                ])
            );

            if (intercept_invalid_token_error.details === 'Permission denied. Session Expired.') {
                pushNotification(intercept_invalid_token_error, null, notificationScopes.GLOBAL);
            }
        }

        return Promise.reject(error);
    });
};

export const checkAlertBanner = () => {
    const { dispatch } = store;

    dispatch({
        type: APP_ALERT_BANNER_REQUEST
    });

    return DASHBOARD.dashboard.get
        .alert()
        .then((response) => {
            const app_alert_banner_data = getDataFromSuccessResponse(response);
            dispatch({
                type: APP_ALERT_BANNER_SUCCESS,
                app_alert_banner_data
            });
            return response;
        })
        .catch(() => {});
};

export const livechatVisibilityOptions = {
    MAXIMIZED: { visibility: 'maximized' },
    MINIMIZED: { visibility: 'minimized' }
};

export const changeLivechatVisibility = ({ visibility } = livechatVisibilityOptions.MINIMIZED) => {
    return (dispatch) => {
        dispatch({
            type: APP_CHANGE_LIVECHAT_VISIBILITY,
            app_livechat_visibility: visibility
        });
    };
};

/**
 * Modal related dispatch actions
 */
/**
 * This dispatch will cause the close actions of the modal to trigger before the modals open prop is set to false
 */
export function closeModalActions() {
    const { dispatch } = store;
    dispatch({
        type: APP_CLOSE_MODAL
    });
}

export function modalOpened() {
    const { dispatch } = store;
    dispatch({
        type: APP_OPEN_MODAL
    });
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useContext, useRef } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useClickAway } from 'utilities/hooks/useClickAway';
import { useSvelteStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PictureInPictureContext } from 'components/PictureInPicture/consts';
import './_PictureInPictureContent.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode | ((options: { contentRef: React.MutableRefObject<HTMLDivElement | null | undefined> }) => React.ReactNode);
 *      pictureInPictureContentRef?: React.MutableRefObject<HTMLDivElement | null | undefined>;
 * }>}
 */
const _PictureInPictureContent = ({ children, pictureInPictureContentRef }) => {
    /***** HOOKS *****/
    const contentRef = useRef(/** @type {React.MutableRefObject<HTMLDivElement | null | undefined>} */ null);
    const { stateStoreData } = useContext(PictureInPictureContext);
    const [pictureState, setPictureState] = useSvelteStore(stateStoreData.store);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** FUNCTIONS *****/
    /**
     * @type {(e: MouseEvent | FocusEvent) => void}
     */
    function handleClickAway(e) {
        if (pictureState === 'maximised') {
            if (e instanceof MouseEvent) {
                e.stopPropagation();
                setPictureState(isMobile ? 'hidden' : 'visible');
            }
        }
    }

    /***** HOOKS *****/
    useClickAway([contentRef], handleClickAway);

    /***** RENDER *****/
    return (
        <div
            className="PictureInPicture__content"
            ref={(ref) => {
                contentRef.current = ref;
                if (pictureInPictureContentRef?.current) {
                    pictureInPictureContentRef.current = ref;
                }
            }}
        >
            {typeof children === 'function' ? children({ contentRef }) : children}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _PictureInPictureContent };

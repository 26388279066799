/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Page.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PageComponent = React.FC<{
    className?: string;
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * After serving it's time in component prison, this component has been let out on paroll.
 * 
 * The Page component provides a container for holding page content with a standard max width and centered. In most 
 * circumstances, the sidebar page component should be used, however, when a sidebar is not required, this component
 * can be used to provide the default layout.
 */
export const Page: PageComponent = ({ children, className }) => {
    /***** RENDER *****/
    return (
        <div className={classNames('Page', className)}>
            <div className={classNames('Page__mainContent', 'Page__mainContent--fullWidth')}>{children}</div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Page;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainsListExpiry from 'containers/services/modules/domains/expiry';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import NXTable from 'components/NXTable';
import TableAccordion from 'components/NXTable/TableAccordion';
import { Accordion } from 'components/NXTable/TableAccordion/Accordion';
import StatusTag from 'components/StatusTag';
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useTppDomainsListQuery } from '..//queries/useTppDomainsListQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_tppDomainsList.scss';

export const legacyDomainsFaqLink = 'https://ventraip.com.au/faq/article/what-is-a-legacy-domain-and-what-does-this-mean-for-me/';

// TEMP: remove "more info" until we have FAQ's
// const legacyDomainsListCopyText = isMigrateTppDomainAvailable ? (
//     <>
//         Domains that have had their management transferred to VentraIP Australia as part of an acquisition but have NOT had their Registrar updated as
//         part of the transfer are classified as Legacy Domains. To unlock more management features, we encourage you to complete a Migration for these
//         domains. More information can be found{' '}
//         <Anchor href={legacyDomainsFaqLink} target="_blank">
//             here
//         </Anchor>
//         .
//     </>
// ) : (
//     <>
//         Domains that have had their management transferred to VentraIP Australia as part of an acquisition but have NOT had their Registrar updated as
//         part of the transfer are classified as Legacy Domains. More information can be found{' '}
//         <Anchor href={legacyDomainsFaqLink} target="_blank">
//             here
//         </Anchor>
//         .
//     </>
// );

const legacyDomainsListCopyText = (
    <>
        Domains that have had their management transferred to VentraIP Australia as part of an acquisition but have NOT had their Registrar updated as
        part of the transfer are classified as Legacy Domains. To unlock more management features, we encourage you to complete a Migration for these
        domains.
    </>
);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The TPP domains list
 */
function TPPDomainsList() {
    /***** HOOKS *****/
    const app_viewport = useSelector((state) => state.app.app_viewport);

    /***** QUERIES *****/
    const { data: tpp_domains_list_data, isLoading: isTppDomainsListLoading } = useTppDomainsListQuery();

    /***** RENDER HELPERS *****/
    const renderButtons = (domainId) => {
        const buttons = [
            <OutlineButton key={2} to={`/my-services/domains/legacy/${domainId}/general#overview`} size="medium">
                Manage
            </OutlineButton>
        ];

        buttons.unshift(
            <SolidButton key={1} to={`/my-services/domains/legacy/${domainId}/general#migrate`} size="medium">
                Migrate
            </SolidButton>
        );

        return buttons;
    };

    const getColumns = () => {
        if (app_viewport === 'md') return '180px 260px 1fr NXActions';
        return '330px 260px 1fr NXActions';
    };

    const getSafeData = (singleDomain) => {
        const { expiry_date, domain, status } = singleDomain?.attributes || {};

        return {
            id: singleDomain?.id || undefined,
            expiry_date: expiry_date || '',
            domain: domain || '',
            status: status || ''
        };
    };

    const renderTable = () => {
        // Mobile table
        if (['sm', 'xs'].includes(app_viewport))
            return (
                <Border all>
                    <Accordion.ToggleWrapper isLoading={isTppDomainsListLoading}>
                        {(tpp_domains_list_data || []).map((singleDomain, index) => {
                            const { id, expiry_date, domain, status } = getSafeData(singleDomain);

                            return (
                                <Accordion
                                    key={index}
                                    index={index}
                                    content={
                                        <NXTable.Actions.Base className="tppDomainsList__mobileContent">{renderButtons(id)}</NXTable.Actions.Base>
                                    }
                                >
                                    <TableAccordion.Child>
                                        <NXTable.Header.Title>Domain</NXTable.Header.Title>
                                        <Text black size--s bold>
                                            {domain}
                                        </Text>
                                    </TableAccordion.Child>
                                    <TableAccordion.Child>
                                        <NXTable.Header.Title>Status</NXTable.Header.Title>
                                        <div className="tppDomainsList__statusContainer">
                                            <StatusTag status={status} />
                                            <StatusTag status="Legacy" />
                                        </div>
                                    </TableAccordion.Child>
                                    <TableAccordion.Child>
                                        <NXTable.Header.Title>Expires</NXTable.Header.Title>
                                        <DomainsListExpiry expiryDate={expiry_date} />
                                    </TableAccordion.Child>
                                </Accordion>
                            );
                        })}
                    </Accordion.ToggleWrapper>
                </Border>
            );

        // Desktop table
        return (
            <Border all radius="S_border-radius_default">
                <NXTable columns={getColumns()}>
                    <NXTable.Header>
                        <NXTable.Header.Title>Domain</NXTable.Header.Title>
                        <NXTable.Header.Title>Status</NXTable.Header.Title>
                        <NXTable.Header.Title>Expires</NXTable.Header.Title>
                    </NXTable.Header>
                    <NXTable.Body isLoading={isTppDomainsListLoading}>
                        {tpp_domains_list_data.map((singleDomain, index) => {
                            const { id, expiry_date, domain, status } = getSafeData(singleDomain);

                            return (
                                <NXTable.Row key={index}>
                                    <Text black size--s bold>
                                        {domain}
                                    </Text>
                                    <div className="tppDomainsList__statusContainer">
                                        <StatusTag status={status} />
                                        <HoverTooltip
                                            alignment="left"
                                            content="Legacy Domains have limited management features as they still use an external domain Registrar."
                                        >
                                            <StatusTag status="Legacy" />
                                        </HoverTooltip>
                                    </div>
                                    <DomainsListExpiry expiryDate={expiry_date} />
                                    <NXTable.Actions>{renderButtons(id)}</NXTable.Actions>
                                </NXTable.Row>
                            );
                        })}
                    </NXTable.Body>
                </NXTable>
            </Border>
        );
    };

    /***** RENDER *****/
    // For customers that have no TPP domains we don't want to show anything at all
    if (!Array.isArray(tpp_domains_list_data) || tpp_domains_list_data.length <= 0) return '';

    return (
        <section className="tppDomainsList">
            <div className="tppDomainsList__top">
                <Text primary size--xl semiBold>
                    <h3>Legacy Domain Names</h3>
                </Text>
                <Text secondary size--s lead--s>
                    <p>{legacyDomainsListCopyText}</p>
                </Text>
            </div>
            {renderTable()}
        </section>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TPPDomainsList;

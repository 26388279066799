/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';

/**********************************************************************************************************
 *   ROUTE SCHEMA
 **********************************************************************************************************/
const searchSchema = z.object({
    code: z.coerce.string().optional(),
    state: z.coerce.string().optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const AuthorizeInstagramRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'katana/authorize/instagram',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    validateSearch: searchSchema
}).lazy(lazyFN(() => import('./instagram.lazy'), 'LazyKatanaAuthorizeInstagramRoute'));

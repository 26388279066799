/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EntryCreationBehaviourHandlerOnImageAddWithKatanaImageUpload } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper/katana/uploadImage';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useTargetKeyToPropertyPath } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useTargetKeyToPropertyPath';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { entryCreationBehaviourTypes } from 'containers/katana/formFields/entryCreationBehaviourTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      method: import('containers/katana/formFields/types').EntryCreationBehaviourTypeValues;
 *      target: string;
 *      fields: import('redux-form').FieldArrayFieldsProps;
 *      properties: import('containers/katana/types').KatanaNamespace.SectionDefinitions.PropertiesModified[];
 *      property: import('containers/katana/types').KatanaNamespace.SectionDefinitions.PropertiesModified;
 * }>}
 */
export const EntryCreationBehaviourHandler = (props) => {
    const { method, target, fields, properties, property } = props;

    const firstEntryCreationTargetKey = useGetCaveat(CaveatTypes.ENTRY_CREATION_FIRST_ENTRY_COPY_TO_TARGET_KEY, property.caveats);
    const newPropertyAccessor = useTargetKeyToPropertyPath(property?.key, firstEntryCreationTargetKey);
    const fileLimit = useGetCaveat(CaveatTypes.REPEATABLE_PROPERTY_LIMIT, property.caveats);
    const { dynamicChange } = useDynamicFormActions();

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** FUNCTIONS *****/
    function provideValue(value) {
        if (firstEntryCreationTargetKey && fields.length === 0) {
            dynamicChange(newPropertyAccessor, value);
        }
        fields.push({
            [target]: value
        });
    }

    /***** RENDER HELPERS *****/
    const targetProperty = properties.find((property) => property?.originalKey === target);

    if (!targetProperty) {
        return <Text>No target property found for {target}</Text>;
    }

    /***** RENDER *****/
    switch (method) {
        case entryCreationBehaviourTypes.ON_IMAGE_ADD: {
            return (
                <EntryCreationBehaviourHandlerOnImageAddWithKatanaImageUpload
                    serviceID={katanaServiceId}
                    provideValue={provideValue}
                    targetProperty={targetProperty}
                    fileLimit={fileLimit}
                    fields={fields}
                />
            );
        }

        default:
            return <Text>No Entry Creation Behaviour for {method}</Text>;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

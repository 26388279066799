/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js/features/promise/all-settled';
import 'core-js/features/string/replace-all';
import 'core-js/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import queryClient from 'store/queryClient';
import store from './store/store';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CustomErrorBoundary from 'components/Errors/ErrorBoundary';
import { RouterContextProvider } from 'router/provider';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { setAxiosDefaults as initializeAxiosDefaults } from 'App/methods';
import { initializeRouteChangeHandler, initializeSentry } from 'router/interceptors';
import { interceptorHandler as initializeInterceptorHandlers } from 'utilities/methods/interceptorHandler';

/**********************************************************************************************************
 *   APPLICATION START
 **********************************************************************************************************/
initializeSentry();
initializeAxiosDefaults();
initializeRouteChangeHandler();
initializeInterceptorHandlers();

// Note, the app is not being rendered in "strict mode"
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store} stabilityCheck="never">
        <CustomErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <RouterContextProvider />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </CustomErrorBoundary>
    </Provider>
);

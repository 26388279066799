/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback, useContext, useLayoutEffect, useMemo } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSvelteStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RichTextEditorContext } from 'components/Form/RichTextEditor/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useRichTextEditorContext<TKey extends RichTextEditorNamespace.ContextStateKey | undefined = undefined>(
    key?: TKey,
    defaultValue?: TKey extends RichTextEditorNamespace.ContextStateKey ? RichTextEditorNamespace.ContextState[TKey] : never
): TKey extends RichTextEditorNamespace.ContextStateKey
    ? [RichTextEditorNamespace.ContextState[TKey], (value: RichTextEditorNamespace.ContextState[TKey]) => void]
    : [RichTextEditorNamespace.ContextState, (value: RichTextEditorNamespace.ContextState) => void] {
    /***** HOOKS *****/
    const currentStateWritable = useContext(RichTextEditorContext);

    const [currentState, setCurrentState, updateCurrentState] = useSvelteStore(currentStateWritable);

    const currentStateKeyValue = useMemo(() => (key ? currentState[key] : undefined), [currentState, key]);

    /***** FUNCTIONS *****/
    const setCurrentStateKeyValue = useCallback(
        (value: NXUtils.ValueOf<RichTextEditorNamespace.ContextState>) => {
            if (key) {
                updateCurrentState((_currentState) => ({ ..._currentState, [key]: value }));
            }
        },
        [key]
    );

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        // Create default value if it doesn't exist
        if (defaultValue && key) {
            setCurrentStateKeyValue(defaultValue);
        }
    }, [defaultValue, key]);

    /***** HOOK RESULTS *****/
    if (key) {
        return [currentStateKeyValue, setCurrentStateKeyValue] as any;
    }

    return [currentState, setCurrentState] as any;
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 */
function useCreateKatanaThemeMutation(serviceID, options = {}) {
    /***** HOOKS *****/

    const { page } = useKatanaParams();

    /***** FUNCTIONS *****/
    /**
     * @type {(attributes: KatanaSiteCreateThemePostAttributesTypeDef) => Promise<unknown>}
     */
    function mutationFn(values) {
        const attributes = filterEndpointAttributeKeys(values, useCreateKatanaThemeMutation.acceptableKeys);
        return KATANA_API.katana.site.service_id.theme.POST({ serviceID, attributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey: useCreateKatanaThemeMutation.mutationKey,
        ...options,
        onSuccess: page === 'setup' ? null : handleDefaultSuccessNotification,
        onSettled: () => {
            katanaQuery.serviceID.getSections.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSections.prefetchQuery(serviceID);
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSiteInfo.resetQueries(serviceID);
            katanaQuery.serviceID.meta.getThemePresets.invalidateQueries(serviceID);
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
useCreateKatanaThemeMutation.mutationKey = ['useCreateKatanaThemeMutation'];

useCreateKatanaThemeMutation.FORM_FIELD_KEYS = /** @type {const} */ ({
    PRESET_ID: 'preset_id',
    SECTIONS: 'sections'
});

useCreateKatanaThemeMutation.acceptableKeys = Object.values(useCreateKatanaThemeMutation.FORM_FIELD_KEYS);

export { useCreateKatanaThemeMutation };


/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ColourCircle } from 'components/ColourSelectCircle/_ColourCircle';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { PresetCustomiserColourSelect } from 'components/PictureInPicture/controls/colourSelect/presetCustomiserColourSelect';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PictureInPictureColourSelectControl.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/

import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

type PictureInPictureColourSelectControlProps = {
    siteData: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes;
    setSiteData: React.Dispatch<React.SetStateAction<KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes>>;
};

const _PictureInPictureColourSelectControl = ({ siteData, setSiteData }: PictureInPictureColourSelectControlProps) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const colourSelectWrapperRef = useRef();

    const { value: isColourSelectOpen, setFalse: closeColourSelector, toggle: toggleColourSelect } = useBoolean(false);

    /***** QUERIES *****/
    const { data: get_colours_meta_data } = katanaQuery.serviceID.meta.getColours.useQuery(katanaServiceId);

    const { data: get_service_data } = katanaQuery.serviceID.getService.useQuery(katanaServiceId);
    const [currentColour, setCurrentColour] = useState(get_service_data?.site?.color || 'white');

    /***** FUNCTIONS *****/
    const selectColour = (colour: string) => {
        closeColourSelector();
        setSiteData((prev) => ({ ...prev, color: colour }));
    };
    /***** EFFECTS *****/

    useLayoutEffect(() => {
        if (get_service_data?.site?.color) {
            setCurrentColour(get_service_data.site.color);
        }
    }, [get_service_data]);

    useLayoutEffect(() => {
        if (siteData?.color) {
            setCurrentColour(siteData.color);
        }
    }, [siteData]);

    useEffect(() => {
        return () => {
            setSiteData({});
        };
    }, []);

    /***** RENDER *****/
    return (
        <>
            <Flex inject>
                <Tooltip title="Change colour" arrow placement="top" className="PictureInPictureColourSelectControl__hoverTooltip">
                    <button type="button" onClick={toggleColourSelect} className="PictureInPictureColourSelectControl" ref={colourSelectWrapperRef}>
                        <Flex inject justify="center" items="center">
                            <ColourCircle colour={get_colours_meta_data?.[currentColour]?.hex_code} selected />
                        </Flex>
                    </button>
                </Tooltip>
            </Flex>
            {isColourSelectOpen && (
                <PresetCustomiserColourSelect
                    preventClickRef={colourSelectWrapperRef}
                    closeColourSelector={closeColourSelector}
                    colour={currentColour}
                    setColour={selectColour}
                />
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _PictureInPictureColourSelectControl };


/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate, useRouter } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouter, NavigateOptions, ParsedLocation, RegisteredRouter, RoutePaths } from '@tanstack/react-router';
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FastLink.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type FastLinkComponent = <
    TRouter extends AnyRouter = RegisteredRouter,
    TFrom extends RoutePaths<TRouter['routeTree']> | string = string,
    TTo extends string = '',
    TMaskFrom extends RoutePaths<TRouter['routeTree']> | string = TFrom,
    TMaskTo extends string = ''
>(
    props: NavigateOptions<TRouter, TFrom, TTo, TMaskFrom, TMaskTo> & {
        children: React.ReactNode;
        onClick?: () => void;
        className?: string;
    }
) => React.ReactElement;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Faster version of the link component. This component will always redirect to the correct location, but for actually building
 * the props that will be passed to the underlying anchor, this is done after render to prevent render blocking.
 *
 * This does mean that on first render, the href on the anchor will be incorrect (even if it will still link to the correct place),
 * but in return, it will work identically on the next render and prevents blocking the render (which the Link takes ~0.5ms to do).
 *
 * Only use in places where there are large numbers of links, or number of rerenders are high as this component has reduced functionality
 * compared to the link component and also does not follow the styling of the `Button` or `Anchor` components.
 */
export const FastLink: FastLinkComponent = ({ children, onClick, className, ...navigateProps }) => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const { buildLocation } = useRouter();

    /***** STATE *****/
    const [location, setLocationHref] = useState<Partial<ParsedLocation>>({});

    const locationDependencies = [
        navigateProps.to,
        navigateProps.hash,
        navigateProps.search,
        navigateProps.from,
        navigateProps.params,
        navigateProps.replace,
        navigateProps.state,
        navigateProps.viewTransition,
        navigateProps.mask,
        navigateProps.resetScroll
    ];

    /***** EFFECTS *****/
    useEffect(() => {
        setLocationHref(buildLocation(navigateProps as any));
    }, locationDependencies);

    /***** RENDER *****/
    return (
        <a
            href={location.href ?? ''}
            className={classNames('FastLink', className)}
            onClick={(e) => {
                e.preventDefault();
                onClick?.();
                navigate(navigateProps as any);
            }}
        >
            {children}
        </a>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

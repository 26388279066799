/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has, get as lodashGet, sample } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { get as storeGet } from 'svelte/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSvelteStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SUPER_ACTIONS_KEYS, superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';

const newDVDSTate = ({ y = 0, x = 0 } = {}) => ({
    y,
    x,
    dirY: sample([1, -1]),
    dirX: sample([1, -1]),
    speed: 1,
    animationID: Date.now(),
    lastTime: Date.now()
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 *      isActive?: boolean;
 *      target?: string;
 * }>}
 */
const _SuperUserDVDSomethingElse = ({ children, isActive = true, target }) => {
    /***** HOOKS *****/
    const [superUserActions] = useSvelteStore(superUserSuperActionsState);

    /** @type {React.MutableRefObject<HTMLDivElement | null>} */
    const dvdChildRef = useRef(null);

    const dvdState = useRef(newDVDSTate());
    /**
     * @type {React.MutableRefObject<{
     *      width?: string;
     *      height?: string;
     * }>}
     */
    const dvdTargetResetValues = useRef({});

    const canShowDVDAction = lodashGet(superUserActions, SUPER_ACTIONS_KEYS.BIGDADDY);

    /***** FUNCTIONS *****/
    /**
     * @param {number} animationID
     */
    function animate(animationID) {
        const currentTime = Date.now();
        const diff = currentTime - dvdState.current.lastTime;
        const dt = diff / (1000 / 60);
        dvdState.current.lastTime = currentTime;

        const targetElement = target ? dvdChildRef.current.querySelector(target) : null;
        if (
            dvdState.current.animationID !== animationID ||
            !targetElement ||
            !(targetElement instanceof HTMLElement) ||
            !lodashGet(storeGet(superUserSuperActionsState), SUPER_ACTIONS_KEYS.BIGDADDY) ||
            !dvdChildRef.current
        ) {
            return;
        }

        const dvdRect = targetElement.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;

        if (dvdState.current.y + dvdRect.height >= screenHeight) {
            dvdState.current.y = screenHeight - dvdRect.height;
            dvdState.current.dirY *= -1;
        }
        if (dvdState.current.y < 0) {
            dvdState.current.y = 0;
            dvdState.current.dirY *= -1;
        }

        if (dvdState.current.x + dvdRect.width >= screenWidth) {
            dvdState.current.dirX *= -1;
            dvdState.current.x = screenWidth - dvdRect.width;
        } else if (dvdState.current.x < 0) {
            dvdState.current.dirX *= -1;
            dvdState.current.x = 0;
        }

        dvdState.current.x += dvdState.current.dirX * dvdState.current.speed * dt;
        dvdState.current.y += dvdState.current.dirY * dvdState.current.speed * dt;

        targetElement.style.left = `${dvdState.current.x}px`;
        targetElement.style.top = `${dvdState.current.y}px`;

        window.requestAnimationFrame(() => animate(animationID));
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (!(target && dvdChildRef?.current)) {
            return;
        }

        dvdState.current.animationID = Date.now();
        const targetElement = /** @type {HTMLElement} */ (dvdChildRef.current.querySelector(target));

        if (targetElement && canShowDVDAction) {
            const dvdRect = targetElement.getBoundingClientRect();
            if (isActive && canShowDVDAction) {
                if (targetElement.style.width && !has(dvdTargetResetValues.current, 'width')) {
                    dvdTargetResetValues.current.width = targetElement.style.width;
                }
                if (targetElement.style.height && !has(dvdTargetResetValues.current, 'height')) {
                    dvdTargetResetValues.current.height = targetElement.style.height;
                }
                targetElement.style.width = `${dvdRect.width}px`;
                targetElement.style.height = `${dvdRect.height}px`;
                targetElement.style.transitionDuration = '0s';
                targetElement.style.transform = 'none';
                dvdState.current = newDVDSTate({
                    y: dvdRect.top,
                    x: dvdRect.left
                });
                window.requestAnimationFrame(() => animate(dvdState.current.animationID));
            } else {
                targetElement.style.transitionDuration = '';
                targetElement.style.width = dvdTargetResetValues.current.width || '';
                targetElement.style.height = dvdTargetResetValues.current.height || '';
                targetElement.style.left = '';
                targetElement.style.top = '';
                dvdTargetResetValues.current = {};
            }
        }
    }, [superUserActions, target, dvdChildRef?.current, isActive]);

    /***** RENDER *****/

    return <div ref={dvdChildRef}>{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _SuperUserDVDSomethingElse };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetRoutableCallToActionSections() {
    /***** HOOKS *****/

    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_katana_site_sections_data } = katanaQuery.serviceID.getSections.useQuery(katanaServiceId);
    const { data: get_katana_site_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(katanaServiceId);

    /***** RENDER *****/
    return useMemo(() => {
        if (!get_katana_site_sections_data || !get_katana_site_section_definitions_data) {
            return [];
        }

        const sortableSections = [...get_katana_site_sections_data].sort((a, b) => (a.order < b.order ? -1 : 1));
        return (
            sortableSections?.map?.((section) => {
                return {
                    label: get_katana_site_section_definitions_data?.[section.section_id]?.name,
                    value: section.id
                };
            }) ?? []
        );
    }, [get_katana_site_section_definitions_data, get_katana_site_sections_data]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

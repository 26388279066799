/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import { get } from 'lodash';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';
import BrowserBox from 'components/Lightboxes/BrowserBox';
import { ControlCircle } from 'components/Lightboxes/BrowserBox/controlCircle/controlCircle';
import RequestLoader from 'components/Loaders/Request';
import { NXIframe } from 'components/NXIframe';
import { PictureInPicture } from 'components/PictureInPicture';
import { _SuperUserDVDSomethingElse as SuperUserDVDSomethingElse } from 'components/StaffMenu/SuperUser/components/dvdSomethingElse';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PresetCustomiser } from 'containers/katana/components/sitePreviewBrowserBox/presetCustomiser';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { viewPortWidths } from 'components/PicturedIframeSourceDocPreview/methods';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useSvelteStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { PictureInPictureNamespace } from 'components/PictureInPicture/types';
import { SUPER_ACTIONS_KEYS, superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import { katanaSubPageEnums } from 'containers/katana/consts';
import './_PicturedIframeSourceDocPreview.scss';
const { PRESET_CONTENT } = katanaSubPageEnums;

const { KATANA_IMAX } = SUPER_ACTIONS_KEYS;
function truthyRef<TRef>(ref: React.RefObject<TRef>): ref is React.MutableRefObject<TRef> {
    return Boolean(ref?.current);
}

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type NXPicturedIframeSourceDocPreviewComponent = React.FC<{
    leftAnchorElement?: HTMLElement | null;
    stateStoreData: PictureInPictureNamespace.CreateStateData;
    loaderData?: NRequestLoader.MultiLoader.Data[];
    srcDoc: string;
    isError?: boolean;
    className?: string;
    canHide?: boolean;
    canMinimise?: boolean;
    canResize?: boolean;
    controlsWrapperPostContent?: React.ReactNode;
}>;

const NXPicturedIframeSourceDocPreview: NXPicturedIframeSourceDocPreviewComponent = ({
    leftAnchorElement,
    stateStoreData,
    srcDoc,
    loaderData = [],
    isError,
    className,
    canHide = true,
    canMinimise = true,
    canResize = true
}) => {
    /***** STATE *****/
    const [viewportMode, setViewportMode] = useState<NBrowserBox.Viewports>('desktop');
    const [isSwappingSourceDoc, setIsSwappingSourceDoc] = useState(false);

    /***** HOOKS *****/
    const [superUserActions] = useSvelteStore(superUserSuperActionsState);
    const { siteData, setSiteData } = useSiteDataPreviewValues();
    const { page, subpage } = useKatanaParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    const [storeState, setStoreState] = useSvelteStore(stateStoreData.store);
    const viewportModeRef = useRef(viewportMode);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const iframeWrapperRef = useRef<HTMLDivElement>(null);

    const pictureInPictureRef = useRef<HTMLDivElement>(null);

    const browserBoxPageRef = useRef<HTMLDivElement>(null);

    const browserBoxHeaderRef = useRef<HTMLDivElement>(null);

    const browserBoxContentRef = useRef<HTMLDivElement>(null);

    /**
     * This function can be called during any part of the render lifecycle therefore we have to rely on refs for the latest data
     */
    const performUpdate = () => {
        if (
            !truthyRef(iframeRef) ||
            !truthyRef(iframeWrapperRef) ||
            !truthyRef(pictureInPictureRef) ||
            !truthyRef(browserBoxPageRef) ||
            !truthyRef(browserBoxHeaderRef) ||
            !truthyRef(browserBoxContentRef) ||
            !iframeRef?.current?.contentDocument?.firstElementChild
        ) {
            return;
        }

        /** Disconnect the observers for the elements we are modifying in the update */

        /**
         * Reset Iframe Parent styles
         * - Set width to auto
         * - Set height to auto
         * - Set overflow to visible
         */
        iframeWrapperRef.current.style.width = 'auto';
        iframeWrapperRef.current.style.height = 'auto';
        iframeWrapperRef.current.style.overflow = 'visible';

        /**
         * Reset Iframe styles
         * - Set transform to none
         * - Set position to relative
         */
        iframeRef.current.style.transform = 'none';
        iframeRef.current.style.position = 'relative';

        /** Set Iframe width to the width of the desired iframe display width, i.e. viewport width */
        const iframeDisplayWidth = viewPortWidths[viewportModeRef.current];

        /** Set the iframe height to the window.innerHeight */
        iframeRef.current.height = String(window.innerHeight);

        /** Get the width of the Browser Box content */
        const browserBoxContentRect = browserBoxContentRef.current.getBoundingClientRect();
        const browserBoxContentWidth = Math.ceil(browserBoxContentRect.width);

        /** Calculate simulated width */
        const difference = iframeDisplayWidth - browserBoxContentWidth;
        const simulatedWidth = browserBoxContentWidth + Math.ceil(difference / 2);
        iframeRef.current.width = String(simulatedWidth);

        /** Get the height of the iframe's document height */
        const iframeDocumentHeight = Math.ceil(iframeRef.current.contentDocument.firstElementChild.getBoundingClientRect().height);

        /** Get the height of the Browser Box Content (BrowserBox Wrapper height - BrowserBox Header height) */
        const browserBoxWrapperRect = browserBoxPageRef.current.getBoundingClientRect();
        const browserBoxHeaderRect = browserBoxHeaderRef.current.getBoundingClientRect();
        const browserBoxContentHeight = browserBoxWrapperRect.height;
        const browserBoxHeaderHeight = browserBoxHeaderRect.height;
        const calculatedContentHeight = browserBoxContentHeight - browserBoxHeaderHeight;

        /** Calculate the iframe target scale based on the iframe's width and the BrowserBox content width */
        const calculatedTargetScale = browserBoxContentWidth / simulatedWidth;
        const calculatedOriginScale = simulatedWidth / browserBoxContentWidth;

        /** Calculate the target height of the iframe */
        const calculatedIframeTargetHeight = iframeDocumentHeight * calculatedTargetScale;

        /** Is the calculated height more than the browser box content height? */
        const isCalculatedHeightMoreThanBrowserBoxContentHeight = calculatedIframeTargetHeight > calculatedContentHeight;

        /** If the calculated height is more than the browser box content height,
         * set the iframe height to the browser box content height multiplied by the calculated scale
         */
        if (isCalculatedHeightMoreThanBrowserBoxContentHeight) {
            const scaledBrowserBoxHeight = calculatedContentHeight * calculatedOriginScale;
            iframeRef.current.height = String(scaledBrowserBoxHeight);
        } else {
            const scaledBrowserBoxHeight = calculatedIframeTargetHeight * calculatedOriginScale;
            iframeRef.current.height = String(scaledBrowserBoxHeight);
        }

        /** Set the iframe scale to the calculated scale */
        iframeRef.current.style.transform = `scale(${calculatedTargetScale})`;

        /**
         * Set the iframe parent styles:
         * - Set width to browser box content width
         * - If the calculated height is more than the browser box content height, set height to the calculated content height
         * - If the calculated height is less than the browser box content height, set height to the calculated iframe target height
         * - Set overflow to hidden
         */
        iframeWrapperRef.current.style.width = `${browserBoxContentWidth}px`;
        if (isCalculatedHeightMoreThanBrowserBoxContentHeight) {
            iframeWrapperRef.current.style.height = `${calculatedContentHeight}px`;
        } else {
            iframeWrapperRef.current.style.height = `${calculatedIframeTargetHeight}px`;
        }
        iframeWrapperRef.current.style.overflow = 'hidden';

        /** set Iframe position to absolute */
        iframeRef.current.style.position = 'absolute';
    };

    const [iframeFirstElementChild, setIframeFirstElementChild] = useState(iframeRef?.current?.contentDocument?.firstElementChild ?? null);

    /***** EFFECTS *****/
    useResizeObserver(pictureInPictureRef, performUpdate);
    useResizeObserver(iframeFirstElementChild, performUpdate);

    useLayoutEffect(() => {
        viewportModeRef.current = viewportMode;
    }, [viewportMode]);

    useLayoutEffect(() => {
        performUpdate();
    }, [viewportMode, storeState, srcDoc]);

    useLayoutEffect(() => {
        setIsSwappingSourceDoc(true);
    }, [srcDoc]);

    useLayoutEffect(() => {
        if (storeState === 'minimised' && isMobile) {
            setStoreState('hidden');
        }
    }, [isMobile, storeState]);

    /***** RENDER HELPERS *****/
    const multiLoaderDataMemo = useMemo(
        () => [...loaderData, { condition: isSwappingSourceDoc, message: 'Fetching latest site preview...' }],
        [loaderData, isSwappingSourceDoc]
    );
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData(multiLoaderDataMemo);
    const currentMultiLoaderMessage = RequestLoader.MultiLoader.useCurrentMessage(multiLoaderData);
    const canPreviewCustomise = (page === 'setup' && subpage === PRESET_CONTENT) || subpage === 'colours-fonts';

    /***** RENDER *****/
    return (
        <SuperUserDVDSomethingElse isActive={storeState === 'visible'} target=".PictureInPicture">
            <PictureInPicture
                className={classNames('PicturedIframeSourceDocPreview', className)}
                leftAnchorElement={leftAnchorElement}
                stateStoreData={stateStoreData}
                pictureInPictureRef={pictureInPictureRef}
            >
                <PictureInPicture.Content>
                    <BrowserBox
                        viewportMode={viewportMode}
                        setViewportMode={setViewportMode}
                        browserBoxHeaderRef={browserBoxHeaderRef}
                        browserBoxContentRef={browserBoxContentRef}
                        browserBoxPageRef={browserBoxPageRef}
                        controlCirclesContent={[
                            canHide ? (
                                <ControlCircle
                                    key={0}
                                    hoverColour="warn"
                                    ControlIcon={PhosphorIcons.X.Bold}
                                    onClick={() => {
                                        setStoreState('hidden');
                                    }}
                                />
                            ) : null,
                            canMinimise ? (
                                <ControlCircle
                                    key={1}
                                    hoverColour="notice"
                                    ControlIcon={PhosphorIcons.Minus.Bold}
                                    onClick={() => {
                                        setStoreState('minimised');
                                    }}
                                />
                            ) : null,
                            canResize ? (
                                <ControlCircle
                                    key={2}
                                    hoverColour="confirm"
                                    ControlIcon={storeState === 'maximised' ? PhosphorIcons.CornersIn.Fill : PhosphorIcons.CornersOut.Fill}
                                    onClick={() => {
                                        setStoreState(storeState === 'maximised' ? 'visible' : 'maximised');
                                    }}
                                >
                                    {(iconProps) => (
                                        <>
                                            <PhosphorIcons.Caret.Left.Fill {...iconProps} />
                                            <PhosphorIcons.Caret.Right.Fill {...iconProps} />
                                        </>
                                    )}
                                </ControlCircle>
                            ) : null
                        ]}
                    >
                        <IsDataUpdatingOverlay.StableDomStructure
                            noBorder
                            isDataUpdating={currentMultiLoaderMessage?.condition}
                            message={currentMultiLoaderMessage?.message}
                            Variant={RequestLoader.WithBackground}
                            height={35}
                        >
                            <div
                                ref={iframeWrapperRef}
                                style={{
                                    position: 'relative'
                                }}
                            >
                                {isError ? (
                                    <Padding xy={2}>
                                        <FetchComponentError />
                                    </Padding>
                                ) : (
                                    <NXIframe
                                        srcDoc={srcDoc}
                                        onLoad={() => {
                                            setIsSwappingSourceDoc(false);
                                            setIframeFirstElementChild(iframeRef?.current?.contentDocument?.firstElementChild ?? null);
                                        }}
                                        iframeRef={iframeRef}
                                        title="NXPicturedIframeSourceDocPreview"
                                        width={window.innerWidth}
                                        height={window.innerHeight}
                                        style={{ transformOrigin: '0 0', left: '0', top: '0' }}
                                    />
                                )}
                            </div>
                        </IsDataUpdatingOverlay.StableDomStructure>
                    </BrowserBox>
                    <PictureInPicture.ControlsWrapper>
                        <PresetCustomiser>
                            {canPreviewCustomise && siteData && setSiteData && (
                                <PictureInPicture.ColourSelect siteData={siteData} setSiteData={setSiteData} />
                            )}
                            {canPreviewCustomise && siteData && setSiteData && (
                                <PictureInPicture.FontSelect siteData={siteData} setSiteData={setSiteData} />
                            )}
                            {!get(superUserActions, KATANA_IMAX) && (
                                <>
                                    {canResize && (
                                        <>
                                            <PictureInPicture.ShrinkButton text="Shrink Preview" />
                                            <PictureInPicture.MaximiseButton text="Full Preview" />
                                        </>
                                    )}
                                    {canHide && <PictureInPicture.MinimiseButton text="Hide" />}
                                </>
                            )}
                        </PresetCustomiser>
                    </PictureInPicture.ControlsWrapper>
                </PictureInPicture.Content>
                <PictureInPicture.ShowButton text="Show Preview" />
            </PictureInPicture>
        </SuperUserDVDSomethingElse>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXPicturedIframeSourceDocPreview;

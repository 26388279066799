/**********************************************************************************************************
 *   GLOBAL IMPORTS
 **********************************************************************************************************/
import axios from 'axios';
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT } from 'utilities/api/account';
import { API as LOGIN } from 'utilities/api/login';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   EXTERNAL ACTIONS
 **********************************************************************************************************/
import { APP_MOUNTING, APP_UPDATE_USER, APP_USER_RESET, loginGetUser } from 'App/action';
import { SERVICES_INITIAL_STATE } from 'containers/services/action';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const LOGIN_INITIAL_STATE = 'login/LOGIN_INITIAL_STATE';
export const LOGIN_LOADING_UPDATE = 'login/LOGIN_LOADING_UPDATE';
export const LOGIN_AUTHENTICATION_VPN_REQUEST = 'login/LOGIN_AUTHENTICATION_VPN_REQUEST';
export const LOGIN_AUTHENTICATION_REQUEST = 'login/LOGIN_AUTHENTICATION_REQUEST';
export const LOGIN_AUTHENTICATION_SUCCESS = 'login/LOGIN_AUTHENTICATION_SUCCESS';
export const LOGIN_AUTHENTICATION_ERROR = 'login/LOGIN_AUTHENTICATION_ERROR';
export const LOGIN_2FA_CREATE = 'login/LOGIN_2FA_CREATE';
export const LOGIN_2FA_REQUEST = 'login/LOGIN_2FA_REQUEST';
export const LOGIN_2FA_SUCCESS = 'login/LOGIN_2FA_SUCCESS';
export const LOGIN_2FA_ERROR = 'login/LOGIN_2FA_ERROR';
export const LOGIN_2FA_AUTHENTICATION_REQUEST = 'login/LOGIN_2FA_AUTHENTICATION_REQUEST';
export const LOGIN_2FA_AUTHENTICATION_SUCCESS = 'login/LOGIN_2FA_AUTHENTICATION_SUCCESS';
export const LOGIN_2FA_AUTHENTICATION_ERROR = 'login/LOGIN_2FA_AUTHENTICATION_ERROR';
export const LOGIN_2FA_RECOVERY_REQUEST = 'login/LOGIN_2FA_RECOVERY_REQUEST';
export const LOGIN_2FA_RECOVERY_SUCCESS = 'login/LOGIN_2FA_RECOVERY_SUCCESS';
export const LOGIN_2FA_RECOVERY_ERROR = 'login/LOGIN_2FA_RECOVERY_ERROR';
export const LOGIN_BACKUP_REQUEST = 'login/LOGIN_BACKUP_REQUEST';
export const LOGIN_BACKUP_SUCCESS = 'login/LOGIN_BACKUP_SUCCESS';
export const LOGIN_BACKUP_ERROR = 'login/LOGIN_BACKUP_ERROR';
export const LOGIN_SECURITY_QUESTIONS_REQUEST = 'login/LOGIN_SECURITY_QUESTIONS_REQUEST';
export const LOGIN_SECURITY_QUESTIONS_SUCCESS = 'login/LOGIN_SECURITY_QUESTIONS_SUCCESS';
export const LOGIN_SECURITY_QUESTIONS_ERROR = 'login/LOGIN_SECURITY_QUESTIONS_ERROR';
export const LOGIN_RECOVERY_MANUAL_REQUEST = 'login/LOGIN_RECOVERY_MANUAL_REQUEST';
export const LOGIN_RECOVERY_MANUAL_SUCCESS = 'login/LOGIN_RECOVERY_MANUAL_SUCCESS';
export const LOGIN_RECOVERY_MANUAL_ERROR = 'login/LOGIN_RECOVERY_MANUAL_ERROR';
export const LOGIN_FORGOT_PASSWORD_REQUEST = 'login/LOGIN_FORGOT_PASSWORD_REQUEST';
export const LOGIN_FORGOT_PASSWORD_SUCCESS = 'login/LOGIN_FORGOT_PASSWORD_SUCCESS';
export const LOGIN_FORGOT_PASSWORD_ERROR = 'login/LOGIN_FORGOT_PASSWORD_ERROR';
export const LOGIN_FORGOT_EMAIL_REQUEST = 'login/LOGIN_FORGOT_EMAIL_REQUEST';
export const LOGIN_FORGOT_EMAIL_SUCCESS = 'login/LOGIN_FORGOT_EMAIL_SUCCESS';
export const LOGIN_FORGOT_EMAIL_ERROR = 'login/LOGIN_FORGOT_EMAIL_ERROR';
export const LOGIN_RESET_PASSWORD_REQUEST = 'login/LOGIN_RESET_PASSWORD_REQUEST';
export const LOGIN_RESET_PASSWORD_SUCCESS = 'login/LOGIN_RESET_PASSWORD_SUCCESS';
export const LOGIN_RESET_PASSWORD_ERROR = 'login/LOGIN_RESET_PASSWORD_ERROR';
export const LOGIN_ACCOUNT_LIST_REQUEST = 'login/LOGIN_ACCOUNT_LIST_REQUEST';
export const LOGIN_ACCOUNT_LIST_SUCCESS = 'login/LOGIN_ACCOUNT_LIST_SUCCESS';
export const LOGIN_ACCOUNT_LIST_ERROR = 'login/LOGIN_ACCOUNT_LIST_ERROR';
export const LOGIN_SECURITY_INFORMATION_REQUEST = 'login/LOGIN_SECURITY_INFORMATION_REQUEST';
export const LOGIN_SECURITY_INFORMATION_SUCCESS = 'login/LOGIN_SECURITY_INFORMATION_SUCCESS';
export const LOGIN_SECURITY_INFORMATION_ERROR = 'login/LOGIN_SECURITY_INFORMATION_ERROR';
export const SET_URL_PARAMS = 'login/SET_URL_PARAMS';
export const REMOVE_URL_PARAMS = 'login/REMOVE_URL_PARAMS';

/**********************************************************************************************************
 *   ACTIONS
 **********************************************************************************************************/
export const setLoadingState = (state) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_LOADING_UPDATE,
            login_loading_state: state
        });
    };
};

export const removeUrlParams = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_URL_PARAMS
        });
    };
};

export const setUrlParams = (params) => {
    return (dispatch) => {
        dispatch({
            type: SET_URL_PARAMS,
            params
        });
    };
};

export const logout = () => {
    return () => {
        axios.delete('/api/logout');
    };
};

export const requestTwoFactor = (appStates) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_2FA_REQUEST
        });

        LOGIN.login.twoFactor
            .requestDefault()
            .then(() => {
                dispatch({
                    type: LOGIN_2FA_SUCCESS
                });
                dispatch(twoFactorBackupMethodView());

                if (appStates) {
                    dispatch({
                        type: APP_MOUNTING,
                        app_mounting: false
                    });
                }
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch({
                    type: LOGIN_2FA_ERROR
                });
                dispatch(setLoadingState(false));

                if (appStates) {
                    dispatch({
                        type: APP_MOUNTING,
                        app_mounting: false
                    });
                }
            });
    };
};

function updateUserTokenData(data) {
    const { dispatch } = store;

    const {
        is_vpn = null,
        is_user_detail_completed = null,
        is_security_detail_completed = null,
        account_id = null,
        do_detail_completed_check = null,
        two_factor_required = null
    } = data;

    dispatch({
        type: APP_UPDATE_USER,
        app_check_token_data: {
            is_vpn,
            is_user_detail_completed,
            is_security_detail_completed,
            do_detail_completed_check,
            account_id,
            two_factor_required
        }
    });
}

/**
 * @param {{ successCallback?: Function, errorCallback?: Function }} parameters
 */
export function loginTokenCheck({ successCallback, errorCallback }) {
    LOGIN.token
        .check()
        .then((response) => {
            const responseData = getDataFromSuccessResponse(response);

            updateUserTokenData(responseData.attributes);

            successCallback?.(response);
        })
        .catch((error) => {
            errorCallback?.(error);
        });
}

// Login
export const authenticatingUserDetails = (values, params, onSingleAccountCallback = () => {}) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_AUTHENTICATION_REQUEST
        });
        dispatch(setUrlParams(params));

        LOGIN.login
            .submit(values)
            .then((response) => {
                const login_authentication_data = getDataFromSuccessResponse(response);
                const { two_factor_required, is_vpn } = login_authentication_data.attributes;

                updateUserTokenData(login_authentication_data.attributes);

                if (two_factor_required) {
                    dispatch(requestTwoFactor());
                } else {
                    requestAccounts(false, onSingleAccountCallback);
                }

                // This triggers the login prompt window to open, so we only want to do it when not on VPN
                if (!is_vpn) {
                    dispatch({
                        type: LOGIN_AUTHENTICATION_SUCCESS,
                        login_authentication_data
                    });
                }

                function errorCallback() {
                    dispatch({
                        type: APP_MOUNTING,
                        app_mounting: false
                    });
                }

                loginTokenCheck({ errorCallback });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch({
                    type: LOGIN_AUTHENTICATION_ERROR
                });
                dispatch(setLoadingState(false));
            });
    };
};

export const getSecurityQuestions = () => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_SECURITY_QUESTIONS_REQUEST
        });

        ACCOUNT.account.GET.security
            .list()
            .then((response) => {
                const login_security_questions_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: LOGIN_SECURITY_QUESTIONS_SUCCESS,
                    login_security_questions_data
                });
            })
            .catch(() => {
                dispatch({
                    type: LOGIN_SECURITY_QUESTIONS_ERROR
                });
            });
    };
};

export const getUserSecurityInformation = () => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_SECURITY_INFORMATION_REQUEST
        });

        ACCOUNT.account.GET.security
            .question()
            .then((response) => {
                const processedResponse = getDataFromSuccessResponse(response);
                const { attributes, id } = processedResponse;
                const login_security_information_data = {
                    ...attributes,
                    question_id: id
                };

                dispatch({
                    type: LOGIN_SECURITY_INFORMATION_SUCCESS,
                    login_security_information_data
                });
            })
            .catch(() => {
                dispatch({
                    type: LOGIN_SECURITY_INFORMATION_ERROR
                });
            });
    };
};

export const authenticatingTwoFactorCode = (values) => {
    const { twofactorcode } = values;
    const { dispatch, getState } = store;

    const is_vpn = getState().app.app_check_token_data?.is_vpn;

    dispatch(setLoadingState(true));
    dispatch({
        type: LOGIN_2FA_AUTHENTICATION_REQUEST
    });

    return axios
        .post('/api/two-factor/', {
            type: 'two-factor-authentication',
            attributes: {
                two_factor_token: twofactorcode
            }
        })
        .then(async () => {
            await Promise.all([
                new Promise((resolve) => {
                    requestAccounts(!is_vpn, resolve);
                }),
                new Promise((resolve) => {
                    loginTokenCheck({ successCallback: resolve });
                })
            ]);

            dispatch(setLoadingState(false));
        })
        .catch((error) => {
            const response = getErrorFromFailResponse(error);

            pushNotification(response, null, notificationScopes.GUEST);
            dispatch({
                type: LOGIN_2FA_AUTHENTICATION_ERROR
            });
            dispatch({
                type: APP_MOUNTING,
                app_mounting: false
            });
            dispatch(setLoadingState(false));
        });
};

export const resetPassword = (token, values) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_RESET_PASSWORD_REQUEST
        });

        ACCOUNT.account.POST.passwordReset(token, values)
            .then((response) => {
                const login_reset_password_data = getDataFromSuccessResponse(response);

                pushNotification(login_reset_password_data, null, notificationScopes.GUEST);

                dispatch({
                    type: LOGIN_RESET_PASSWORD_SUCCESS
                });
                dispatch(setLoadingState(false));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch({
                    type: LOGIN_RESET_PASSWORD_ERROR
                });
                dispatch(setLoadingState(false));
            });
    };
};

export const forgotPasswordMethod = (values) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_FORGOT_PASSWORD_REQUEST
        });

        LOGIN.login.forgot
            .password(values)
            .then((response) => {
                const login_forgot_password_data = getDataFromSuccessResponse(response);

                pushNotification(login_forgot_password_data, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_PASSWORD_SUCCESS
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_PASSWORD_ERROR
                });
            });
    };
};

export const forgotEmailMethod = (attributes) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_FORGOT_EMAIL_REQUEST
        });

        axios
            .post('/api/forgot/email', {
                type: 'forgot',
                attributes
            })
            .then((response) => {
                const login_forgot_email_data = getDataFromSuccessResponse(response);

                pushNotification(login_forgot_email_data, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_EMAIL_SUCCESS
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_FORGOT_EMAIL_ERROR
                });
            });
    };
};

export const twoFactorBackupMethodView = () => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_BACKUP_REQUEST
        });

        LOGIN.login.twoFactor
            .listMethods()
            .then((response) => {
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_BACKUP_SUCCESS,
                    login_backup_data: response.data.data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_BACKUP_ERROR
                });
            });
    };
};

export const twoFactorRecoveryMethod = (values) => {
    const { method } = values;
    return (dispatch) => {
        dispatch(setLoadingState(true));
        dispatch({
            type: LOGIN_2FA_RECOVERY_REQUEST
        });

        axios({
            method: 'get',
            url: '/api/two-factor/create?method=' + method
        })
            .then(() => {
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_2FA_RECOVERY_SUCCESS
                });
            })
            .catch(() => {
                dispatch(setLoadingState(false));
                dispatch({
                    type: LOGIN_2FA_RECOVERY_ERROR
                });
            });
    };
};

export const generateTwoFactorBasedOnMethod = (method) => {
    return (dispatch) => {
        dispatch(setLoadingState(true));
        LOGIN.login.twoFactor
            .requestAlternate(method)
            .then((response) => {
                const successMessage = getDataFromSuccessResponse(response);

                pushNotification(successMessage, null, notificationScopes.GUEST);

                dispatch(setLoadingState(false));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);

                pushNotification(response, null, notificationScopes.GUEST);

                dispatch(setLoadingState(false));
            });
    };
};

export const submitRecoveryForm = (values) => {
    const { dispatch } = store;
    dispatch(setLoadingState(true));
    dispatch({
        type: LOGIN_RECOVERY_MANUAL_REQUEST
    });

    LOGIN.login.recovery
        .submit({
            name: values.name,
            security_question_id: values.security_question_id,
            security_answer: values.security_answer,
            dob: values.dob,
            phone: values.phone,
            postcode: values.postcode,
            message: values.message,
            contact_email: values.contact_email
        })
        .then(() => {
            dispatch({
                type: LOGIN_RECOVERY_MANUAL_SUCCESS
            });

            dispatch(setLoadingState(false));
        })
        .catch((error) => {
            const response = getErrorFromFailResponse(error);

            pushNotification(response, null, notificationScopes.GUEST);
            dispatch({
                type: LOGIN_RECOVERY_MANUAL_ERROR
            });
            dispatch(setLoadingState(false));
        });
};

export const requestAccounts = (requireUser, onSingleAccount) => {
    const { dispatch } = store;

    dispatch({
        type: LOGIN_ACCOUNT_LIST_REQUEST
    });

    LOGIN.user.account
        .list()
        .then((response) => {
            const login_account_list_data = getDataFromSuccessResponse(response);

            // We pass requireUser = true when calling requestAccounts from "loadAppConfig" because we always need to fetch the user when its a page refresh
            // When calling requestAccounts from authenticatingUserDetails (login), we dont pass require user. Because in this case, if there
            // is more than one user then we need to allow selecting the user first instead of fetching the user immediately
            if (requireUser || login_account_list_data.length <= 1) {
                const completeAccountList = () => {
                    dispatch({
                        type: LOGIN_ACCOUNT_LIST_SUCCESS,
                        login_account_list_data
                    });

                    dispatch({
                        type: APP_MOUNTING,
                        app_mounting: false
                    });

                    onSingleAccount?.();
                };

                loginGetUser({ successCallback: completeAccountList });
            } else {
                dispatch({
                    type: LOGIN_ACCOUNT_LIST_SUCCESS,
                    login_account_list_data
                });

                dispatch({
                    type: APP_MOUNTING,
                    app_mounting: false
                });
            }
        })
        .catch((error) => {
            const login_account_list_error = getErrorFromFailResponse(error);

            pushNotification(login_account_list_error, null, notificationScopes.GUEST);
            dispatch({
                type: LOGIN_ACCOUNT_LIST_ERROR
            });
            dispatch(setLoadingState(false));

            dispatch({
                type: APP_MOUNTING,
                app_mounting: false
            });

            dispatch({
                type: APP_USER_RESET
            });
        });
};

export const selectAccount = (id, history, redirect = 'dashboard') => {
    return (dispatch) => {
        dispatch({
            type: APP_MOUNTING,
            app_mounting: true
        });

        LOGIN.user.account
            .set(id)
            .then(() => {
                dispatch({
                    type: SERVICES_INITIAL_STATE
                });

                loginTokenCheck({
                    successCallback: () => {
                        loginGetUser({
                            finallyCallback: () => {
                                dispatch({
                                    type: APP_MOUNTING,
                                    app_mounting: false
                                });
                                if (history && redirect) {
                                    history.push(redirect);
                                }
                            }
                        });
                    }
                });
            })
            .catch((error) => {
                const login_select_account_error = getErrorFromFailResponse(error);

                pushNotification(login_select_account_error, null, notificationScopes.GUEST);

                dispatch(setLoadingState(false));
            });
    };
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { cpanelReservedAliases } from 'config/config';
import isValidDomain from 'is-valid-domain';
import { isPossiblePhoneNumber, isValidNumber } from 'libphonenumber-js';
import { Component, createRef, useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Fade from 'react-reveal/Fade';
import { Field, change } from 'redux-form';
import isURL from 'validator/es/lib/isURL';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { Radio } from 'components/Form/Radio';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import DialogNotification from 'components/Notifications/DialogNotification';
import RadioButtonSet from 'components/RadioButtonSet';
import Search from 'components/Search';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import RenderRadioGroup from '../../../components/Form/RenderRadioGroup';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { regexes } from 'utilities/methods/regexes';
import { booleanValidation, stringUniqueCharacters } from '../commonActions';
import {
    validatorABNFormat,
    validatorABNorACN,
    validatorACNFormat,
    validatorASCII,
    validatorCPanelStartSpecs,
    validatorCheckboxRequired,
    validatorCountryPostCode,
    validatorDomainAndSubDomain,
    validatorDomainNameLowerCased,
    validatorEmailPrefix,
    validatorIPV4,
    validatorIPV6,
    validatorLowerLettersAndDigits,
    validatorMaxLength,
    validatorMinLength,
    validatorNumber,
    validatorRegularCharactersSpacesHyphensApostrophesAccents,
    validatorRequired,
    validatorRequiredAcceptedValidation,
    validatorValidDate
} from '../validators';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CREDIT_CARD_TYPES } from 'components/CreditCardImage/consts';
import { CREDIT_CARD_FIELD_DATA } from 'utilities/consts';
import { CLASS_ccp__select__item } from 'utilities/methods/consts';

export const validateConfirmValidation = (value, allValues) => {
    const { password_confirmation, password } = allValues;
    return password_confirmation === password ? undefined : 'Passwords do not match';
};

/**
 * @todo - fix above function for account passwords later and then remove this.
 */
export const tempValidateConfirmPasswordAccounts = (value, allValues) => {
    const { password_confirmation, new_password } = allValues;
    return password_confirmation === new_password ? undefined : 'Passwords do not match';
};

/**
 * @deprecated - use {@link RFButton.TReduxFormButton} instead
 *
 * @param {*} pristine
 * @param {boolean} submitting
 * @param {boolean} valid
 * @param {import('react').ReactNode} text
 * @param {import('components/Buttons/_BaseButton').BaseButtonColourTypeDef} color
 * @param {string} className
 */
export const renderButton = (pristine, submitting, valid, text = 'Save Changes', color = 'confirm', className = '') => {
    if (pristine || submitting || !valid) {
        return <InactiveButton className={className}>{text}</InactiveButton>;
    } else {
        return (
            <SolidButton className={className} color={color} type="submit">
                {text}
            </SolidButton>
        );
    }
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const emailFieldValidation = (value) =>
    value &&
    // eslint-disable-next-line no-useless-escape
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        value
    )
        ? 'Invalid email address'
        : undefined;

export const emailPrefixValidation = (value) => {
    const standardError =
        'Username must start with a letter, only contain letters, numbers, underscore, hyphen and period, and be no longer than 30 characters.';

    if (!validatorEmailPrefix.stringLessThan30(value)) return standardError;
    if (!validatorEmailPrefix.onlyContainsLettersNumbersUnderscoreDashOrDot(value)) return standardError;
    if (!validatorEmailPrefix.doesNotEndInDot(value)) return 'Username cannot end in a period.';
    if (!validatorEmailPrefix.doesNotHaveConsecutiveDots(value)) return 'Username cannot contain consecutive periods. (..)';

    return undefined;
};

export const ipAddressValidation = (value) => {
    const ipv6 = value && validatorIPV6(value);
    const ipv4 = value && validatorIPV4(value);

    return !ipv6 && !ipv4 ? `Invalid IP Address` : undefined;
};

export const ipAddressValidationLongFormat = (value) => {
    const validationResult = ipAddressValidation(value);

    // Add tooltip or use https://www.npmjs.com/package/react-awesome-reveal
    return validationResult
        ? `
         IP Address does not appear to be valid. Please double check the IP address with your current host. Also ensure the correct formatting, seen below:</br ></br >
         IPv4 address: 110.000.000.00</br >
         IPv6 address: 2001:0db8:85a3:0000:0000:8a2e:0370:7334
     `
        : undefined;
};

export const validateStateField = (value) => {
    if (validatorRegularCharactersSpacesHyphensApostrophesAccents(value)) {
        const invalidCharList = stringUniqueCharacters(value.replace(regexes.regularCharactersSpacesHyphensApostrophesAccents(), ''));
        return `You have used invalid characters in the state: ${invalidCharList}`;
    }
    return undefined;
};
export const validateStateFieldASCII = (value) => {
    if (validatorASCII(value)) {
        return `You have used invalid characters in the state: ${stringUniqueCharacters(value.replace(regexes.ASCII(), ''))}`;
    }
    return undefined;
};

export const validateEmailCompare = (value, allValues) => {
    const { new_email, confirm_new_email } = allValues;

    if (new_email === confirm_new_email) {
        return undefined;
    }

    return 'Email Address does not match.';
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const bankBSBValidation = (value) => (value && !/^[\d]{3}(-|\s)?[\d]{3}/i.test(value) ? 'Invalid BSB Number' : undefined);

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const bankACCValidation = (value) => (value && !/^[\d\s]+$/i.test(value) ? 'Invalid Account Number' : undefined);

export const requiredFieldErrorMessage = 'This is a required field';
export const requiredFieldValidation = (value) => (validatorRequired(value) ? undefined : requiredFieldErrorMessage);

export const requiredCheckboxFieldValidation = (value) => (validatorCheckboxRequired(value) ? undefined : 'Required');

export const generatePostcodeValidation = (country = 'other') => {
    return (value) => {
        return validatorCountryPostCode(country, value) ? undefined : 'Invalid Postcode';
    };
};

export const nullOrUndefinedValidation = (value) => {
    return [undefined, null].includes(value);
};

export const requiredACNorABNValidation = (value) => {
    return validatorABNorACN(value) ? undefined : 'Invalid ABN/ACN format';
};

export const notRequiredACNorABNValidation = (value) => {
    return nullOrUndefinedValidation(value) || validatorABNorACN(value) ? undefined : 'Invalid ABN/ACN format';
};

export const notRequiredACNorABNValidationByType = (type) => (value) => {
    switch (type) {
        case 'ACN':
            return nullOrUndefinedValidation(value) || validatorACNFormat(value) ? undefined : 'Invalid ACN format';
        case 'ABN':
            return nullOrUndefinedValidation(value) || validatorABNFormat(value) ? undefined : 'Invalid ABN format';
        default:
            return nullOrUndefinedValidation(value) || validatorABNorACN(value) ? undefined : 'Invalid ABN/ACN format';
    }
};

export const validatorDomainNoSubdomain = (value) => {
    return isValidDomain(value, { subdomain: false });
};

export const validatorDomainAndOrSubdomain = (value) => {
    return validatorDomainAndSubDomain(value) ? undefined : 'Invalid domain';
};

export const URLValidation = Object.assign(
    (value) => {
        return isURL(value, { require_protocol: true }) ? undefined : 'Invalid URL';
    },
    { validationMethod: isURL }
);

export const requiredDomainNoSubdomainFAQLink = (value) => {
    return !validatorDomainNoSubdomain(value) ? (
        <>
            {
                "Please enter a valid primary domain. An example of a valid domain would be: 'yourbusiness.com'. If you are trying to set up a subdomain please "
            }
            <Anchor href="https://ventraip.com.au/faq/article/creating-a-sub-domain-in-cpanel/">refer to this FAQ</Anchor>
        </>
    ) : undefined;
};

export const requiredAcceptedValidation = (value) => {
    return validatorRequiredAcceptedValidation(value) ? undefined : 'Required';
};

export const dateValidation = (value) => {
    return validatorValidDate(value) ? undefined : 'The date entered is not a valid date.';
};

export const dateValidationNotRequired = (value) => {
    if (!value) return undefined;
    return dateValidation(value);
};

export function validateReactPhoneNumberInput(value) {
    if (isPossiblePhoneNumber(value) && isValidNumber(value)) return undefined;
    return 'The phone number you have entered is invalid.';
}
export function validateReactPhoneNumberInputNotRequired(value = '') {
    if (!value) return undefined;
    return validateReactPhoneNumberInput(value);
}

export const validateIsAustralianNumber = (value) => {
    if (isPossiblePhoneNumber(value) && isValidNumber(value) && value.startsWith('+61')) return undefined;
    return 'An Australian phone number ( +61 ) is required.';
};

export const validateIsValidDomain = (value) => {
    return validatorDomainNameLowerCased(value) ? undefined : 'Invalid domain name';
};

/**
 * Appends a domain to the current subdomain and then verifies that it is a valid subdomian
 *
 * @param {string} domain
 * @returns {(value: string) => undefined | string}
 */
export const generateSubdomainFieldValidation = (domain) => (value) => {
    return isValidDomain(`${value}.${domain}`) ? undefined : 'Invalid sub domain';
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const postcodeValidation = (value) => (value && value.length === 4 && !/\D/i.test(value) ? undefined : 'Invalid Postcode');

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const peopleNameFieldValidation = (value) => (value && /^[a-z ,.'-]+$/i.test(value) ? undefined : 'Invalid name');

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const domainFieldValidation = (value) =>
    value && /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(value) ? undefined : 'Invalid domain name.';

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const domainFieldNoProtocolValidation = (value) =>
    value.toLowerCase().startsWith('http://') || value.toLowerCase().startsWith('https://')
        ? 'Please exclude the protocol (http:// or https://) from the domain.'
        : undefined;

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const domainFieldValidationLowercased = (value) =>
    value && /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(value.toLowerCase())
        ? undefined
        : 'Invalid Domain Name';

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const detectCardType = (number) => {
    const cardTypeRegexes = {
        [CREDIT_CARD_TYPES.VISA]: /^4[0-9]{12}(?:[0-9]{3})?$/,
        [CREDIT_CARD_TYPES.MASTER_CARD]: /^5[1-5][0-9]{14}$/,
        [CREDIT_CARD_TYPES.AMERICAN_EXPRESS]: /^3[47][0-9]{13}$/
    };
    if (number) {
        number = number.split(' ').join('');
        for (const key in cardTypeRegexes) {
            if (cardTypeRegexes[key].test(number)) {
                return key;
            }
        }
    }
    return undefined;
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const creditcardValidation = (value) => {
    if (value) {
        value = value.split(' ').join('');
    }
    return value &&
        /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/i.test(
            value
        )
        ? undefined
        : 'Invalid Credit Card';
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const numberValidation = (value) => {
    return value && /^\d+$/i.test(value) ? undefined : 'Only Numbers';
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const alphaNumericValidation = (value) => {
    return value && /^[a-zA-Z0-9]+$/.test(value) ? undefined : 'Value may only contain letters and numbers.';
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const webForwarderValidation = (value) => {
    if (value.startsWith('http://') || (value.startsWith('https://') && !value.includes('@'))) {
        return undefined;
    } else if (value.startsWith('http://') || (value.startsWith('https://') && value.includes('@'))) {
        return 'Address must be a valid domain name';
    } else {
        return "Address must start with 'http://' or 'https://'";
    }
};

export const requiredEmptyValidation = (value) => {
    return value ? 'Field must be empty' : undefined;
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const isInArrayValidation = (value, array) => {
    return !array.includes(value) ? 'Please choose one of the options provided' : undefined;
};

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const trailingCharactersValidation = (value) => {
    return value && /[^a-zA-Z0-9]+$/.test(value) ? 'Invalid trailing characters' : undefined;
};

export const mailboxUserNameNormalisation = (value) => {
    return value.split(' ').join('').toLowerCase();
};

export function lowerLettersAndDigitsCPanelValidation(value) {
    return !validatorLowerLettersAndDigits(value) ? `Usernames may only use lower letters ( a-z ) and digits ( 0-9 ).` : undefined;
}

export function maxLengthCPanalUsername(value) {
    return !validatorMaxLength(value, 16) ? `Usernames cannot contain more than 16 characters.` : undefined;
}

export function specialUsernameRequirementStartSpecsCPanel(value) {
    return !validatorCPanelStartSpecs(value) ? `Usernames cannot begin with a digit ( 0-9 ) or the string 'test'.` : undefined;
}

export function specialUsernameRequirementEndWithSpecCPanel(value) {
    return value.endsWith('assword') ? `Usernames cannot end with the string 'assword'.` : undefined;
}

export function cPanelReservedAliasesValidation(value) {
    return cpanelReservedAliases.includes(value) ? `Usernames cannot be a system reserved alias` : undefined;
}

export function isCVVValid(value) {
    return validatorNumber(value) && validatorMaxLength(value, 4) && validatorMinLength(value, 3) ? undefined : 'Invalid CVV';
}

/**
 * DEPRECATED, See {@link ValidatorDeprecation} in src/utilities/methods/validators
 * @return {undefined|string}
 */
export const invoiceSearchValidation = (value) => (/\D/.test(value) ? 'Please enter the number of the invoice you wish to search for.' : undefined);

/**
 * Provides an error message if the value is the same as the account email
 */
export const generateIsNotAccountEmail = (accountEmail, customMessage) => (value) =>
    value?.toLowerCase() === accountEmail?.toLowerCase() ? customMessage ?? 'This field cannot match your account email' : undefined;

//components
export const renderTableError = (touched, error, warning) => {
    return (
        touched &&
        ((error && (
            <Fade
                // @deprecated use `Revealer` instead
                className="sharedTable__input--displayError"
                bottom
                collapse
            >
                <span className="sharedTable__input--tooltip warn">{error}</span>
            </Fade>
        )) ||
            (warning && (
                <Fade className="sharedTable__input--displayError" bottom collapse>
                    <span className="sharedTable__input--tooltip warn">{error}</span>
                </Fade>
            )))
    );
};

export const returnErrorAndWarnClass = (touched, error, warning, initial) => {
    if (initial === '' && initial !== null) {
        return error || warning;
    } else {
        return (touched && error) || warning;
    }
};

/**
 * @deprecated use Input.ReduxForm instead!
 */
export function RenderField({
    input,
    label,
    tooltip,
    readOnly,
    placeholder,
    type = 'text',
    prepend,
    append,
    tabIndex,
    autoFocus,
    preLabel,
    disabled,
    inputRef,
    autoComplete,
    inputMode,
    className,
    validated,
    onKeyDown,
    meta,
    placeholderItalic
}) {
    const { touched, error, warning, pristine, initial } = meta;
    const prependText = () => {
        if (input.value === preLabel) {
            return '';
        }

        if (input.value.includes(preLabel)) {
            return input.value;
        }

        return `${preLabel}${input.value}`;
    };

    /*   SET CONDITIONAL PROPS
     **********************************************************************************************************/
    const conditionalProps = {
        ...input,
        id: input.name || '',
        type: type,
        className: `${append ? 'appended' : ''}`,
        placeholder: placeholder ? placeholder : undefined,
        readOnly: readOnly ? readOnly : undefined,
        tabIndex: tabIndex ? tabIndex : 0,
        value: preLabel && input.value ? prependText() : input.value,
        disabled,
        ref: inputRef,
        autoComplete,
        inputMode,
        onKeyDown
    };

    const wrapperBoxClasses = classNames({
        isPrepended: prepend,
        isAppended: append,
        readonly: readOnly,
        error: returnErrorAndWarnClass(touched, error, warning, pristine) && !validated,
        validated_valid: returnErrorAndWarnClass(touched, validated?.valid, warning, initial),
        validated_invalid: returnErrorAndWarnClass(touched, validated && !validated.valid, warning, initial)
    });

    return (
        <FormItem className={className} isPlaceholderItalic={placeholderItalic} disabled={disabled} name={input.name}>
            <FormLabel htmlFor={input.name || ''}>
                {tooltip || label ? (
                    <>
                        {label}
                        {tooltip ? <Tooltip info={tooltip} /> : ''}
                    </>
                ) : (
                    ''
                )}
            </FormLabel>
            <FormItemInner meta={meta} wrapperboxClasses={wrapperBoxClasses}>
                {prepend ? <div className="prependedText">{prepend.text}</div> : ''}
                <input {...conditionalProps} />
                {append ? (
                    <div className="appendedText">
                        {append.text}
                        <Tooltip
                            info={`This text will be appended to anything you type into this input field. <br/><br/> For example, entering 'abc123' will become abc123${append.text} when the form is submitted.`}
                        >
                            <PhosphorIcons.WarningCircle />
                        </Tooltip>
                    </div>
                ) : (
                    ''
                )}
            </FormItemInner>
        </FormItem>
    );
}

export class RenderCurrencyField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            touched: false
        };

        this.handleTouch = this.handleTouch.bind(this);
    }

    // Manually handle touched because blur event from redux form messes up the input formatting
    handleTouch() {
        const { touched } = this.state;

        if (!touched) {
            this.setState({
                touched: true
            });
        }
    }

    render() {
        const {
            input: { name, value, onChange },
            label,
            tooltip,
            placeholder,
            disabled,
            meta: { error, warning, initial }
        } = this.props;
        const { touched } = this.state;
        const { handleTouch } = this;

        return (
            <div className="form__item">
                <div className="form__title">
                    {label ? (
                        <label htmlFor={name || ''}>
                            {label}
                            {tooltip ? <Tooltip info={tooltip} /> : ''}
                        </label>
                    ) : (
                        ``
                    )}
                </div>
                <div className="form__item__inner">
                    <div className={`wrapperbox${returnErrorAndWarnClass(touched, error, warning, !touched) ? ' error' : ''}`}>
                        <CurrencyInput
                            id={name}
                            name={name}
                            placeholder={placeholder}
                            disabled={disabled}
                            value={value}
                            onValueChange={onChange}
                            onBlur={handleTouch}
                            step={1}
                            intlConfig={{ locale: 'en-AU', currency: 'AUD' }}
                        />
                    </div>
                    <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} pristine={!touched} initial={initial} />
                </div>
            </div>
        );
    }
}

/**
 * @typedef {{
 *      value: string,
 *      label: string,
 *      icon?: JSX.Element,
 *      disabled?: boolean
 * }} RenderSelectFieldOption
 */
/**
 * @deprecated - Please use "components/Form/Select". Use <Select /> for non forms, for forms use `<Select.HookForm />`/`<Select.ReduxForm />`
 * @typedef {Object} RenderSelectFieldProps
 * @property {string} label
 * @property {string} tooltip
 * @property {import('redux-form').WrappedFieldInputProps} input
 * @property {import('redux-form').WrappedFieldMetaProps} meta
 * @property {unknown} search
 * @property {RenderSelectFieldOption[]} options
 * @property {string} className
 * @property {boolean} readOnly
 * @property {boolean} disabled
 */
export class RenderSelectField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOptionList: false,
            focused: false
        };

        this.clickAway = this.clickAway.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.monitorKeyword = this.monitorKeyword.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);

        this.dropdownRef = createRef();
        this.menuRef = createRef();
    }

    monitorKeyword(keyword) {
        this.setState({
            currentKeyword: keyword
        });
    }

    clickAway(e) {
        const { dropdownRef } = this;

        if (
            !e.target?.parentNode?.classList?.contains('search__form') &&
            e.target !== dropdownRef?.current &&
            !dropdownRef.current.contains(e.target)
        ) {
            this.setState({
                showOptionList: false,
                focused: false
            });
        }
    }

    onFocus() {
        this.setState({
            focused: true
        });
    }

    // use onKeyDown to close the menu when tabbing onto next element. Can't use onBlur because it clashes with onClick when selecting an option
    onKeyDown(e) {
        const {
            options,
            input: { value }
        } = this.props;
        const { focused, showOptionList } = this.state;
        const { selectOption, menuRef } = this;

        if (focused) {
            // const selectedIndex = options.indexOf(options.find(option => option.value === value));
            const selectedIndex = options.findIndex((option) => option.value === value);

            // scroll the options menu so that the selected option is always in view
            if (showOptionList && options.length > 5) {
                if (e.key === 'ArrowUp') {
                    if (menuRef.current.scrollTop > (selectedIndex - 1) * 40 && selectedIndex > 0) {
                        menuRef.current.scrollTop = (selectedIndex - 1) * 40;
                    }
                }

                if (e.key === 'ArrowDown') {
                    if (selectedIndex + 1 > 4 && menuRef.current.scrollTop < (selectedIndex - 3) * 40 && selectedIndex + 1 < options.length) {
                        menuRef.current.scrollTop = (selectedIndex - 3) * 40;
                    }
                }
            }

            switch (e.key) {
                case 'Tab':
                    this.setState({
                        showOptionList: false,
                        focused: false
                    });
                    break;
                case 'ArrowDown':
                case 'Down':
                    e.preventDefault();
                    if (selectedIndex === -1) selectOption(options[0].value);
                    else if (selectedIndex < options.length - 1) selectOption(options[selectedIndex + 1].value);
                    break;
                case 'ArrowUp':
                case 'Up':
                    e.preventDefault();
                    if (selectedIndex === -1) selectOption(options[options.length - 1].value);
                    else if (selectedIndex > 0) selectOption(options[selectedIndex - 1].value);
                    break;
                case 'Enter':
                    e.preventDefault();
                    this.setState({
                        showOptionList: !showOptionList
                    });
                    break;
                case 'Escape':
                case 'Esc':
                    e.preventDefault();
                    this.setState({
                        showOptionList: false
                    });
                    break;
                default:
                    break;
            }
        }
    }

    selectOption(value, closeList) {
        const {
            input: { name },
            meta: { dispatch, form }
        } = this.props;
        dispatch(change(form, name, value));
        if (closeList) {
            this.setState({
                showOptionList: false
            });
        }
    }

    onButtonClick(e) {
        this.setState({
            showOptionList: !this.state.showOptionList
        });
    }

    componentDidMount() {
        document.addEventListener('click', this.clickAway);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            options,
            input: { value }
        } = this.props;
        const { showOptionList } = this.state;
        const { menuRef } = this;

        // if dropdown is opened but the selected option is out of scroll
        if (options && showOptionList && !prevState.showOptionList) {
            const selectedIndex = options.findIndex((option) => option.value === value);

            if (selectedIndex + 1 > 4 && menuRef.current.scrollTop < (selectedIndex - 3) * 40) {
                menuRef.current.scrollTop = (selectedIndex - 4) * 40;
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickAway);
    }

    render() {
        const {
            label,
            input,
            tooltip,
            options,
            search,
            className,
            readOnly,
            disabled,
            meta: { touched, error, warning, pristine, initial }
        } = this.props;
        const { showOptionList, focused } = this.state;
        const { dropdownRef, menuRef, selectOption, onKeyDown, onFocus, onButtonClick } = this;
        const { value, name } = input;

        const renderHiddenOptions = () => {
            if (options && options.length > 0) {
                return options.map((item, index) => {
                    const hiddenOptionLabelContent = item?.label?.props?.children ?? item.label;
                    return (
                        <option key={index} value={item.value}>
                            {hiddenOptionLabelContent}
                        </option>
                    );
                });
            }
        };

        const renderOptions = () => {
            if (options && options.length > 0) {
                return options.map((item, index) => {
                    const selectItemClassNames = classNames(CLASS_ccp__select__item, { selected: value === item.value });
                    const disabled = !!item?.disabled;

                    const buttonProps = {
                        disabled,
                        value: item.value,
                        className: selectItemClassNames,
                        onClick: (e) => {
                            e.preventDefault();
                            selectOption(item.value);
                        }
                    };

                    if (item.icon) {
                        return (
                            <button key={index} {...buttonProps}>
                                {item.icon} {item.label}
                            </button>
                        );
                    }

                    return (
                        <button key={index} {...buttonProps}>
                            {item.label}
                        </button>
                    );
                });
            }
        };

        const renderLabel = () => {
            if (options && options.length > 0) {
                const filtered = options.find((option) => value === option.value);
                return filtered ? filtered.label : 'Please Select';
            }
            return 'Please Select';
        };

        const renderLabelIcon = () => {
            if (options && options.length > 0) {
                const filtered = options.find((option) => value === option.value);

                return filtered?.icon ? filtered?.icon : '';
            }
            return '';
        };

        const renderSearch = () => {
            return (
                <div className="search">
                    <div className="search__label">{search.label}</div>
                    <Search
                        slim={true}
                        className={search.data && search.data.length >= 1 ? 'result' : ''}
                        render={{
                            status: search.status,
                            placeholder: 'Search for a domain name'
                        }}
                        functions={{
                            cancel: search.cancel,
                            search: search.function,
                            reset: search.reset
                        }}
                        helpers={{
                            keyword: search.helpers.keyword
                        }}
                    />
                    {search.status === 'success' &&
                        search.data.slice(0, 3).map((domain, index) => {
                            return (
                                <button
                                    key={index}
                                    className="search__domain"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        search.contact(domain.id, true);
                                        selectOption(domain.attributes.domain);
                                    }}
                                >
                                    {domain.attributes.domain}
                                </button>
                            );
                        })}
                </div>
            );
        };

        const formItemClassNames = classNames('form__item', className);

        return (
            <div className={formItemClassNames}>
                <div className="ccp__select">
                    <select id={name + '--ccp__hidden'} {...input} readOnly={readOnly}>
                        {renderHiddenOptions()}
                    </select>

                    <div className="form__title">
                        {!!label && (
                            <label>
                                {label}
                                {!!tooltip && <Tooltip info={tooltip} />}
                            </label>
                        )}
                    </div>

                    <button
                        type="button"
                        className={`ccp__select--toggle${touched && error ? ' ccp__select--toggle-error' : ''}${focused ? ' focused' : ''}`}
                        ref={dropdownRef}
                        disabled={disabled}
                        onClick={disabled ? null : onButtonClick}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                    >
                        {renderLabelIcon()}
                        {renderLabel()}
                        <PhosphorIcons.Chevron state={showOptionList ? 'up' : 'down'} />
                    </button>

                    {!disabled && !readOnly && showOptionList && (
                        <div ref={menuRef} className={`ccp__select--menu ${label ? 'label' : ''}`}>
                            {renderOptions()}
                            {search && renderSearch()}
                        </div>
                    )}
                    <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} pristine={pristine} initial={initial} />
                </div>
            </div>
        );
    }
}
/**
 * @deprecated use CheckBoxList.Item.ReduxForm instead
 */
export const renderCheckboxField = ({
    input,
    label,
    type,
    className,
    disabled,
    noBackground,
    onClick,
    meta: { dispatch, form, touched, error, warning, pristine, initial }
}) => {
    const onKeyDown = (e) => {
        if (e.key === ' ' && !disabled) {
            // Stop spacebar scrolling the page
            e.preventDefault();
            dispatch(change(form, input.name, !input.value));
            if (onClick && typeof onClick === 'function') {
                onClick(e);
            }
        }
    };

    // Eslint warnings disabled rather than fixed as this component is deprecated anyway
    return (
        <div
            className={`form__item checkbox${className ? ' ' + className : ''}${disabled ? ' checkbox--disabled' : ''}${
                noBackground ? ' checkbox--noBackground' : ''
            }`}
        >
            <div className="form__item__inner">
                <div className="wrapperbox">
                    <input {...input} id={input.name} placeholder={label} type={type} className="" disabled={disabled} />
                    {/* eslint-disable-next-line */}
                    <label htmlFor={input.name} className="v-checkbox" onClick={onClick}>
                        {/* eslint-disable-next-line */}
                        <span tabIndex={disabled ? null : 0} onKeyDown={onKeyDown}></span>
                        <div className="v-checkbox__label">{label}</div>
                    </label>
                </div>
            </div>
            <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} pristine={pristine} initial={initial} />
        </div>
    );
};

// Eslint warnings disabled rather than fixed as this component is deprecated anyway
/**
 * @deprecated use CheckBoxList.Item instead
 */
export const ControlledCheckbox = ({ label, className, disabled, checked, onClick, noBackground }) => (
    <div
        className={classNames('form__item', 'controlledCheckbox', className, {
            'controlledCheckbox--disabled': disabled,
            'controlledCheckbox--checked': checked,
            'controlledCheckbox--noBackground': noBackground
        })}
    >
        <div className="form__item__inner">
            <div className="wrapperbox">
                {/* eslint-disable-next-line */}
                <label
                    className="v-checkbox"
                    onClick={(e) => {
                        if (!disabled) onClick(e);
                    }}
                >
                    {/* eslint-disable-next-line */}
                    <span
                        // eslint-disable-next-line
                        tabIndex={disabled ? null : 0}
                        onKeyDown={(e) => {
                            if (e.key === ' ' && !disabled) {
                                e.preventDefault();
                                onClick(e);
                            }
                        }}
                    ></span>
                    <div className="v-checkbox__label">{label}</div>
                </label>
            </div>
        </div>
    </div>
);

export const RenderFieldWithLink = ({
    input,
    tooltip,
    readOnly,
    fakevalue,
    linktext,
    linkonclick,
    linkhref,
    label,
    placeholder,
    type,
    meta: { touched, error, warning, pristine, initial }
}) => {
    /***** RENDER HELPERS *****/
    function renderLink() {
        if (linkhref) {
            return (
                <Anchor className="linktext" href={linkhref}>
                    {linktext}
                </Anchor>
            );
        }

        if (linkonclick) {
            return (
                <Anchor className="linktext" onClick={linkonclick}>
                    {linktext}
                </Anchor>
            );
        }

        return null;
    }

    /***** RENDER *****/
    return (
        <div className="form__item">
            <div className="form__title">
                {label ? (
                    <label>
                        {label}
                        {tooltip ? <Tooltip info={tooltip} /> : ''}
                    </label>
                ) : (
                    ``
                )}
            </div>
            <div className="form__item__inner">
                <div className="wrapperbox hasCTA">
                    {fakevalue ? (
                        <input {...input} readOnly={readOnly} value="fakevalue" placeholder={placeholder} type={type} />
                    ) : (
                        <input {...input} readOnly={readOnly} placeholder={placeholder} type={type} />
                    )}
                    {renderLink()}
                </div>
                <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} pristine={pristine} initial={initial} />
            </div>
        </div>
    );
};

export class RenderRadioGroupWithNotification extends Component {
    render() {
        const {
            label,
            input,
            options,
            className,
            outlined,
            meta: { dispatch, form, touched, error, warning, pristine, initial }
        } = this.props;
        const { value, name } = input;

        const renderRadio = (options) => {
            return options.map((item, index) => {
                return (
                    <button
                        type="button"
                        key={index}
                        className={`RadioGroup__option${item.value === value ? ' RadioGroup__option--selected' : ''}`}
                        onClick={() => {
                            dispatch(change(form, name, item.value));
                        }}
                        onKeyDown={(e) => {
                            if (e.key === ' ') {
                                e.preventDefault();
                                dispatch(change(form, name, item.value));
                            }
                        }}
                    >
                        <div className="option__content">
                            <div className="option__input">
                                <Radio.Circle isActive={item.value === value} />
                                <input
                                    style={{ display: 'none' }}
                                    type="radio"
                                    value={item.value}
                                    name={name}
                                    checked={item.value === value}
                                    readOnly="readOnly"
                                />
                            </div>
                            <div className="option__label">
                                <div className="option__title">{item.label}</div>
                                {item.desc ? <div className="option__desc">{item.desc}</div> : ''}
                            </div>
                        </div>
                        {item.notification ? <DialogNotification type={item.notification.type}>{item.notification.text}</DialogNotification> : ''}
                    </button>
                );
            });
        };

        return (
            <div className="form__item">
                {label ? <label>{label}</label> : ''}
                <div className={`notification RadioGroup${className ? ' ' + className : ''}${outlined ? ' RadioGroup--outlined' : ''}`}>
                    <div className="RadioGroup__container"> {renderRadio(options)} </div>
                    <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} pristine={pristine} initial={initial} />
                </div>
            </div>
        );
    }
}

export const RenderRadioButtonSet = ({ label, options, input: { value, name }, color, size, meta: { dispatch, form } }) => {
    return (
        <div className="form__item">
            <RadioButtonSet
                label={label}
                options={options}
                activeOption={value}
                setActiveOption={(optionValue) => dispatch(change(form, name, optionValue))}
                color={color}
                size={size}
            />
        </div>
    );
};

export class RenderRevealPasswordField extends Component {
    static typeStates = {
        PASSWORD: 'password',
        TEXT: 'text'
    };
    static iconStates = {
        ICON_EYE_OPEN: 'icon-eye-open',
        ICON_EYE_CLOSED: 'icon-eye-closed'
    };

    constructor(props) {
        super(props);
        const { PASSWORD } = RenderRevealPasswordField.typeStates;
        const { ICON_EYE_CLOSED } = RenderRevealPasswordField.iconStates;
        this.state = {
            type: PASSWORD,
            toggleIcon: ICON_EYE_CLOSED
        };
    }

    toggleField = (e) => {
        e.preventDefault();
        const { TEXT, PASSWORD } = RenderRevealPasswordField.typeStates;
        const { ICON_EYE_CLOSED, ICON_EYE_OPEN } = RenderRevealPasswordField.iconStates;

        const { type } = this.state;
        const hiddenPassword = type === PASSWORD;

        this.setState({
            type: hiddenPassword ? TEXT : PASSWORD,
            toggleIcon: hiddenPassword ? ICON_EYE_OPEN : ICON_EYE_CLOSED
        });
    };

    render() {
        const { input, label, placeholder, tooltip, onkeyup, meta, autoComplete } = this.props;
        const { type, toggleIcon } = this.state;
        const { toggleField } = this;

        return (
            <FormItem name={input.name}>
                <FormLabel htmlFor={input.name}>
                    <Flex gap={0}>
                        {label}
                        {tooltip ? <Tooltip info={tooltip} /> : ''}
                    </Flex>
                </FormLabel>
                <FormItemInner meta={meta}>
                    <input {...input} id={input.name || ''} onKeyUp={onkeyup} placeholder={placeholder} type={type} autoComplete={autoComplete} />
                    {Boolean(label) && (
                        <button type="button" className="form__reveal" onClick={toggleField}>
                            <i className={`icon ${toggleIcon}`} />
                        </button>
                    )}
                </FormItemInner>
            </FormItem>
        );
    }
}

export const renderCardNumberField = ({
    input,
    label,
    readOnly,
    placeholder,
    type,
    value,
    disabled,
    meta: { form, dispatch, touched, error, warning, pristine, initial }
}) => {
    const cardType = detectCardType(input.value);
    const renderCCLogo = () => {
        switch (cardType) {
            case CREDIT_CARD_TYPES.VISA:
                return <PhosphorIcons.Visa size={26} />;
            case CREDIT_CARD_TYPES.MASTER_CARD:
                return <PhosphorIcons.MasterCard size={26} />;
            case CREDIT_CARD_TYPES.AMERICAN_EXPRESS:
                return <PhosphorIcons.Amex size={26} />;
            default:
                return <PhosphorIcons.CreditCard size={26} />;
        }
    };

    const formItemClasses = classNames('form__item', 'form__item__cardnumber', { 'form__item--disabled': disabled });
    return (
        <div className={formItemClasses}>
            {label ? <label>{label}</label> : ``}
            <div className="form__item__inner">
                <div className={classNames('wrapperbox', { error: returnErrorAndWarnClass(touched, error, warning, !touched), readonly: readOnly })}>
                    {renderCCLogo()}
                    <input
                        {...input}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        type={type}
                        disabled={disabled}
                        onChange={(e) => {
                            const value = e.target.value;
                            const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
                            const matches = v.match(/\d{4,16}/g);
                            const match = (matches && matches[0]) || '';
                            const parts = [];
                            for (let i = 0, len = match.length; i < len; i += 4) {
                                parts.push(match.substring(i, i + 4));
                            }
                            if (parts.length) {
                                dispatch(change(form, input.name, parts.join(' ')));
                            } else {
                                dispatch(change(form, input.name, value));
                            }
                        }}
                    />
                </div>
                <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} pristine={pristine} initial={initial} />
            </div>
        </div>
    );
};

export const renderCVVNumberField = ({ disabled, input, label, placeholder, type, img, meta: { touched, error, warning, pristine, initial } }) => {
    const formItemClasses = classNames('form__item', 'cvv', { 'form__item--disabled': disabled });

    return (
        <div className={formItemClasses}>
            <FormLabel htmlFor={input?.name}>
                {Boolean(label) && (
                    <Flex gap={0} items="center">
                        {label}
                        {Boolean(img) && (
                            <Tooltip
                                className="CVVToolTip"
                                info={<img width="211" height="auto" alt="Back of credit card cvv example" src={img} />}
                            />
                        )}
                    </Flex>
                )}
            </FormLabel>
            <div className="form__item__inner">
                <div className={classNames('wrapperbox', { error: returnErrorAndWarnClass(touched, error, warning, !touched) })}>
                    <input {...input} maxLength={10} placeholder={placeholder} type={type} disabled={disabled} />
                </div>
                <ValidationMessage open={touched && error} warn>
                    {error || 'Invalid CVV'}
                </ValidationMessage>
            </div>
        </div>
    );
};

export class RenderTransparentField extends Component {
    constructor(props) {
        super(props);
        const { parent } = this.props;
        parent.inputRefs = {};
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        const { meta, parent } = this.props;
        const { touched } = meta;
        if (parent && touched !== nextprops.meta.touched) {
            parent.setState({
                ...parent.state,
                touched: nextprops.meta.touched
            });
        }
    }
    render() {
        const { input, parent } = this.props;
        const { name } = input;
        return (
            <input
                {...input}
                className="transparent"
                {...this.props}
                ref={(el) => {
                    parent.inputRefs[name] = el;
                }}
            />
        );
    }
}

export class RenderCardExpiryFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            touched: false
        };
        this.expiryFieldValidation = this.expiryFieldValidation.bind(this);
        this.onChangeExpiryValidation = this.onChangeExpiryValidation.bind(this);
        this.onExpiryMonthPressed = this.onExpiryMonthPressed.bind(this);
    }

    expiryFieldValidation(value, fields) {
        const creditCardMonth = fields[CREDIT_CARD_FIELD_DATA.CARD_MONTH.name];
        const creditCardYear = fields[CREDIT_CARD_FIELD_DATA.CARD_YEAR.name];

        if (!creditCardMonth || !creditCardYear) {
            const message = 'Required';
            this.setState({ errorMessage: message });
            return message;
        }
        //regex to check if month is 2 digits, and is 1 to 12
        if (!/^((0?[1-9])|(1[0-2]))$/.test(creditCardMonth)) {
            this.setState({ errorMessage: 'Invalid Month' });
            return 'Invalid Month';
        }

        //regex to check if year is 2 digits, and is 00 to 99
        if (!/^\d{2}$/.test(creditCardYear)) {
            this.setState({ errorMessage: 'Invalid Year' });
            return 'Invalid Year';
        }

        const now = new Date();
        const month = now.getMonth() + 1;
        // minus 2000 to get the last 2 digits of the year
        const year = now.getFullYear() - 2000;

        if (parseInt(creditCardYear) < year || (parseInt(creditCardYear) === year && parseInt(creditCardMonth) < month)) {
            this.setState({ errorMessage: 'Invalid Expiry Date' });
            return 'Invalid Expiry Date';
        }

        this.setState({ errorMessage: null });
        return null;
    }

    onChangeExpiryValidation(e) {
        this.setState({
            touched: true
        });
    }

    onExpiryMonthPressed(e) {
        const val = e.currentTarget.value;
        const isShiftTab = (e.key === 'Tab' && e.shiftKey) || e?.code?.includes?.('Shift');
        const { inputRefs } = this;

        const creditCardMonth = inputRefs[CREDIT_CARD_FIELD_DATA.CARD_MONTH.name];
        const creditCardYear = inputRefs[CREDIT_CARD_FIELD_DATA.CARD_YEAR.name];

        if (val.length === 2 && !isShiftTab && e.target === creditCardMonth) {
            creditCardYear.focus();
        }
    }

    render() {
        const { expiryFieldValidation, onExpiryMonthPressed } = this;
        const { disabled } = this.props;
        const { errorMessage, touched } = this.state;
        const formItemClasses = classNames('form__item', 'expiry_fields', { 'form__item--disabled': disabled });

        return (
            <div className={formItemClasses}>
                <label htmlFor={CREDIT_CARD_FIELD_DATA.CARD_EXPIRY.name}>Expiry</label>
                <div className="form__item__inner">
                    <div className={classNames('wrapperbox', { error: returnErrorAndWarnClass(touched, errorMessage, '', !touched) })}>
                        <Field
                            component={RenderTransparentField}
                            name={CREDIT_CARD_FIELD_DATA.CARD_MONTH.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_MONTH.autoComplete}
                            parent={this}
                            type="text"
                            placeholder="MM"
                            disabled={disabled}
                            validate={[expiryFieldValidation]}
                            onKeyUp={onExpiryMonthPressed}
                            maxLength={2}
                        />
                        <Field
                            component={RenderTransparentField}
                            name={CREDIT_CARD_FIELD_DATA.CARD_YEAR.name}
                            autoComplete={CREDIT_CARD_FIELD_DATA.CARD_YEAR.autoComplete}
                            parent={this}
                            type="text"
                            placeholder="YY"
                            disabled={disabled}
                            validate={[expiryFieldValidation]}
                            forwardRef={true}
                            maxLength={2}
                        />
                        <span className="separator">/</span>
                    </div>
                    <ValidationMessage open={touched && errorMessage} warn>
                        {errorMessage}
                    </ValidationMessage>
                </div>
            </div>
        );
    }
}

export class RenderAdvanceListSlim extends Component {
    render() {
        const { list, input, label, tooltip, readOnly, placeholder, type, meta, disabled } = this.props;
        const { value } = input;
        const inputname = input.name;
        const { touched, error, warning, pristine, initial, dispatch, form } = meta;
        const renderList = () => {
            if (list && list.length > 0) {
                return list.map((item, index) => {
                    const { attributes } = item;
                    const { displayName, name, desc, destination, content, onClick, tag = '' } = attributes;

                    return (
                        <button
                            type="button"
                            key={index}
                            className={`RenderAdvanceListSlim__item item${value === name ? ' selected' : ''}${content ? ' has__content' : ''}`}
                            onClick={() => {
                                dispatch(change(form, inputname, name));
                                if (onClick) onClick();
                            }}
                            disabled={disabled}
                        >
                            <div className="item__top">
                                <div className="item__column item__column--left">
                                    <span className="item__radio"></span>
                                </div>
                                <div className="item__column item__column--right">
                                    <div className="item__display">
                                        <span className="item__label">{displayName}</span>
                                        {tag}
                                    </div>
                                    <span className="item__desc">
                                        {desc}
                                        {destination ? <span className="item__desc-dest">{destination}</span> : ''}
                                    </span>
                                </div>
                            </div>
                            {value === name && content ? <div className="item__content">{content}</div> : ''}
                        </button>
                    );
                });
            }
        };
        return (
            <div className="form__item RenderAdvanceListSlim">
                <div className="form__title">
                    {label ? (
                        <label>
                            {label}
                            {tooltip ? <Tooltip info={tooltip} /> : ''}
                        </label>
                    ) : (
                        ``
                    )}
                </div>
                <div className="form__item__inner RenderAdvanceListSlim__inner">
                    <div className={`wrapperbox RenderAdvanceListSlim__wrapperbox ${readOnly ? 'readonly' : ''}`}>
                        <input {...input} readOnly={readOnly} placeholder={placeholder} type={type} />
                        <div className="form__item__list">{renderList()}</div>
                    </div>
                    <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} pristine={pristine} initial={initial} />
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   TABLE FORM ELEMENTS
 **********************************************************************************************************/

export function TableRenderField({ input, attributes, readOnly, append, tabIndex, className, meta: { touched, error, warning, pristine, initial } }) {
    /*   SET CONDITIONAL PROPS
     **********************************************************************************************************/
    const conditionalProps = {
        ...input,
        ...attributes,
        className: `${append ? 'appended' : ''}`,
        readOnly: readOnly ? readOnly : undefined,
        tabIndex: tabIndex ? tabIndex : 0
    };

    return (
        <div className={classNames('sharedTable__input', className)}>
            <div
                className={`sharedTable__input--wrapper${append ? ' isAppended ' : ''}${readOnly ? ' readonly ' : ''}${
                    returnErrorAndWarnClass(touched, error, warning, pristine) ? ' error' : ''
                }`}
            >
                <input {...conditionalProps} />
                {append ? (
                    <div className="appendedText">
                        {append.text}
                        <Tooltip
                            info={`This text will be appended to the end of anything you type into this input field. <br/><br/> For example, entering 'abc123' will become abc123${append.text} when the form is submitted.`}
                        >
                            <PhosphorIcons.WarningCircle />
                        </Tooltip>
                    </div>
                ) : (
                    ''
                )}
                {renderTableError(touched, error, warning)}
            </div>
        </div>
    );
}

export function TableRenderSelectField({
    label,
    input,
    settings,
    options,
    className,
    readOnly,
    ellipsized,
    selectOnChange,
    meta: { form, touched, error, warning, pristine, initial, dispatch }
}) {
    const { value, name } = input;

    /***** STATE *****/
    const [showOptionList, setShowOptionsList] = useState(false);

    /***** HOOKS *****/
    const containerRef = useRef(null);

    /***** FUNCTIONS *****/
    function showOptions() {
        setShowOptionsList(true);
    }

    function closeOptions(e) {
        if (!e || !containerRef.current || !containerRef.current.contains(e.target)) {
            setShowOptionsList(false);
        }
    }

    function selectOption(form, name, value) {
        dispatch(change(form, name, value));
        closeOptions();
        if (selectOnChange) {
            selectOnChange(name, value);
        }
    }

    /***** EFFECTS *****/
    // Clickaway
    useEffect(() => {
        document.addEventListener('click', closeOptions);

        return () => document.removeEventListener('click', closeOptions);
    }, []);

    useEffect(() => {
        if (options && options.length > 0) {
            options.forEach((item, index) => {
                if (index === 0) {
                    if (!name.includes('index')) {
                        selectOption(form, name, item.value);
                    }
                }
            });
        }
    }, []);

    /***** RENDER HELPERS *****/
    const renderHiddenOptions = () => {
        if (options && options.length > 0) {
            return options.map((item, index) => {
                return (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                );
            });
        }
    };

    const renderOptions = () => {
        if (options && options.length > 0) {
            return options.map((item, index) => {
                const selectItemClassNames = classNames(CLASS_ccp__select__item, { selected: value === item.value });
                if (item.icon) {
                    return (
                        <button
                            key={index}
                            type="button"
                            className={selectItemClassNames}
                            value={item.value}
                            onClick={(e) => {
                                e.preventDefault();
                                selectOption(form, name, item.value);
                            }}
                        >
                            {item.icon} {item.label}
                        </button>
                    );
                }

                return (
                    <button
                        key={index}
                        type="button"
                        className={selectItemClassNames}
                        value={item.value}
                        onClick={(e) => {
                            e.preventDefault();
                            selectOption(form, name, item.value);
                        }}
                    >
                        {item.label}
                    </button>
                );
            });
        }
    };

    const renderLabel = () => {
        if (options && options.length > 0) {
            const filtered = options.filter((option) => value === option.value);

            let label;

            if (settings && settings.isTag) label = filtered && filtered.length > 0 ? filtered[0].label : options[0].label;

            if (filtered && filtered.length > 0) label = filtered[0].label;

            return ellipsized ? `${label.substring(0, ellipsized.chars)}${label.length > ellipsized.chars ? '...' : ''}` : label;
        }
        return 'Please Select';
    };

    const renderLabelIcon = () => {
        if (options && options.length > 0) {
            const filtered = options.filter((option) => value === option.value);

            return filtered && filtered.length > 0 && filtered[0].icon ? filtered[0].icon : '';
        }
        return '';
    };

    /***** RENDER *****/
    return (
        <div ref={containerRef} className={`ccp__select isTable${className ? ` ${className} ` : ' '}`}>
            <select id={name + '--ccp__hidden'} {...input} readOnly={readOnly}>
                {renderHiddenOptions()}
            </select>

            <button
                type="button"
                className={`ccp__select--toggle${touched && error ? ' sharedTable__select--toggle-error' : ''}`}
                onClick={() => (showOptionList ? closeOptions() : showOptions())}
            >
                {renderLabelIcon()}
                {renderLabel()}
                <i className="icon icon-chevron-down"></i>
            </button>

            {showOptionList ? <div className={`ccp__select--menu ${label ? 'label' : ''}`}>{renderOptions()}</div> : null}

            {renderTableError(touched, error, warning)}
        </div>
    );
}

/**********************************************************************************************************
 *   DYNAMIC FORM BUILDER (builds based on backend data)
 **********************************************************************************************************/

/*   Globally required functions
 *****************************************************/

export const validationFuncStringEnum = {
    REQUIRED: 'required',
    DATE: 'date',
    VALID_DATE: 'valid_date',
    TIME: 'time',
    VALID_TIME: 'valid_time',
    HIDDEN: 'hidden',
    BOOLEAN: 'boolean',
    INTEGER: 'integer',
    IP: 'ip',
    IP_LONG: 'ip_long',
    EMAIL: 'email',
    ACCEPTED: 'accepted',
    IN_ARRAY: 'inArray'
};
export const getValidationFuncFromString = (str) => {
    const newString = str.includes('in:') ? 'inArray' : str;

    switch (newString) {
        case 'required':
            return requiredFieldValidation;
        case 'date':
        case 'valid_date':
            return dateValidation;
        case 'time':
        case 'hidden':
            return requiredEmptyValidation;
        case 'boolean':
            return booleanValidation;
        case 'integer':
            return numberValidation;
        case 'integer_dynamicForm_migration':
            return numberValidation;
        // IP vs IP long needs to be handled differently. the error message should not come from here. rather it should only validate and source the string from somewhere else. this can get convoluted quickly if more were to be added for similar edge cases
        case 'ip':
            return ipAddressValidation;
        case 'ip_long':
            return ipAddressValidationLongFormat;
        case 'email':
            return emailFieldValidation;
        case 'accepted':
            return requiredAcceptedValidation;
        case 'inArray': {
            const arr = str.split('in:')[1].split(',');
            return (value) => isInArrayValidation(value, arr);
        }
        default:
            return null;
    }
};

export const formatPayloadWithRepeatables = (values) => {
    const repeatableFieldGroupsObject = {};
    const requestObject = { ...values };

    const repeatableFields = Object.keys(requestObject)
        .filter((item) => item.includes('--repeated'))
        .map((item) => {
            const meta = item.split('--');
            const groupMeta = meta[1].split('__');

            return {
                originalKey: item,
                fieldName: meta[0],
                number: groupMeta[1],
                group: groupMeta[2]
            };
        });

    const groups = repeatableFields.map((item) => item.group).filter((item, i, arr) => arr.indexOf(item) === i);
    groups.forEach((group) => {
        const matchingGroup = repeatableFields.filter((item) => item.group === group);
        const highest = matchingGroup.map((item) => item.number).sort()[matchingGroup.length - 1];

        repeatableFieldGroupsObject[group] = [];
        for (let j = 0; j < highest; j++) {
            repeatableFieldGroupsObject[group].push({});
        }
    });

    repeatableFields.forEach((item) => {
        repeatableFieldGroupsObject[item.group][item.number - 1][item.fieldName] = values[item.originalKey];
    });

    repeatableFields.forEach((item) => {
        delete requestObject[item.originalKey];
    });

    return {
        ...requestObject,
        ...repeatableFieldGroupsObject
    };
};

/**********************************************************************************************************
 *   END DYNAMIC FORM BUILDER
 **********************************************************************************************************/

export { RenderRadioGroup };

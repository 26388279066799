/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import googleLogo from 'assets/images/powered_by_google.png';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector } from 'redux-form';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import ComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { getCountries, getLocalStates } from 'App/action';
import { pushNotification } from 'components/Toast/functions';
import {
    RenderField,
    RenderSelectField,
    generatePostcodeValidation,
    requiredFieldValidation,
    validateStateField,
    validateStateFieldASCII
} from 'utilities/methods/form';
import { getAddressDetails, getAutocompleteSuggestions } from './action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_AutocompleteAddress.scss';

/**
 * @typedef {import('redux-form').InjectedFormProps} injectedFormProps
 * @typedef {Readonly<{
 *   selectedCountry: string,
 *   selectedState: string,
 *   onlyAUandNZ: boolean,
 *   halfWidthFields: boolean,
 *   fieldOrder: string[],
 *   form: string
 * }> & ReturnType<typeof mapStateToProps>} props
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AutocompleteAddress extends Component {
    /** @param {props} props */
    constructor(props) {
        super(props);

        const { selectedCountry } = props;

        this.state = {
            autocompleteOptions: [],
            addManually: false,
            autocompleteSessionToken: null,
            focusedOption: null,
            postcodeValidation: generatePostcodeValidation(selectedCountry)
        };

        this.processAutocompleteOptions = this.processAutocompleteOptions.bind(this);
        this.resetAutocompleteOptions = this.resetAutocompleteOptions.bind(this);
        this.clickAway = this.clickAway.bind(this);
        this.fillFieldsFromAutocomplete = this.fillFieldsFromAutocomplete.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.focusOption = this.focusOption.bind(this);
        this.requestAddressDetails = this.requestAddressDetails.bind(this);
    }

    focusOption(focusedOption = null) {
        this.setState({
            focusedOption
        });
    }

    onKeyDown(e) {
        const { autocompleteOptions, focusedOption } = this.state;
        const { focusOption, requestAddressDetails, resetAutocompleteOptions } = this;

        if (autocompleteOptions?.length > 0) {
            switch (e.key) {
                case 'ArrowDown':
                case 'Down':
                    e.preventDefault();
                    if (focusedOption === null) focusOption(0);
                    else if (focusedOption < autocompleteOptions.length - 1) focusOption(focusedOption + 1);
                    break;
                case 'ArrowUp':
                case 'Up':
                    e.preventDefault();
                    if (focusedOption === null) focusOption(autocompleteOptions.length - 1);
                    else if (focusedOption > 0) focusOption(focusedOption - 1);
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (focusedOption !== null && focusedOption >= 0 && focusedOption < autocompleteOptions.length) {
                        // Select add manually
                        if (focusedOption === autocompleteOptions.length - 1) resetAutocompleteOptions(true);
                        // Select any other option
                        else {
                            const { description, place_id } = autocompleteOptions[focusedOption];
                            requestAddressDetails(place_id, description);
                        }
                    }
                    break;
                case 'Escape':
                case 'Esc':
                    e.preventDefault();
                    resetAutocompleteOptions();
                    break;
                case 'Tab':
                    resetAutocompleteOptions();
                    break;
                default:
                    break;
            }
        }
    }

    requestAddressDetails(place_id, description) {
        const { getAddressDetails, form } = this.props;
        const { autocompleteSessionToken } = this.state;

        getAddressDetails(form, autocompleteSessionToken, place_id, description, document.createElement('div'), () => {
            this.setState({
                autocompleteSessionToken: null
            });
        });
    }

    processAutocompleteOptions(predictions) {
        return predictions
            .map((item) => {
                const autoCompleteItem = {
                    place_id: item.place_id,
                    description: item.description
                };
                return item.place_id && item.description ? autoCompleteItem : null;
            })
            .filter((item) => item);
    }

    resetAutocompleteOptions(disable) {
        this.setState({
            autocompleteOptions: [],
            addManually: disable ? true : false,
            focusedOption: null
        });
    }

    clickAway(e) {
        const { resetAutocompleteOptions } = this;
        const { autocompleteOptions } = this.state;

        if (e?.path?.[0]?.name !== 'address1' && autocompleteOptions?.length > 0) {
            resetAutocompleteOptions();
        }
    }

    fillFieldsFromAutocomplete() {
        const { autocomplete_address_details_data, app_local_states_data, form, change, fieldOrder } = this.props;

        const {
            processedOptions: { au, nz }
        } = app_local_states_data;

        const addressObj = {
            subpremise: '',
            street_number: '',
            route: '',
            sublocality: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: ''
        };

        const getStateSelection = (options, selection) => {
            const isWhanganui = (word) => {
                const lc = word.toLowerCase();
                return lc === 'manawatu-wanganui' || lc === 'manawatu-whanganui';
            };

            const matchingOption = options.find((option) => {
                // There is a discrepancy between Google API results and wikipedia spelling of "Whanganui", this is just to cover both spellings
                if (isWhanganui(selection) && isWhanganui(option.label)) {
                    return true;
                }
                return selection.toLowerCase() === option.label.toLowerCase();
            });

            return matchingOption.value || '';
        };

        try {
            const { address_components } = autocomplete_address_details_data;

            address_components.forEach((component) => {
                component.types.forEach((type) => {
                    if (addressObj[type] === '') {
                        if (type === 'administrative_area_level_1') {
                            addressObj[type] = component.long_name;
                        } else {
                            addressObj[type] = component.short_name;
                        }
                    }
                });
            });
        } catch (e) {
            // Do nothing. Try catch block is just guarding against any properties not existing on the google API response
        }

        const suburbIsSublocality = addressObj.locality && addressObj.locality === addressObj.administrative_area_level_1;

        // account for unit addresses like 10/66 Victor Cres where the details request doesn't return a street number
        if (!addressObj.subpremise && !addressObj.street_number) {
            try {
                const desc = autocomplete_address_details_data.selectedDescription;
                const [unit, building] = desc.split(' ')[0].split('/');
                if (!isNaN(unit) && !isNaN(building)) {
                    addressObj.subpremise = unit;
                    addressObj.street_number = building;
                }
            } catch (e) {
                // Do nothing. Try catch block is just guarding against description format not being as expected
            }
        }

        const nonBlanks = Object.values(addressObj).filter((value) => value !== '').length;

        const cityFieldName = fieldOrder.includes('city') ? 'city' : 'suburb';

        function renderStateFormValue() {
            if (['AU', 'NZ'].includes(addressObj.country)) {
                return getStateSelection(addressObj.country === 'NZ' ? nz : au, addressObj.administrative_area_level_1);
            }

            return '';
        }

        function renderAddress2Value() {
            if (suburbIsSublocality) return '';
            if (addressObj.sublocality === addressObj.locality) return '';
            return addressObj.sublocality;
        }

        if (nonBlanks > 0) {
            change(
                form,
                'address1',
                `${addressObj.subpremise && addressObj.street_number ? addressObj.subpremise + '/' : ''}${
                    addressObj.street_number ? addressObj.street_number + ' ' : ''
                }${addressObj.route}`
            );
            change(form, 'address2', renderAddress2Value());
            change(form, cityFieldName, suburbIsSublocality ? addressObj.sublocality : addressObj.locality);
            change(form, 'state', renderStateFormValue());
            change(form, 'country', addressObj.country);
            change(form, 'postcode', addressObj.postal_code);
        } else {
            pushNotification({ status: 418, details: 'Address autocomplete failed' });
        }

        this.setState({
            autocompleteOptions: [],
            focusedOption: null
        });
    }

    /*   LIFECYCLE METHODS
     *****************************************************/
    componentDidMount() {
        const {
            onlyAUandNZ,
            /**
             * Redux State
             */
            app_local_states_data,
            app_countries_data,
            app_countries_status
        } = this.props;
        const { clickAway, onKeyDown } = this;

        document.addEventListener('click', clickAway);
        document.addEventListener('keydown', onKeyDown);

        if (!app_local_states_data) getLocalStates();

        if (!onlyAUandNZ && !app_countries_data && app_countries_status !== 'loading') {
            getCountries();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            app_local_states_data,
            autocomplete_address_suggestions_status,
            autocomplete_address_suggestions_data,
            autocomplete_address_details_status,
            autocomplete_address_details_data,
            form,
            change,
            selectedCountry,
            selectedState,
            postcode
        } = /** @type {props} */ (this.props);
        const { processAutocompleteOptions, fillFieldsFromAutocomplete } = this;

        if (autocomplete_address_suggestions_status === 'success' && prevProps.autocomplete_address_suggestions_status !== 'success') {
            if (
                autocomplete_address_suggestions_data &&
                autocomplete_address_suggestions_data.predictions &&
                autocomplete_address_suggestions_data.predictions.length > 0 &&
                autocomplete_address_suggestions_data.form === form
            ) {
                this.setState({
                    autocompleteOptions: [...processAutocompleteOptions(autocomplete_address_suggestions_data.predictions), 'Add manually']
                });
            }
        }

        if (
            autocomplete_address_details_status === 'success' &&
            prevProps.autocomplete_address_details_status === 'loading' &&
            autocomplete_address_details_data &&
            autocomplete_address_details_data.form === form
        ) {
            fillFieldsFromAutocomplete();
        }

        if (app_local_states_data && selectedCountry !== prevProps.selectedCountry) {
            const {
                processedOptions: { au, nz }
            } = app_local_states_data;

            if (['NZ', 'AU'].includes(selectedCountry)) {
                const stateList = selectedCountry === 'NZ' ? nz : au;
                if (!stateList.find((option) => option.value === selectedState)) {
                    change(form, 'state', '');
                }
            }
        }

        if (selectedCountry !== prevProps.selectedCountry) {
            /**
             * Because the postcode validation is regenerated in the state but the field is unaware that the validation has changed
             * we need to force the field to revalidate by modifying the value of the field - adding a space and then removing it on the next frame
             * does this with no visual impact to the user
             */
            this.setState(
                {
                    postcodeValidation: generatePostcodeValidation(selectedCountry)
                },
                () => {
                    if (prevProps.selectedCountry) {
                        const isAuNz = (country) => ['AU', 'NZ'].includes(country);
                        const isGoingToAuNz = isAuNz(selectedCountry) && !isAuNz(prevProps.selectedCountry);
                        const isLeavingAuNz = !isAuNz(selectedCountry) && isAuNz(prevProps.selectedCountry);

                        if (isGoingToAuNz || isLeavingAuNz) {
                            change(form, 'postcode', postcode + ' ');
                            setTimeout(() => change(form, 'postcode', postcode));
                        }
                    }
                }
            );
        }
    }

    componentWillUnmount() {
        const { clickAway, onKeyDown } = this;

        document.removeEventListener('click', clickAway);
        document.removeEventListener('keyDown', onKeyDown);
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const {
            app_local_states_status,
            app_local_states_data,
            getAutocompleteSuggestions,
            domain_information_data,
            form,
            selectedCountry,
            onlyAUandNZ,
            biasAUAndNZ,
            halfWidthFields,
            fieldOrder,
            app_countries_data,
            disabled
        } = /** @type {props} */ (this.props);
        const { autocompleteOptions, addManually, autocompleteSessionToken, focusedOption, postcodeValidation } = this.state;
        const { requestAddressDetails, resetAutocompleteOptions } = this;
        const domain = domain_information_data?.attributes?.domain ?? '';
        const isAUDomain = domain.endsWith('.au');

        const renderAutocompleteOptions = () => {
            if (autocompleteOptions?.length <= 0) return null;

            const disableAutocomplete = () => {
                resetAutocompleteOptions(true);
            };

            const mapCallbackWithVariables = (JSX) => (option, index) => {
                const { description, place_id } = option;
                const listClasses = classNames({
                    'Autocomplete__option': true,
                    'Autocomplete__option--focused': focusedOption === index
                });

                return JSX({ description, place_id, listClasses, option, index });
            };

            return (
                <ul className="Autocomplete__suggestions">
                    {autocompleteOptions.map(
                        mapCallbackWithVariables(({ option, index, description, place_id, listClasses }) => (
                            <li key={index} className={listClasses}>
                                {option === 'Add manually' ? (
                                    <button onClick={disableAutocomplete}>I want to add manually</button>
                                ) : (
                                    <button onClick={() => requestAddressDetails(place_id, description)}>{description}</button>
                                )}
                            </li>
                        ))
                    )}
                    <li className="Autocomplete__googleLogo">
                        <img src={googleLogo} alt="Powered by Google" />
                    </li>
                </ul>
            );
        };

        const renderOneField = (fieldName) => {
            const {
                processedOptions: { au, nz }
            } = app_local_states_data;

            function getCountryOptionsMapped() {
                if (onlyAUandNZ) return [];

                return (
                    app_countries_data
                        ?.filter(({ attributes }) => !['AU', 'NZ'].includes(attributes.country_code))
                        .map(({ attributes: { country, country_code } }) => ({ label: country, value: country_code })) ?? []
                );
            }

            switch (fieldName) {
                case 'address1':
                    return (
                        <div className={`form__column form__column--autocomplete ${halfWidthFields ? 'half' : 'full'}`}>
                            <Field
                                label="Address Line 1"
                                name="address1"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation]}
                                className="form__textfield"
                                onChange={(_, value) => {
                                    if (typeof window.google?.maps?.places?.AutocompleteSessionToken !== 'function') {
                                        return;
                                    }

                                    if (!addManually) {
                                        if (!value) {
                                            return this.setState({
                                                autocompleteOptions: []
                                            });
                                        }
                                        if (autocompleteSessionToken) {
                                            return getAutocompleteSuggestions(
                                                form,
                                                autocompleteSessionToken,
                                                value,
                                                onlyAUandNZ ? ['au', 'nz'] : false,
                                                !onlyAUandNZ && biasAUAndNZ ? true : false
                                            );
                                        }
                                        const token = new window.google.maps.places.AutocompleteSessionToken();
                                        this.setState(
                                            {
                                                autocompleteSessionToken: token
                                            },
                                            () => {
                                                getAutocompleteSuggestions(
                                                    form,
                                                    token,
                                                    value,
                                                    onlyAUandNZ ? ['au', 'nz'] : false,
                                                    !onlyAUandNZ && biasAUAndNZ ? true : false
                                                );
                                            }
                                        );
                                    }
                                }}
                                disabled={disabled}
                            />
                            {renderAutocompleteOptions()}
                        </div>
                    );

                case 'address2':
                    return (
                        <div className={`form__column ${halfWidthFields ? 'half' : 'full'}`}>
                            <Field
                                label="Address Line 2"
                                name="address2"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[]}
                                className="form__textfield"
                                disabled={disabled}
                            />
                        </div>
                    );

                case 'city':
                    return (
                        <div className={`form__column ${halfWidthFields ? 'half' : 'full'}`}>
                            <Field
                                label="Suburb"
                                name="city"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation]}
                                className="form__textfield"
                                disabled={disabled}
                            />
                        </div>
                    );

                case 'suburb':
                    return (
                        <div className={`form__column ${halfWidthFields ? 'half' : 'full'}`}>
                            <Field
                                label="Suburb"
                                name="suburb"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation]}
                                className="form__textfield"
                                disabled={disabled}
                            />
                        </div>
                    );

                case 'state': {
                    const stateFieldValidate = isAUDomain ? validateStateFieldASCII : validateStateField;
                    return (
                        <div className={`form__column ${halfWidthFields ? 'half' : 'full'}`}>
                            {['AU', 'NZ'].includes(selectedCountry) ? (
                                <Field
                                    label="State"
                                    name="state"
                                    component={RenderSelectField}
                                    type="select"
                                    validate={[requiredFieldValidation]}
                                    className="form__dropdown"
                                    options={selectedCountry === 'NZ' ? nz : au}
                                    disabled={disabled}
                                />
                            ) : (
                                <Field
                                    label="State"
                                    name="state"
                                    component={RenderField}
                                    type="text"
                                    validate={[requiredFieldValidation, stateFieldValidate]}
                                    className="form__textfield"
                                    disabled={disabled}
                                />
                            )}
                        </div>
                    );
                }
                case 'country': {
                    const finalOptions = [
                        {
                            label: 'Australia',
                            value: 'AU'
                        },
                        {
                            label: 'New Zealand',
                            value: 'NZ'
                        },
                        ...getCountryOptionsMapped()
                    ];

                    return (
                        <div className={`form__column ${halfWidthFields ? 'half' : 'full'}`}>
                            <Field
                                label="Country"
                                name="country"
                                component={RenderSelectField}
                                type="select"
                                validate={[requiredFieldValidation]}
                                className="form__dropdown"
                                options={finalOptions}
                                disabled={disabled}
                            />
                        </div>
                    );
                }

                case 'postcode': {
                    return (
                        <div className={`form__column ${halfWidthFields ? 'half' : 'full'}`}>
                            <Field
                                label="Postcode"
                                name="postcode"
                                component={RenderField}
                                type="text"
                                placeholder=""
                                validate={[requiredFieldValidation, postcodeValidation]}
                                className="form__textfield"
                                disabled={disabled}
                            />
                        </div>
                    );
                }

                default:
                    return '';
            }
        };

        const renderHalfWidthFields = () => {
            return (
                <>
                    <div className="form__row">
                        {renderOneField(fieldOrder[0])}
                        {renderOneField(fieldOrder[1])}
                    </div>
                    <div className="form__row">
                        {renderOneField(fieldOrder[2])}
                        {renderOneField(fieldOrder[3])}
                    </div>
                    <div className="form__row">
                        {renderOneField(fieldOrder[4])}
                        {renderOneField(fieldOrder[5])}
                    </div>
                </>
            );
        };

        const renderFullWidthFields = () => {
            return (
                <>
                    {fieldOrder.map((field, index) => (
                        <div key={index} className="form__row">
                            {renderOneField(field)}
                        </div>
                    ))}
                </>
            );
        };

        const renderComponent = () => {
            return halfWidthFields ? renderHalfWidthFields() : renderFullWidthFields();
        };

        switch (app_local_states_status) {
            case 'success':
                return renderComponent();
            case 'error':
                return <ComponentError />;
            case 'loading':
            default:
                return <RequestLoader />;
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

AutocompleteAddress = connect(
    (state) => ({
        app_local_states_status: state.app.app_local_states_status,
        app_local_states_data: state.app.app_local_states_data,
        autocomplete_address_suggestions_status: state.autocomplete.autocomplete_address_suggestions_status,
        autocomplete_address_suggestions_data: state.autocomplete.autocomplete_address_suggestions_data,
        autocomplete_address_details_status: state.autocomplete.autocomplete_address_details_status,
        autocomplete_address_details_data: state.autocomplete.autocomplete_address_details_data
    }),
    {
        change,
        getAutocompleteSuggestions,
        getAddressDetails
    }
)(AutocompleteAddress);

AutocompleteAddress.propTypes = {
    /**
     * Name of the form
     */
    form: PropTypes.string,
    /**
     * The currently selected country
     */
    selectedCountry: PropTypes.string,

    /**
     * The currently selected state
     */
    selectedState: PropTypes.string,

    /**
     * Whether to only show/use the countries AU and NZ
     */
    onlyAUandNZ: PropTypes.bool,

    /**
     * Whether to only show full with fields or half width fields
     */
    halfWidthFields: PropTypes.bool,

    /**
     * The order of the address fields
     */
    fieldOrder: PropTypes.arrayOf(PropTypes.string),

    /**
     * Disables all the fields
     */
    disabled: PropTypes.bool
};

const mapStateToProps = (state, { form }) =>
    /** @type {const} */ ({
        app_countries_data: state.app.app_countries_data,
        app_countries_status: state.app.app_countries_status,
        app_local_states_data: state.app.app_local_states_data,
        domain_information_data: state.domain.domain_information_data,
        app_local_states_status: state.app.app_local_states_status,
        autocomplete_address_details_data: state.autocomplete.autocomplete_address_details_data,
        autocomplete_address_details_status: state.autocomplete.autocomplete_address_details_status,
        autocomplete_address_suggestions_data: state.autocomplete.autocomplete_address_suggestions_data,
        autocomplete_address_suggestions_status: state.autocomplete.autocomplete_address_suggestions_status,
        postcode: formValueSelector(form)(state, 'postcode')
    });

const mapDispatchToProps = {
    change,
    getAutocompleteSuggestions,
    getAddressDetails
};

AutocompleteAddress = connect(mapStateToProps, mapDispatchToProps)(AutocompleteAddress);

export default withRouter(AutocompleteAddress);

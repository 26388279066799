/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VipRewardsFaqs from 'containers/vipRewards/modules/faqs';
import VipRewardsMoreInfoRewardsCarousel from './rewardsCarousel';
import VipRewardsSignupButton from './signupButton';
import VipRewardsTierIcon from './tierIcon';
import VipRewardsMoreInfoTiersCarousel from './tiersCarousel';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import SimpleTable from 'components/SimpleTable';
import Tooltip from 'components/Tooltip';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { orderedTiers, vipRewardsReadableName } from 'containers/vipRewards/consts';
import { headings, rewardCircles } from './consts';

import BackgroundEmblem from 'assets/images/vipRewards/background emblem.svg';
import MainHero from 'assets/images/vipRewards/main-hero.svg';
import VipRewardsLogo from 'assets/images/vipRewards/vipRewardsLogo.svg';

import { createRoute, redirect } from '@tanstack/react-router';
import { VIPRewardsRootRoute } from 'containers/vipRewards';
import { isVIPMember } from 'containers/vipRewards/utilities';
import './_vipRewardsMoreInfo.scss';
import { useSignupSuccessModalContext } from './signupSuccess/context';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPRewardsMoreInfoRoute = createRoute({
    getParentRoute: () => VIPRewardsRootRoute,
    path: 'learn-more',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);

        if (isVIPMember(opts.context)) {
            if (opts.cause === 'enter') {
                throw redirect({ to: '/vip-rewards' });
            }
        }
    }
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsMoreInfo() {
    /***** SELECTORS *****/
    const app_viewport = useSelector((state) => state.app.app_viewport);

    /***** HOOKS *****/
    const { openSuccessModal } = useSignupSuccessModalContext();
    const vipRewardsInfoNewRef = useRef(null);
    const { rewardsTiers } = useVipRewards();

    /***** RENDER HELPERS *****/
    const renderRewardCircles = () => {
        const renderCircles = () =>
            rewardCircles.map(({ image, title, description, isBold }) => (
                <div key={description} className="vipRewardsInfoCircle">
                    {image}
                    <Text align--center primary size--l lead--none semiBold={!isBold} bold={isBold}>
                        <h5>{title}</h5>
                    </Text>
                    <Text align--center secondary size--s lead--s semiBold={isBold}>
                        {description}
                    </Text>
                </div>
            ));

        if (['sm', 'xs'].includes(app_viewport)) {
            return (
                <VipRewardsMoreInfoRewardsCarousel
                    slides={renderCircles().map((circleElement, index) => (
                        <div key={index}>{circleElement}</div>
                    ))}
                />
            );
        }

        return <div className="vipRewardsInfoNew__circles">{renderCircles()}</div>;
    };

    const renderTiersTable = () => {
        const gridProps = {
            'columns': app_viewport === 'md' ? '85px 85px 85px 85px 85px' : '100px 100px 100px 100px 100px',
            'gap': 2,
            'justify-children--center': true
        };

        const renderTable = () => {
            const renderOneColumn = (tier, headingId) => {
                if (headingId === 'image') {
                    return <VipRewardsTierIcon key={tier} className="vipRewardsInfoTiers__tierIcon" tier={tier} />;
                }

                if (headingId === 'name') {
                    return (
                        <Text className="vipRewardsInfoTiers__tierNameInTable" key={tier} black size--l medium capitalize>
                            {tier === 'vip' ? 'VIP' : tier}
                        </Text>
                    );
                }

                const renderText = () => {
                    const value = rewardsTiers?.[tier]?.[headingId];

                    if (!value) {
                        if (headingId === 'account_age') {
                            const orderedTiersIndex = orderedTiers.findIndex((tierName) => tierName === tier);
                            const nextTierName = orderedTiers[orderedTiersIndex + 1];
                            const nextTierAccountAge = rewardsTiers[nextTierName]?.account_age;

                            return `< ${nextTierAccountAge || 355} years`;
                        }

                        return '-';
                    }

                    switch (headingId) {
                        case 'account_age':
                            return `${value} years`;
                        case 'new_hosting_benefit':
                        case 'new_domain_benefit':
                        case 'existing_hosting_benefit':
                        case 'existing_domain_benefit':
                            return `${value}% off`;
                        default:
                            return '';
                    }
                };

                return (
                    <Text key={tier} secondary size--s>
                        {renderText()}
                    </Text>
                );
            };

            if (['sm', 'xs'].includes(app_viewport)) {
                const carouselGridProps = {
                    'columns': ['xs'].includes(app_viewport) ? '120px' : '150px',
                    'justify-children--center': true
                };

                const slides = orderedTiers.map((orderedTier, index) => (
                    <SimpleTable
                        key={index}
                        rows={React.Children.toArray(
                            headings.map(({ id: headingId }) => (
                                <Grid key={headingId} {...carouselGridProps} className="NXGrid--justify-children--center">
                                    <>{[orderedTier].map((tier) => renderOneColumn(tier, headingId))}</>
                                </Grid>
                            ))
                        )}
                    />
                ));

                return <VipRewardsMoreInfoTiersCarousel slides={slides} />;
            }

            return (
                <SimpleTable
                    rows={React.Children.toArray(
                        headings.map(({ id: headingId }) => (
                            <Grid key={headingId} {...gridProps} className="NXGrid--justify-children--center">
                                <>{orderedTiers.map((tier) => renderOneColumn(tier, headingId))}</>
                            </Grid>
                        ))
                    )}
                />
            );
        };

        return (
            <div className="vipRewardsInfoTiers">
                <div className="vipRewardsInfoTiers__headings">
                    <div className="vipRewardsInfoTiers__headingsBackground" />
                    <Text black size--s semiBold>
                        <h6>Tier</h6>
                    </Text>
                    {headings.slice(2).map(({ id, title, tooltip }) => (
                        <div key={id} className="vipRewardsInfoTiers__headingContainer">
                            <Text secondary size--s medium lead--s>
                                {title}
                            </Text>
                            {tooltip ? <Tooltip info={tooltip} /> : ''}
                        </div>
                    ))}
                </div>

                {renderTable()}

                <VipRewardsSignupButton onSuccess={openSuccessModal} />
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <div className="vipRewardsInfo">
            <div className="vipRewardsInfo__header">
                <img src={VipRewardsLogo} alt={vipRewardsReadableName} />
            </div>

            <div className="vipRewardsInfo__container">
                <div className="vipRewardsInfoDiscover">
                    <img src={BackgroundEmblem} alt={vipRewardsReadableName} />
                    <img src={MainHero} alt={vipRewardsReadableName} />

                    <div className="vipRewardsInfoDiscover__textContent">
                        <Text size--xxxl={!['sm', 'xs'].includes(app_viewport)} size--xl={['sm', 'xs'].includes(app_viewport)} primary semiBold>
                            <h2>Join Our Reward Program</h2>
                        </Text>
                        <Text size--l={!['sm', 'xs'].includes(app_viewport)} black medium lead--s>
                            <h3>At VentraIP, we appreciate your loyalty and want to reward you for choosing us.</h3>
                        </Text>
                        <Text secondary lead--s size--s={['sm', 'xs'].includes(app_viewport)}>
                            Experience the perks of our exclusive rewards program by diving into a world of benefits, including new web hosting,
                            domain name registrations, hosting renewals, domain name renewals, and many more amazing offers.
                        </Text>

                        <VipRewardsSignupButton onSuccess={openSuccessModal} />
                    </div>
                </div>

                <div ref={vipRewardsInfoNewRef} className="vipRewardsInfoNew">
                    <Text
                        size--xxxl={!['sm', 'xs'].includes(app_viewport)}
                        size--xl={['sm', 'xs'].includes(app_viewport)}
                        primary
                        semiBold
                        align--center
                        lead--s
                    >
                        <h2>Join our reward program today and enjoy these exclusive benefits</h2>
                    </Text>
                    {renderRewardCircles()}
                </div>

                <div className="vipRewardsInfoUnlock">
                    <Text
                        size--xxxl={!['sm', 'xs'].includes(app_viewport)}
                        size--xl={['sm', 'xs'].includes(app_viewport)}
                        primary
                        semiBold
                        align--center
                        lead--s
                    >
                        <h2>Unlock increased rewards with time!</h2>
                    </Text>
                    {renderTiersTable()}
                </div>

                <VipRewardsFaqs />
            </div>
            <div className="vipRewardsInfo__tandcContainer">
                <Text secondary italic size--xs align--right lead--s>
                    *
                    <a href="https://ventraip.com.au/terms-policies-agreements/ventraip-reward-program/" target="_blank" rel="noreferrer">
                        Terms and Conditions
                    </a>{' '}
                    apply
                </Text>
            </div>
        </div>
    );
}

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
VIPRewardsMoreInfoRoute.update({
    component: React.memo(VipRewardsMoreInfo)
});


export default VipRewardsMoreInfo;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { LOGO } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;
const { STYLE } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleBasicDetailsSubmit(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId, page } = useKatanaParams();
    const { submitSiteData } = useSiteDataPreviewValues();
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { mutateAsync: mutateUpdateKatanaSiteBusinessInfo } = useUpdateKatanaSiteBusinessInfoMutation(katanaServiceId, { onSuccess: _.noop });
    const { mutateAsync: mutateUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(katanaServiceId, { onSuccess: _.noop });

    /***** HOOK RESULTS *****/
    return async function handleSubmit(values: { [x: string]: any }) {
        const clonedValues = _.cloneDeep(values);
        if (clonedValues?.logo_choice === LOGO) {
            _.set(clonedValues[STYLE], 'fonts.brand', null);
        }
        if (clonedValues?.logo_choice === STYLE) {
            clonedValues[LOGO] = null;
        }

        const promises = [mutateUpdateKatanaSiteBusinessInfo(clonedValues), mutateUpdateKatanaSiteInfo(clonedValues)];

        submitSiteData(promises);

        return Promise.all(promises).then((e) => {
            katanaQuery.serviceID.getService.optimisticallyAddLaunchChecklistUpdate(katanaServiceId, 'webpage-info-completed');
            if (page !== 'setup') {
                pushNotification({ status: 200, message: 'Successfully updated site details.' });
            }
            performRouting();
            return e;
        });
    };
}

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteId, Params } from 'router/types';
import { type MultiHashPathObject } from 'router/utils';
import { getFullPathname } from 'router/utils/getFullPathname';
import { getRouteConfig } from 'router/utils/getRouteConfig';
import { resolveRouteConfig } from 'router/utils/resolveRouteConfig';
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
/**
 * Retrieves whether or not every segment of the route is active, and if at least one hash is active.
 */
export function getModuleConfigStatus<TPathObject extends MultiHashPathObject>(options: TPathObject, params?: Params<TPathObject>) {
    // Get the full Path
    const fullPath = getFullPathname(options);

    // Get an array of hash configs. This uses the full path as the hash only exists on the full provided path
    const hashConfigs = Array.isArray(options.hash)
        ? options.hash.map((hash) => getRouteConfig({ path: fullPath, hash }))
        : [getRouteConfig({ path: fullPath, hash: options.hash })];

    // Convert the fullPath to segments and then build up an array of increasing length segments
    const builtSegments = fullPath.split('/').reduce<NXUtils.AllowAnyString<AnyRouteId>[]>((acc, _, index, arr) => {
        // Do not push if it's the first element and the path starts with '/' as this will be an empty string
        if (!index && fullPath.startsWith('/')) {
            return acc;
        }

        // Take the path segments, and join them based on the index
        const path = arr.slice(0, index + 1).join('/') as AnyRouteId;

        // Append the built segment to the accumulator
        acc.push(path);

        return acc;
    }, []);

    const removeUndefined = (val: any) => val !== undefined;

    const isRouteActive = (path: string) => {
        const retrievedConfig = getRouteConfig({ path });
        const resolvedConfig = resolveRouteConfig(retrievedConfig, params);

        return resolvedConfig.active;
    };

    // check the active state of each route segment
    const isEveryRouteSegmentActive = builtSegments
        .map(isRouteActive)
        .filter(removeUndefined) // do not check route segments that don't exist in config
        .every(Boolean);

    // given the final route, check the active state of each hash
    const isAtleastOneHashActive = hashConfigs.map((hashConfig) => resolveRouteConfig(hashConfig, params).active).some(Boolean);

    return {
        isEveryRouteSegmentActive,
        isAtleastOneHashActive
    };
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { IconifyIcon } from 'components/Icons/Iconify';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSvelteStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { developmentModuleFilterButtonState } from 'components/DevelopmentModuleFilter/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function DevelopmentModuleFilterButton() {
    /***** HOOKS *****/
    const [moduleState, setModuleState] = useSvelteStore(developmentModuleFilterButtonState);

    if (import.meta.env.MODE !== 'development' || moduleState === 'hidden') {
        return null;
    }

    /***** RENDER *****/
    return (
        <button
            type="button"
            onClick={() => setModuleState(moduleState === 'closed' ? 'open' : 'closed')}
            className="developmentModuleFilter__button"
        >
            <IconifyIcon icon="ph:package-duotone" size={28} />
        </button>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { DevelopmentModuleFilterButton };

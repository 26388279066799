/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { RouteComponent } from '@tanstack/react-router';
import { Link, createRoute } from '@tanstack/react-router';
import classNames from 'classnames';
import { abn, application, company, terms_url, url } from 'config/config';
import { DateTime } from 'luxon';
import qs from 'qs';
import { Component } from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DEV_LOGIN_VIEW_MANIPULATOR } from 'containers/login/components/DEV_LOGIN_VIEW_MANIPULATOR';
import ScamWarning from 'containers/login/modules/scamWarning';
import ScamWarningBackground from 'containers/login/modules/scamWarning/background';
import ScamWarningMessage from 'containers/login/modules/scamWarning/message';
import LoginAccountSelect from './forms/LoginAccountSelect';
import LoginAuth from './forms/LoginAuth';
import LoginBackup from './forms/LoginBackup';
import LoginForgot from './forms/LoginForgot';
import LoginRecovery from './forms/LoginRecovery';
import LoginReset from './forms/LoginReset';
import LoginTwoFactor from './forms/LoginTwoFactor';
import { RecoverySuccess } from './forms/RecoverySuccess';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Loader from 'components/Loaders/App';
import Transition from 'components/Transition';
import { Flex } from 'components/Utils/Flex';

/*   ACTIONS
 *****************************************************/
import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';
import {
    authenticatingTwoFactorCode,
    authenticatingUserDetails,
    forgotEmailMethod,
    forgotPasswordMethod,
    logout,
    resetPassword,
    submitRecoveryForm,
    twoFactorBackupMethodView,
    twoFactorRecoveryMethod
} from './action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import AppLogo from 'config/images/app.svg';
import CompanyLogo from 'config/images/company.svg';
import { routerMiddleware } from 'router/utils/middleware';
import { z } from 'zod';
import './_login.scss';

const searchSchema = z.object({
    forgot: z.boolean().optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const LoginRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/login',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('guest', opts);
    },
    validateSearch: searchSchema
});

export const ResetPasswordRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/reset-password/$token',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('guest', opts);
    },
    validateSearch: searchSchema
});

/**********************************************************************************************************
 *   FOOTER
 **********************************************************************************************************/
const Footer = () => {
    const getYear = () => {
        const date = new Date();
        return date.getFullYear();
    };
    const year = getYear();
    const navitems = [
        {
            label: 'Website',
            link: `${url}`,
            type: 'external'
        },
        {
            label: 'Contact',
            link: 'https://ventraip.com.au/contact-us/',
            type: 'external'
        },
        {
            label: 'Terms & Conditions',
            link: `${terms_url}`,
            type: 'external'
        }
    ];

    const generateBottomNav = (navitems) => {
        return navitems.map(function (item, index) {
            if (item.type === 'internal') {
                return (
                    <li key={index}>
                        <Link to={item.link}>{item.label}</Link>
                    </li>
                );
            } else {
                return (
                    <li key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={item.link}>
                            {item.label}
                        </a>
                    </li>
                );
            }
        });
    };

    return (
        <div className="login__footer">
            <div className="title">
                &copy; 2009 - {year} {company} Pty Ltd ABN {abn}
            </div>
            <nav className="links">
                <ul>{generateBottomNav(navitems)}</ul>
            </nav>
        </div>
    );
};

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** @type {import('containers/login/consts').PossibleLoginViews} */
            currentView: 'loading',
            recovered: false,
            method: false,
            email: false,
            success: {
                details: false,
                /** @type {import('containers/login/consts').PossibleLoginSuccessViews} */
                view: undefined,
                resubmit: false
            }
        };

        this.changeView = this.changeView.bind(this);
        this.handleBackupSubmit = this.handleBackupSubmit.bind(this);
        this.handleRecoverySubmit = this.handleRecoverySubmit.bind(this);
        this.handleTwoFactorSubmit = this.handleTwoFactorSubmit.bind(this);
        this.handleForgotSubmit = this.handleForgotSubmit.bind(this);
        this.handleResetSubmit = this.handleResetSubmit.bind(this);
        this.handleLoginForm = this.handleLoginForm.bind(this);
        this.getURLParameters = this.getURLParameters.bind(this);
    }

    changeView(view) {
        this.setState({
            currentView: view
        });

        const loginContainer = document.querySelector('.login__container');
        if (loginContainer) loginContainer.scrollTop = 0;
    }

    getURLParameters() {
        const { location } = this.props;
        let { search } = location;

        if (search) {
            search = search.replace('?', '');
        }
        return qs.parse(search);
    }

    handleBackupSubmit(values) {
        const { twoFactorRecoveryMethod } = this.props;
        const { changeView } = this;

        if (values && values.method === 'Manual') {
            changeView('recovery');
        } else {
            this.setState(
                {
                    method: values.method
                },
                () => {
                    twoFactorRecoveryMethod(values);
                }
            );
        }
    }

    handleResetSubmit(values) {
        const { match, resetPassword } = this.props;
        const { params } = match;
        const { token } = params;
        this.setState(
            {
                success: {
                    details: false,
                    view: 'reset'
                }
            },
            () => {
                resetPassword(token, values);
            }
        );
    }

    handleRecoverySubmit(values) {
        const modifiedValues = { ...values, dob: DateTime.fromJSDate(values.dob).toFormat('dd/MM/yyyy') };
        this.setState(
            {
                success: {
                    details: modifiedValues,
                    view: 'manual'
                }
            },
            () => {
                submitRecoveryForm(modifiedValues);
            }
        );
    }

    async handleTwoFactorSubmit(values) {
        const { changeView } = this;
        const { history, search } = this.props;

        changeView('loading');
        await authenticatingTwoFactorCode(values);

        console.log('handleTwoFactorSubmit', search.ref);
        history.push(search.ref ?? '/dashboard');
    }

    handleForgotSubmit(values) {
        const { forgotEmailMethod, forgotPasswordMethod } = this.props;
        const { method } = values;
        const modifiedValues = { ...values, dob: DateTime.fromJSDate(values.dob).toFormat('dd/MM/yyyy') };

        if (method === 'email') {
            this.setState(
                {
                    success: {
                        details: modifiedValues,
                        view: 'manual'
                    }
                },
                () => {
                    forgotEmailMethod(modifiedValues);
                }
            );
        } else {
            this.setState(
                {
                    success: {
                        details: modifiedValues,
                        view: 'password',
                        resubmit: forgotPasswordMethod
                    }
                },
                () => {
                    forgotPasswordMethod(modifiedValues);
                }
            );
        }
    }

    handleLoginForm(values) {
        const { authenticatingUserDetails, googleReCaptchaProps, history, search } = this.props;
        const { changeView } = this;
        const params = this.getURLParameters();

        // Don't do recaptcha in cypress tests as it is causing them to intermittently fail
        if (import.meta.env.VITE_CCP_ENVIRONMENT === 'development' && window.Cypress) {
            return this.setState(
                {
                    email: values.email
                },
                () => {
                    changeView('loading');
                    authenticatingUserDetails({ ...values }, params, () => {
                        console.log('handleLoginForm', search.ref);
                        history.push(search.ref ?? '/dashboard');
                    });
                }
            );
        }

        if (!googleReCaptchaProps?.executeRecaptcha) return;

        googleReCaptchaProps
            .executeRecaptcha('login')
            .then((token) => {
                this.setState(
                    {
                        email: values.email
                    },
                    () => {
                        changeView('loading');
                        authenticatingUserDetails({ ...values, g_recaptcha_response: token }, params, () => {
                            console.log('handleLoginForm (not testing)', search.ref);
                            history.push(search.ref ?? '/dashboard');
                        });
                            
                    }
                );
            })
            .catch((_) => {
                // google recaptcha request failed
                pushNotification({ status: 500, details: 'ReCaptcha failed, please try again later.' }, null, notificationScopes.GUEST);
            });
    }

    componentDidUpdate(prevProps) {
        const {
            history,
            page,
            login_2fa_status,
            login_2fa_authentication_status,
            login_backup_status,
            login_authentication_status,
            login_2fa_recovery_status,
            login_recovery_manual_status,
            login_forgot_email_status,
            login_forgot_password_status,
            login_reset_password_status,
            login_account_list_status,
            login_account_list_data,
            app_check_token_data,
            app_user_data,
            twoFactorBackupMethodView,
            app_check_token_status,
            app_user_status
        } = this.props;
        const { changeView } = this;

        if (page && page !== '/login') {
            history.push(page);
        }

        if (login_2fa_status === 'error' && prevProps.login_2fa_status === 'loading') {
            twoFactorBackupMethodView();
        }

        if (login_backup_status === 'success' && prevProps.login_backup_status === 'loading') {
            if (login_2fa_status === 'success') {
                changeView('2fa');
            } else {
                changeView('backup');
            }
        }

        if (login_2fa_recovery_status === 'success' && prevProps.login_2fa_recovery_status === 'loading') {
            changeView('2fa');
        }

        if (login_2fa_authentication_status === 'error' && prevProps.login_2fa_authentication_status === 'loading') {
            changeView('2fa');
        }

        if (login_authentication_status === 'error' && prevProps.login_authentication_status === 'loading') {
            changeView('login');
        }

        if (login_account_list_status === 'success' && prevProps.login_account_list_status === 'loading') {
            if (login_account_list_data?.length) {
                changeView('accountlist');
            }
        }

        if (
            (login_forgot_password_status === 'success' && prevProps.login_forgot_password_status === 'loading') ||
            (login_forgot_email_status === 'success' && prevProps.login_forgot_email_status === 'loading') ||
            (login_recovery_manual_status === 'success' && prevProps.login_recovery_manual_status === 'loading') ||
            (login_reset_password_status === 'success' && prevProps.login_reset_password_status === 'loading')
        ) {
            changeView('success');
        }
    }

    componentDidMount() {
        const { login_2fa_status, login_account_list_status, login_account_list_data, match, location, search, twoFactorBackupMethodView } =
            this.props;
        const { changeView } = this;
        const { params } = match;
        const { token } = params;

        if (!login_2fa_status) {
            changeView('login');
        }

        if (login_2fa_status === 'error') {
            twoFactorBackupMethodView();
        }

        if (login_2fa_status === 'success') {
            changeView('2fa');
        }

        if (login_account_list_status === 'success' && login_account_list_data) {
            if (login_account_list_data?.length > 1) {
                changeView('accountlist');
            }
        }

        if (token) {
            changeView('reset');
        }

        if (search) {
            const { forgot } = search;

            if (forgot) {
                changeView('forgot');
            }
        }

        if (import.meta.env.DEV) {
            const LOGIN_VIEW_MANIPULATION_SUBSCRIBER = superUserSuperActionsState.subscribe((state) => {
                this.setState({
                    loginViewManipulation: state.LOGIN_VIEW_MANIPULATION
                });
            });

            this.setState({
                LOGIN_VIEW_MANIPULATION_SUBSCRIBER
            });
        }
    }

    componentWillUnmount() {
        if (import.meta.env.DEV) {
            this.state.LOGIN_VIEW_MANIPULATION_SUBSCRIBER?.();
        }
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/

    render() {
        const { login_loading_state, login_account_list_data, logout, app_viewport } = this.props;
        const { currentView, success, method, email } = this.state;
        const {
            changeView,
            handleLoginForm,
            handleForgotSubmit,
            handleTwoFactorSubmit,
            handleRecoverySubmit,
            handleBackupSubmit,
            handleResetSubmit
        } = this;

        const handleFormRender = () => {
            switch (currentView) {
                case '2fa':
                    return <LoginTwoFactor navigation={changeView} onSubmit={handleTwoFactorSubmit} backupMethod={method} />;

                case 'recovery':
                    return <LoginRecovery navigation={changeView} onSubmit={handleRecoverySubmit} />;

                case 'forgot':
                    return <LoginForgot navigation={changeView} onSubmit={handleForgotSubmit} />;

                case 'backup':
                    return <LoginBackup navigation={changeView} onSubmit={handleBackupSubmit} />;

                case 'success':
                    return <RecoverySuccess changeView={changeView} view={success.view} details={success.details} resubmit={success.resubmit} />;

                case 'reset':
                    return <LoginReset navigation={changeView} onSubmit={handleResetSubmit} />;

                case 'login':
                    return (
                        <Flex direction="column" justify="center" items="center" className="login__handler">
                            <div className="login__header">
                                <img src={AppLogo} alt="CCP" />
                            </div>
                            <LoginAuth onSubmit={handleLoginForm} email={email} />
                            <div className="login__link-container">
                                <Anchor
                                    className="login__link login__forgotDetailsButton"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        changeView('forgot');
                                    }}
                                >
                                    Forgot your details?
                                </Anchor>
                            </div>
                        </Flex>
                    );

                case 'accountlist':
                    return (
                        <Flex direction="column" justify="center" items="center" className="login__handler">
                            <div className="login__header">
                                <img src={AppLogo} alt="Customer Control Panel" />
                            </div>
                            <div className="accountSelect__title">Choose an account to log into below</div>
                            <LoginAccountSelect accountList={login_account_list_data} />
                            <Anchor
                                className="accountSelect__logout"
                                onClick={() => {
                                    logout();
                                    this.changeView('login');
                                }}
                            >
                                Logout
                            </Anchor>
                        </Flex>
                    );
                case 'loading':
                default:
                    return <Loader />;
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className={`login${currentView === 'login' ? ' login--scamWarning' : ''}`}>
                <div className="login__side--left">
                    {!['xs', 'sm'].includes(app_viewport) && currentView === 'login' ? <ScamWarning /> : ''}
                    {currentView === 'login' ? (
                        ''
                    ) : (
                        <>
                            <div className="login__side-header">
                                <img src={CompanyLogo} alt={company} />
                            </div>
                            <div className="login__side-content">
                                <Transition>
                                    <div className="title animated">Welcome to {application}</div>
                                    <div className="desc animated">We&apos;ve made a few changes!</div>
                                </Transition>
                            </div>
                        </>
                    )}
                </div>
                <Transition when={currentView} loading={login_loading_state} className="login__side--right">
                    {['xs', 'sm'].includes(app_viewport) && currentView === 'login' ? (
                        <>
                            <ScamWarningBackground />
                            <ScamWarningMessage />
                        </>
                    ) : (
                        ''
                    )}
                    <div
                        className={classNames('login__container', `login__container--${currentView}`, {
                            maxHeight: ['recovery'].includes(currentView)
                        })}
                    >
                        <div className="login__containerContent">{handleFormRender()}</div>
                    </div>
                    {Footer()}
                </Transition>
                {import.meta.env.VITE_CCP_ENVIRONMENT === 'development' && this.state.loginViewManipulation && (
                    <DEV_LOGIN_VIEW_MANIPULATOR
                        currentView={currentView}
                        setView={changeView}
                        currentSuccessView={success.view}
                        setCurrentSuccessView={(e) => {
                            this.setState({
                                success: {
                                    view: e
                                }
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const LoginRouteComponent = withRouter(
    withGoogleReCaptcha(
        connect(
            (state) => ({
                app_viewport: state.app.app_viewport,
                login_authentication_status: state.login.login_authentication_status,
                login_2fa_authentication_status: state.login.login_2fa_authentication_status,
                login_2fa_status: state.login.login_2fa_status,
                login_backup_status: state.login.login_backup_status,
                login_reset_password_status: state.login.login_reset_password_status,
                login_forgot_password_status: state.login.login_forgot_password_status,
                login_forgot_email_status: state.login.login_forgot_email_status,
                login_recovery_manual_status: state.login.login_recovery_manual_status,
                login_account_list_data: state.login.login_account_list_data,
                login_account_list_status: state.login.login_account_list_status,
                app_user_data: state.app.app_user_data,
                app_user_status: state.app.app_user_status,
                app_check_token_status: state.app.app_check_token_status
            }),
            {
                authenticatingUserDetails,
                twoFactorRecoveryMethod,
                twoFactorBackupMethodView,
                forgotPasswordMethod,
                forgotEmailMethod,
                resetPassword,
                logout
            }
        )(Login)
    )
);

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
ResetPasswordRoute.update({
    component: LoginRouteComponent as RouteComponent<any>
});

LoginRoute.update({
    component: LoginRouteComponent as RouteComponent<any>
});

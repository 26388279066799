/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DialogNotification from 'components/Notifications/DialogNotification';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ContentDefinitionFormItem } from 'containers/katana/components/contentDefinitionForm/contentDefinitionFormItem';
import { getServiceInfoDataFromPath } from 'containers/katana/components/dynamicFormFieldRenderer/methods';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _PropertyRenderContentDefinitionFormItem = (props) => {
    const { property, FormComponent } = props;
    const { caveats } = property;

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_service_data } = katanaQuery.serviceID.getService.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const isContactUsRequestedDetails = property?.key.includes('properties.requested_details');
    const hasBusinessEmail = get_service_data.business.email ? true : false;

    const interactiveGoogleMaps = useGetCaveat(CaveatTypes.INTERACTIVE_GOOGLE_MAPS, caveats);
    const googleMapsAddressSource = getServiceInfoDataFromPath(katanaServiceId, interactiveGoogleMaps);
    const showGoogleMapsMessage = Boolean(googleMapsAddressSource !== undefined && !googleMapsAddressSource);

    /***** RENDER *****/
    if (showGoogleMapsMessage) {
        return (
            <Padding y={2}>
                <DialogNotification type="warning" noMargin>
                    <Text bold black size--xss lead--s>
                        This feature requires a business address to work. To show the map on your site, please add your business address on the basic
                        details page.
                    </Text>
                </DialogNotification>
            </Padding>
        );
    } else if (!hasBusinessEmail && isContactUsRequestedDetails) {
        return null;
    } else {
        return <ContentDefinitionFormItem property={property} FormComponent={FormComponent} />;
    }
};

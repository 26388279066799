/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import _, { cloneDeep, has } from 'lodash';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkDomain } from 'containers/katana/queries/checkDomain';
import { applyNewSectionSort } from 'containers/katana/queries/methods/applyNewSectionSort';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';
import { getSingleSection } from 'containers/katana/queries/serviceID/getSection';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import { createOptimisticUpdateMethod } from 'utilities/methods/tanstack/createOptimisticUpdateMethod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { SetupStages } from 'containers/katana/consts';
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.GET>>;

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(['katana', 'service', serviceID]);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: ({ signal }) =>
            KATANA_API.katana.service_id.GET(serviceID, signal).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(serviceID: number) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function cancelQueries(serviceID: number) {
    return queryClient.cancelQueries({ queryKey: createQueryKey(serviceID) });
}

function getQueryData(serviceID: number): undefined | TData {
    return queryClient.getQueryData(createQueryKey(serviceID));
}

function ensureQueryData(serviceId: number | string) {
    const _serviceId = typeof serviceId === 'string' ? Number(serviceId) : serviceId;
    return queryClient.ensureQueryData(createQueryOptions(_serviceId));
}

function invalidateQueries(serviceID: number) {
    const queryKey = createQueryKey(serviceID);
    // Whenever the service is invalidated, we also need to invalidate the preview check as it uses the service data
    queryClient.invalidateQueries({ queryKey: [...queryKey, 'preview'] });
    const queryData = getQueryData(serviceID);
    // Whenever the service is invalidated, we also need to invalidate the domain check as it uses the service data
    if (queryData?.status !== 200 && queryData?.data?.attributes?.name) {
        checkDomain.invalidateQueries(queryData.data.attributes.name);
    }
    return queryClient.invalidateQueries({
        queryKey
    });
}

function invalidateAllQueries(serviceID: number) {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey(serviceID),
        predicate: (query) => query.queryKey.includes(serviceID) && !query.queryKey.includes('meta')
    });
}

/**
 * As a potential concept to follow a more "parent invalidates all children" approach to query key invalidation we can do something along the lines of the following code
 *
 * Where `invalidateExact` invalidates just that query.
 *
 * Where `invalidateLoose` invalidates that query + all the child queries.
 *
 * i.e.:
 *
 * if our queryKey is `[userId, 'serviceId']`
 *
 * and our queries are:
 * ```
 * [userId]
 * [userId, 'serviceId']
 * [userId, 'serviceId', 123]
 * ```
 *
 * if we were to call `invalidateExact`. only `[userId, 'serviceId']` would get invalidated
 *
 * if we were to call `invalidateLoose`. the following queries would be invalidated:
 * ```
 * [userId, 'serviceId']
 * [userId, 'serviceId', 123]
 * ```
 */
// const createBaseQueryKeyWithInvalidation = (...args) => {
//     const queryKey = createBaseQueryKey(args);
//     const invalidateExact = queryClient.invalidateQueries(queryKey)
//     const invalidateLoose = queryClient.invalidateQueries({
//       predicate: (key: Array<string | number>) =>
//         queryKey.every((_key, i) => key[i] === _key)
//   })
// }

//   const createQueryKey = (serviceId) => {
//     const { queryKey, invalidateExact, invalidateLoose } = createBaseQueryKeyWithInvalidation("example", serviceId)
//   }

const setQueryData = createSetQueryDataMethod<number, TData>(createQueryKey);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery<TQueryOptions extends NXQueryUtils.TUseQueryOptions<typeof createQueryOptions>>(serviceID?: number, options?: TQueryOptions) {
    /***** HOOKS *****/
    const { katanaServiceId } = useParams({ strict: false });

    /***** QUERIES *****/
    return useQuery({
        ...createQueryOptions(serviceID ?? Number(katanaServiceId)),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
function optimisticSectionSort(serviceID: number, sectionIDs: number[]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        clonedData.data.sections = applyNewSectionSort(sectionIDs, clonedData.data.sections);

        return clonedData;
    });
}

function optimisticallyAddLaunchChecklistUpdate(serviceID: number, launchChecklistKey: SetupStages) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);
        if (!has(clonedData, 'data.attributes.launch_checklist')) {
            return;
        }

        clonedData.data.attributes.launch_checklist = _.uniq([...clonedData.data.attributes.launch_checklist, launchChecklistKey]);

        return clonedData;
    });
}

function optimisticNameUpdate(serviceID: number, sectionID: number, name: string) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        const section = clonedData.data.sections.find((section) => section.id === sectionID);

        if (!section) {
            return;
        }

        getSingleSection.optimisticUpdate({ sectionID, serviceID }, 'data.name', name);

        section.name = name;
        return clonedData;
    });
}

function optimisticSectionAddition(serviceID: number, section: Artah.Katana.ID.GET._200['data']['sections'][number]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        clonedData.data.sections.push(section);

        sortSectionIntoCorrectOrder(clonedData.data.sections);

        return clonedData;
    });
}

function optimisticSectionPropertiesUpdate(
    serviceID: number,
    sectionID: number,
    propertiesData: Artah.Katana.ID.GET._200['data']['sections'][number]['properties']
) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        const section = clonedData.data.sections.find((section) => section.id === sectionID);

        if (!section) {
            return;
        }

        getSingleSection.optimisticUpdate({ sectionID, serviceID }, 'data.properties', propertiesData);

        section.properties = propertiesData;

        return clonedData;
    });
}

export const getService = Object.freeze({
    useQuery: _useQuery,
    optimisticSectionSort,
    optimisticNameUpdate,
    optimisticSectionAddition,
    optimisticSectionPropertiesUpdate,
    optimisticallyAddLaunchChecklistUpdate,
    optimistic: createOptimisticUpdateMethod(setQueryData),
    cancelQueries,
    invalidateQueries,
    invalidateAllQueries,
    prefetchQuery,
    getQueryData,
    createQueryKey,
    createQueryOptions,
    ensureQueryData
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';
import { application } from 'config/config';
import React, { Fragment } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXBox from 'components/NXBox';
import SimpleTable from 'components/SimpleTable';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RecoverySuccess.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RecoverySuccessProps = {
    details: {
        name: string;
        security_question_id: string;
        security_answer: string;
        dob: string;
        phone: string;
        postcode: string;
        message: string;
        contact_email: string;
        email: string;
    };
    changeView: (view: string) => void;
    view: string;
    resubmit: (details: any) => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const RecoverySuccess: React.FC<RecoverySuccessProps> = ({ details, changeView, view, resubmit }) => {
    /***** HOOKS *****/
    const navigate = useNavigate();

    /***** RENDER HELPERS *****/
    const handleSuccessRender = () => {
        switch (view) {
            case 'manual': {
                const tableContents = [
                    ['Full Name', details?.name],
                    ['Security Question', details?.security_question_id],
                    ['Security Answer', details?.security_answer],
                    ['Date of Birth', details?.dob],
                    ['hone Number', details?.phone],
                    ['Postcode', details?.postcode],
                    ['Message', details?.message],
                    ['Contact Email', details?.contact_email]
                ];
                return (
                    <div className="RecoverySuccess__manualContainer">
                        <NXBox.DefaultPadding>
                            <Flex justify="center">
                                <PhosphorIcons.ShieldCheckered.Fill confirm size={48} />
                            </Flex>
                        </NXBox.DefaultPadding>
                        <NXBox.DefaultPadding>
                            <Text confirm size--h2 bold align--center lead--xs>
                                Your details have been submitted
                            </Text>
                        </NXBox.DefaultPadding>
                        <NXBox.DefaultPadding>
                            <Text size--xss secondary align--center>
                                Your details have been sent to our Recovery Team who will review them and get back to you ASAP.
                            </Text>
                        </NXBox.DefaultPadding>

                        <NXBox.DefaultPadding>
                            <Border all inject>
                                <SimpleTable
                                    rows={React.Children.toArray(
                                        tableContents.map(([key, value]) => (
                                            <Grid columns="1fr 2fr">
                                                <Text size--xss secondary>
                                                    {key}
                                                </Text>{' '}
                                                <Text size--xss secondary>
                                                    {value}
                                                </Text>
                                            </Grid>
                                        ))
                                    )}
                                />
                            </Border>
                        </NXBox.DefaultPadding>
                    </div>
                );
            }

            case 'password':
                return (
                    <Fragment>
                        <div className="login__title sent">
                            <div className="heading">A link to reset your password has been sent to {details?.email}</div>
                            <div className="description">
                                If there&apos;s a {application} account associated with the email provided, we&apos;ve sent a password reset link to
                                that email address. <br />
                                <br /> If you have an account but don&apos;t see the email soon, please check your spam and junk folders. To make sure
                                our emails land in your inbox, please add ventraip.com.au to your email whitelist.
                            </div>
                        </div>
                        <div className="login__link-container">
                            <Anchor
                                className="login__link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    resubmit(details);
                                }}
                            >
                                Resend password reset email
                            </Anchor>
                        </div>
                    </Fragment>
                );

            case 'reset':
                return (
                    <Fragment>
                        <div className="login__title sent">
                            <Flex justify="center">
                                <PhosphorIcons.ShieldCheckered.Fill confirm size={48} />
                            </Flex>

                            <Text confirm size--h2 bold align--center lead--xs>
                                Your password was successfully reset
                            </Text>
                            <Padding top={4} inject>
                                <Text size--xss secondary align--center>
                                    If you were logged in, your session was closed for security purposes. Please login with your new password to
                                    continue.
                                </Text>
                            </Padding>
                        </div>
                    </Fragment>
                );

            default:
                return '';
        }
    };

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <Flex direction="column" items="center" justify="center" className="RecoverySuccess">
            {handleSuccessRender()}

            <div className="login__link-container">
                <Anchor
                    className="login__link"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate({ to: '/login', replace: true });
                        changeView('login');
                    }}
                >
                    Back to Login
                </Anchor>
            </div>
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { RecoverySuccess };

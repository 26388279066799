import { checkAlertBanner, handleStaffLogin, interceptInvalidToken, loadAppConfig, updateViewport } from 'App/action';
import AppLoader from 'components/Loaders/App';
import { approveAdditionalUser } from 'containers/account/action';
import { approveMoveService } from 'containers/services/action';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AppMountingComponent = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const googleAnalyticsIds = {
    sandbox: 'G-XHEEHTRRTW',
    production: 'G-VTB9C86524'
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AppMounting: AppMountingComponent = ({ children }) => {
    /***** STATE *****/
    const [isStaffLogin, setIsStaffLogin] = useState(false);

    /***** HOOKS *****/
    const dispatch = useDispatch();

    /***** SELECTORS *****/
    const { app_user_data, app_mounting } = useSelector((state: any) => state.app);
    const { account_approve_additional_user_status } = useSelector((state: any) => state.account);
    const { service_move_approve_status } = useSelector((state: any) => state.services);
    const { login_url_params } = useSelector((state: any) => state.login);

    /***** EFFECTS *****/
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const invitation_token = params.get('token');
        const move_token = params.get('move_token');

        updateViewport();
        interceptInvalidToken();
        window.addEventListener('resize', updateViewport);
        ReactGA.initialize(import.meta.env.VITE_CCP_ENVIRONMENT === 'development' ? googleAnalyticsIds.sandbox : googleAnalyticsIds.production);

        /****************************************************************************
         * STAFF LOGIN
         ****************************************************************************/
        const staffCheckPath = document.location.pathname;
        if (staffCheckPath.startsWith('/staff-login')) {
            const splitParams = staffCheckPath.split('/');

            setIsStaffLogin(true);
            handleStaffLogin({
                token: splitParams && splitParams.length === 3 ? splitParams[2] : false
            });
        } else if (invitation_token) {
            approveAdditionalUser(invitation_token)(dispatch);
        } else if (move_token) {
            approveMoveService(move_token)(dispatch);
        } else {
            checkAlertBanner();
            loadAppConfig();
        }

        return () => {
            window.removeEventListener('resize', updateViewport);
        };
    }, []);

    useEffect(() => {
        if (!app_user_data) {
            return;
        }
        if (login_url_params?.token) {
            return approveAdditionalUser(login_url_params.token);
        }
        if (login_url_params?.move_token) {
            return approveMoveService(login_url_params.move_token, true);
        }
    }, [app_user_data]);

    /***** RENDER HELPERS *****/
    const isLoading = [account_approve_additional_user_status, service_move_approve_status].includes('loading') || app_mounting;

    /***** RENDER *****/
    if (isLoading) {
        return <AppLoader fullHeight message={isStaffLogin ? 'You are logging in as a staff member' : ''} />;
    }

    return children;
};

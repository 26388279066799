/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { has } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { newSectionOrganiserData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionOrganiserData';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import type { KatanaAPI, KatanaGetSectionAttributes } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type KatanaSiteSectionMutationParams = {
    serviceID: number;
    katanaSectionID?: KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionKeys;
};

type HandleMutationParams = {
    sectionDefinitionID: string;
    attributes: KatanaGetSectionAttributes;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Katana add section
 */
export function useAddKatanaSiteSectionMutation({ serviceID, katanaSectionID }: KatanaSiteSectionMutationParams, options = {}) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, katanaSectionID });

    async function handleMutation({ sectionDefinitionID, attributes }: HandleMutationParams) {
        const updatedAttributes = await handleModifyEntireAttributeObject(attributes);

        return KATANA_API.katana.site.service_id.sections.POST({ serviceID, sectionDefinitionID, attributes: updatedAttributes });
    }

    return useMutation({
        mutationFn: handleMutation,
        onError: (response) => {
            handleDefaultErrorNotification(response);
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSections.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSectionSitePreview.invalidateAllQueries(serviceID);
            katanaQuery.serviceID.getSitePreview.invalidateQueries(serviceID);
        },
        ...options,
        onSuccess: (response) => {
            if (response.status !== 200) {
                return;
            }
            if (page !== 'setup') {
                handleDefaultSuccessNotification(response);
            }
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSections.invalidateQueries(serviceID);
            if (has(response, 'data')) {
                newSectionOrganiserData.addNewID(response.data.id);
                katanaQuery.serviceID.getSingleSection.setQueryData({ serviceID, sectionID: response.data.id }, () =>
                    katanaQuery.serviceID.getSingleSection.createDataObject(response, {
                        meta: {
                            media_attachments: []
                        }
                    })
                );
                katanaQuery.serviceID.getSections.optimisticSectionAddition(serviceID, response.data);
                katanaQuery.serviceID.getService.optimisticSectionAddition(serviceID, response.data);
            }
            options?.onSuccess?.(response);
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

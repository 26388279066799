/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';
import { useKatanaPage } from 'containers/katana/hooks/useKatanaPage';
import { useKatanaSubpage } from 'containers/katana/hooks/useKatanaSubpage';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Cooerces routes into legacy katana route params and then returns them. As the new router is adopted, routes may not need
 * to be so dynamic (as they will render at relevant routes) and this hook may be deprecated in favour of a more direct approach.
 *
 * Note: Only childPage and katanaServiceId come from the router and use strict: false meaning that this function will not throw,
 */
function useKatanaParams() {
    /***** HOOKS *****/
    const { katanaServiceId, childPage } = useParams({ strict: false });

    /***** HOOK RESULTS *****/
    return {
        /**
         * dynamic path segment at the end of the route
         */
        childPage: isNaN(Number(childPage)) ? childPage : Number(childPage),

        /**
         *  The subpage of the editor (ie. /vip-sites/$katanaId/setup/**$subpage**\/$childPage)
         */
        subpage: useKatanaSubpage(),

        /**
         * The page of the editor (ie. /vip-sites/$katanaId/**$page**\/basic-details/$childPage)
         */
        page: useKatanaPage(),

        /**
         * The ID of the katana service in mercury
         */
        katanaServiceId: Number(katanaServiceId)
    };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export { useKatanaParams };

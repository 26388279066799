/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VipRewardsTierIcon from '../../moreInfo/tierIcon';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Icons from 'components/Icons';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';
import { capitalize } from 'utilities/methods/commonActions';
import { getTiersWithRequiredAccountAgeDays, isTierLocked } from '../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { orderedTiers } from 'containers/vipRewards/consts';
import { lightboxes } from '../index';

import Star from 'assets/images/vipRewards/star.svg';

import { application } from 'config/config';
import './_vipRewardsHomeBenefits.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function VipRewardsHomeBenefits({ setCurrentlyOpenLightbox }) {
    /***** HOOKS *****/
    const app_viewport = useSelector((state) => state.app.app_viewport);

    const { currentTier, rewardsTiers } = useVipRewards();

    /***** STATE *****/
    const [activeTierTab, setActiveTierTab] = useState(currentTier?.lowercase);

    /***** RENDER HELPERS *****/
    const timelineTiers = getTiersWithRequiredAccountAgeDays(rewardsTiers);

    const activeTierTabData = rewardsTiers?.[activeTierTab];

    const renderTabs = () => {
        // Dropdown
        if (['sm', 'xs'].includes(app_viewport)) {
            const renderOptions = (closeDropdown) =>
                orderedTiers.map((tierName) => ({
                    label: (
                        <div className="vipRewardsHomeBenefits__dropdownOption">
                            <div className="vipRewardsHomeBenefits__dropdownTitle">
                                <VipRewardsTierIcon tier={tierName} />
                                <Text black uppercase medium>
                                    <p>{tierName === 'vip' ? 'VIP' : capitalize(tierName)} Status</p>
                                </Text>
                                {isTierLocked(timelineTiers, currentTier?.lowercase, tierName) ? (
                                    <div className="vipRewardsHomeBenefits__headerTabsLocked">
                                        <Icons.Lock />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    ),
                    value: 'tierName',
                    onSelect: () => {
                        setActiveTierTab(tierName);
                        closeDropdown();
                    }
                }));

            return (
                <CustomDropdown
                    className="vipRewardsHomeBenefits__dropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button
                            className="vipRewardsHomeBenefits__dropdownButton"
                            ref={labelRef}
                            type="button"
                            onClick={() => dropdownTitleOnClick()}
                        >
                            <div className="vipRewardsHomeBenefits__dropdownTitle">
                                <VipRewardsTierIcon tier={activeTierTab} />
                                <Text black uppercase medium>
                                    <p>{activeTierTab === 'vip' ? 'VIP' : capitalize(activeTierTab)} Status</p>
                                </Text>
                                {isTierLocked(timelineTiers, currentTier?.lowercase, activeTierTab) ? (
                                    <div className="vipRewardsHomeBenefits__headerTabsLocked">
                                        <Icons.Lock />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <i className="item__icon icon icon-chevron-down" />
                        </button>
                    )}
                    key="dropdown"
                    render={(closeDropdown) => <SelectOptions options={renderOptions(closeDropdown)} />}
                />
            );
        }

        return (
            <div className="vipRewardsHomeBenefits__header">
                <div className="vipRewardsHomeBenefits__headerBorder" />
                <div className="vipRewardsHomeBenefits__headerTabsContainer">
                    {timelineTiers.map(({ name }) => {
                        return (
                            <button
                                key={name}
                                type="button"
                                onClick={() => setActiveTierTab(name.toLowerCase())}
                                className={classNames('vipRewardsHomeBenefits__headerTab', `vipRewardsHomeBenefits__headerTab--${name}`, {
                                    'vipRewardsHomeBenefits__headerTab--selected': name === activeTierTab
                                })}
                            >
                                <div className="vipRewardsHomeBenefits__headerTabContent">
                                    <VipRewardsTierIcon tier={name} />
                                    <Text size--s black semiBold uppercase lead--none>
                                        <p>{name} Status</p>
                                    </Text>
                                </div>
                                {isTierLocked(timelineTiers, currentTier?.lowercase, name) ? (
                                    <div className="vipRewardsHomeBenefits__headerTabsLocked">
                                        <Icons.Lock />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </button>
                        );
                    })}
                    <div className="vipRewardsHomeBenefits__headerMarker" />
                </div>
            </div>
        );
    };

    const renderCards = () => {
        if (!activeTierTabData) return '';

        const { new_hosting_benefit, new_domain_benefit, existing_hosting_benefit, existing_domain_benefit, name } = activeTierTabData;

        if (name.toLowerCase() === 'vip') {
            return (
                <div className="vipRewardsHomeBenefits__noBenefits">
                    <VipRewardsTierIcon tier="vip" />
                    <Text align--center lead--xs black medium size--xxl>
                        <h4>You have no rewards to redeem</h4>
                    </Text>
                    <Text align--center lead--none secondary size--s>
                        <small>Exclusive discounts and rewards will be unlocked once you reach Bronze status.</small>
                    </Text>
                </div>
            );
        }

        const cardData = [
            {
                title: 'New Web Hosting Plans',
                description: 'Order any new web hosting plan and enjoy this exclusive discount.',
                discount: `${new_hosting_benefit}% off`,
                buttonOrSmall: (
                    <SolidButton type="onClick" onClick={() => setCurrentlyOpenLightbox(lightboxes.PURCHASE_HOSTING)}>
                        Shop now
                    </SolidButton>
                )
            },
            {
                title: 'New Domain Names',
                description: 'Secure a fresh domain name with special savings.',
                discount: `${new_domain_benefit}% off`,
                buttonOrSmall: (
                    <SolidButton type="onClick" onClick={() => setCurrentlyOpenLightbox(lightboxes.REGISTER_DOMAIN)}>
                        Shop now
                    </SolidButton>
                )
            },
            {
                title: 'Renew Your Web Hosting',
                description: 'Extend your web hosting service for less.',
                discount: `${existing_hosting_benefit}% off`,
                buttonOrSmall: (
                    <Text size--s secondary lead--s align--center>
                        <small>Discount applied in {application}</small>
                    </Text>
                )
            },
            {
                title: 'Renew Your Domain Name',
                description: 'Renew your domain and save on the renewal cost.',
                discount: `${existing_domain_benefit}% off`,
                buttonOrSmall: (
                    <Text size--s secondary lead--s align--center>
                        <small>Discount applied in {application}</small>
                    </Text>
                )
            }
        ];

        return cardData.map(({ title, description, discount, buttonOrSmall }) => {
            return (
                <div key={title} className={`vipRewardsHomeBenefits__card vipRewardsHomeBenefits__card--${activeTierTab}`}>
                    <div className="vipRewardsHomeBenefits__cardLeft">
                        <div className="vipRewardsHomeBenefits__cardCorner">
                            <img src={Star} alt="Decorative star" />
                        </div>
                        <Text size--l black semiBold>
                            <h5>{title}</h5>
                        </Text>
                        <Text secondary size--s lead--s>
                            <p>{description}</p>
                        </Text>
                    </div>
                    <div className="vipRewardsHomeBenefits__cardRight">
                        <div className="vipRewardsHomeBenefits__cardRightCutoutContainer">
                            {Array(['xs'].includes(app_viewport) ? 12 : 6)
                                .fill()
                                .map((_, index) => (
                                    <div key={index} />
                                ))}
                        </div>
                        {discount ? (
                            <Flex direction="column" justify="center" items="center" gap={0}>
                                <Text size--xs black semiBold lead--none>
                                    <p>DISCOUNT</p>
                                </Text>
                                <Text primary size--xxxl lead--none bold>
                                    <p>{discount.toUpperCase()}</p>
                                </Text>
                            </Flex>
                        ) : (
                            ''
                        )}
                        {activeTierTab === currentTier?.lowercase ? buttonOrSmall : ''}
                    </div>
                </div>
            );
        });
    };

    /***** RENDER *****/
    return (
        <div className={['sm', 'xs'].includes(app_viewport) ? 'vipRewardsHomeBenefitsMobile' : 'vipRewardsHomeBenefits'}>
            {renderTabs()}

            <div className="vipRewardsHomeBenefits__main">
                <Text primary size--xl semiBold>
                    <h4>{activeTierTab === 'vip' ? activeTierTab.toUpperCase() : capitalize(activeTierTab)} Reward Benefits</h4>
                </Text>
                {isTierLocked(timelineTiers, currentTier?.lowercase, activeTierTab) ? (
                    <div className="vipRewardsHomeBenefits__lockedBar">
                        <Icons.Lock />
                        <Text white size--s align--center lead--none>
                            <p>You&apos;ll gain automatic access upon reaching {capitalize(activeTierTab)} Status.</p>
                        </Text>
                    </div>
                ) : (
                    ''
                )}
                {renderCards()}
                {activeTierTabData?.name.toLowerCase() !== 'vip' && (
                    <Text size--s align--center secondary className="vipRewardsHomeBenefits__disclaimer">
                        Discount benefits are automatically applied to shared web hosting and standard domain name invoices only, with a maximum
                        period of 1 year.
                    </Text>
                )}
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

VipRewardsHomeBenefits.propTypes = {
    /**
     * Function to open the purchase lightboxes for domains and hosting
     */
    setCurrentlyOpenLightbox: PropTypes.func
};

export default VipRewardsHomeBenefits;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { writable } from 'svelte/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { useSvelteStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const siteDataPreviewWritable = writable<KatanaAPI.Katana.ServiceID.Preview.Preset.POST.SiteAttributes>({});

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useSiteDataPreviewValues() {
    /***** STATE *****/
    const [siteData, setSiteData] = useSvelteStore(siteDataPreviewWritable);

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { mutateAsync: mutateAsyncUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(katanaServiceId);

    /***** FUNCTIONS *****/
    function submitSiteData<TPromise extends any>(promises: Promise<TPromise>[]) {
        if (_.values(siteData).length) {
            promises.push(
                mutateAsyncUpdateKatanaSiteInfo(siteData).then((e) => {
                    setSiteData({});
                    return e;
                })
            );
        }
    }

    /***** HOOK RESULTS *****/
    return {
        siteData,
        setSiteData,
        submitSiteData
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export { useSiteDataPreviewValues };


/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { has } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Katana update section
 * @param {{
 *      serviceID: number;
 *      katanaSectionID?: import('utilities/api/katana/types').KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionKeys;
 * }} params
 */
function useUpdateKatanaSiteSectionMutation({ serviceID, katanaSectionID }, options = {}) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, katanaSectionID });

    /**
     * @param {object} parameters
     * @param {number} parameters.sectionID
     * @param {import('utilities/api/katana/types').KatanaGetSectionAttributes} parameters.attributes
     */
    function handleMutation({ sectionID, attributes }) {
        const updatedAttributes = handleModifyEntireAttributeObject(attributes);

        /**
         * The optimistic update should be reversed if there's an error.
         */
        // Check if the name has been updated, if so, update the name in the service query
        if (has(updatedAttributes, 'name')) {
            katanaQuery.serviceID.getSections.optimisticNameUpdate(serviceID, sectionID, updatedAttributes.name);
            katanaQuery.serviceID.getService.optimisticNameUpdate(serviceID, sectionID, updatedAttributes.name);
        }
        /**
         * Looks like this one might not be working
         * // TODO: Fix this
         */
        if (has(updatedAttributes, 'properties')) {
            katanaQuery.serviceID.getSections.optimisticSectionPropertiesUpdate(serviceID, sectionID, updatedAttributes.properties);
            katanaQuery.serviceID.getService.optimisticSectionPropertiesUpdate(serviceID, sectionID, updatedAttributes.properties);
        }

        return KATANA_API.katana.site.service_id.section.section_id.PUT({ serviceID, sectionID, attributes: updatedAttributes });
    }

    return useMutation({
        mutationFn: handleMutation,
        onError: handleDefaultErrorNotification,
        onSettled: () => {
            katanaQuery.serviceID.getSections.invalidateQueries(serviceID);
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSectionSitePreview.invalidateAllQueries(serviceID);
            katanaQuery.serviceID.getSitePreview.invalidateQueries(serviceID);
        },
        ...options,
        onSuccess: (response) => {
            if (page !== 'setup') {
                handleDefaultSuccessNotification(response);
            }
            options?.onSuccess?.(response);
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useUpdateKatanaSiteSectionMutation };


/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { useHistory } from 'utilities/hooks/router/useHistory';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SideBarChildMenuItem } from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/sideBarChildMenuItem';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useBooleanStore } from 'utilities/hooks/useBooleanStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorLightboxHasChanges } from 'containers/katana/containers/ContentEditorLightbox/consts';
const { NAVIGATION, PRESET_CONTENT, BASIC_DETAILS, CONTACT_INFORMATION, COLOUR_FONTS } = katanaSubPageEnums;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<import('containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/types').NSideBarChildMenu.Option>}
 */
const SideBarChildMenuItemWrapper = ({ title, routeKey, identifier }) => {
    /***** STATE *****/
    const { value: isShowingUnsavedChangesLightbox, setTrue: showUnsavedChangesLightbox, setFalse: hideUnsavedChangesLightbox } = useBoolean(false);

    /***** HOOKS *****/
    const { getKatanaDestination } = useKatanaURL();
    const history = useHistory();
    const { value: hasChanges } = useBooleanStore(katanaContentEditorLightboxHasChanges);
    const { page, subpage, childPage } = useKatanaParams();

    /***** FUNCTIONS *****/
    const onChildMenuItemClick = () => {
        if (hasChanges) {
            showUnsavedChangesLightbox();
        } else {
            history.push(getNavigationDestiny());
        }
    };

    const isNavigationEntry = routeKey === NAVIGATION;
    function getNavigationDestiny() {
        switch (routeKey) {
            case NAVIGATION:
                return getKatanaDestination(page, NAVIGATION);
            case BASIC_DETAILS:
                return getKatanaDestination(page, BASIC_DETAILS);
            case CONTACT_INFORMATION:
                return getKatanaDestination(page, CONTACT_INFORMATION);
            default:
                return getKatanaDestination(page, PRESET_CONTENT, routeKey);
        }
    }

    /***** RENDER HELPERS *****/
    const isCurrentRoute = Boolean(identifier) ? routeKey === identifier : childPage === routeKey || (isNavigationEntry && subpage === NAVIGATION);

    /***** RENDER *****/
    return (
        <>
            <SideBarChildMenuItem title={title} onClick={onChildMenuItemClick} isCurrentRoute={isCurrentRoute} />
            <Modal.Compact isOpen={isShowingUnsavedChangesLightbox} onClose={hideUnsavedChangesLightbox} ariaLabel="Unsaved Changes Modal">
                <Text.Heading h2 align--center>
                    You have unsaved changes
                </Text.Heading>
                <Padding y={4}>
                    <Text size--m secondary align--center>
                        {`You haven't finished configuring this.`}
                        <br />
                        Do you want to continue your progress?
                    </Text>
                </Padding>
                <Flex justify="center">
                    <OutlineButton to={getNavigationDestiny()} onClick={hideUnsavedChangesLightbox}>
                        Leave
                    </OutlineButton>
                    <SolidButton type="onClick" onClick={hideUnsavedChangesLightbox}>
                        Resume Configuration
                    </SolidButton>
                </Flex>
            </Modal.Compact>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { SideBarChildMenuItemWrapper };


/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm, touch } from 'redux-form';
import store from 'store/store';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import AutocompleteAddress from 'components/AutocompleteAddress';
import OutlineButton from 'components/Buttons/OutlineButton';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import RenderPhoneField from 'components/Form/PhoneInput/RenderPhoneField';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainSelectionInfoList from './domainSelectionInfoList';

/*   ACTIONS
 *****************************************************/
import {
    emailFieldValidation,
    peopleNameFieldValidation,
    renderCheckboxField,
    RenderField,
    RenderSelectField,
    requiredFieldValidation,
    validateReactPhoneNumberInput
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { formatPhoneNumberSynergy } from 'components/Form/PhoneInput/methods';
import Text from 'components/Utils/Text';
import { DOMAIN_CONTACT_RESET, DOMAIN_CONTACT_THROUGH_SEARCH_RESET } from 'containers/domain/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { contactFormDetailsOptions } from 'containers/domain/const';
import './_domainContactsForm.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let ContactForm = (props) => {
    const {
        handleSubmit,
        contact,
        submitButton,
        resetContactDetailsOnLoad = false,
        /**
         * Redux Props
         */
        anyTouched,
        app_user_data,
        domain_contact_data,
        domain_contact_status,
        domain_contact_through_search_data,
        domain_contact_through_search_status,
        domain_contact_update_status,
        domain_information_data,
        form,
        pristine,
        selectedCountry,
        selectedState,
        submitting,
        useSameAsContact,
        valid,
        dispatch
    } = props;

    /***** STATE *****/
    const [details, setDetails] = useState({});
    const [detailsOptions, setDetailsOptions] = useState(contactFormDetailsOptions);

    /***** FUNCTIONS *****/
    function handleApply() {
        const appliedDetails = {
            address1: details.address1,
            address2: details.address2 || '',
            company: details.company || '',
            organisation: details.organisation || details.company || '',
            country: details.country,
            email: details.email,
            firstname: details.firstname,
            lastname: details.lastname,
            phone: formatPhoneNumberSynergy(details.phone),
            postcode: details.postcode,
            state: details.state,
            suburb: useSameAsContact === 'profile' ? details.city : details.suburb
        };

        Object.keys(appliedDetails).forEach((field) => {
            dispatch(change(form, field, appliedDetails[field]));
            dispatch(touch(form, field));
        });
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (!resetContactDetailsOnLoad) return;
        dispatch({
            type: DOMAIN_CONTACT_RESET
        });
        dispatch({
            type: DOMAIN_CONTACT_THROUGH_SEARCH_RESET
        });
    }, []);

    useEffect(() => {
        if (!domain_contact_data) return;

        const { attributes } = domain_contact_data;
        setDetailsOptions(contactFormDetailsOptions.filter(({ value }) => Object.keys(attributes).includes(value) || value === 'profile'));

        dispatch(change(form, 'contactupdate', contact));
    }, [domain_contact_data]);

    useEffect(() => {
        if (domain_contact_status === 'loading') {
            setDetails({});
            dispatch(change(form, 'contactType', ''));
        }
    }, [domain_contact_status]);

    useEffect(() => {
        dispatch(change(form, 'contactupdate', contact));
    }, [contact]);

    useEffect(() => {
        const state = store.getState();

        if (state.form?.[form]?.values && !anyTouched) {
            dispatch(touch(form, ...Object.keys(state.form[form].values)));
        }
    });

    useEffect(() => {
        if (domain_contact_through_search_status === 'success' && domain_contact_through_search_data) {
            setDetails(domain_contact_through_search_data.attributes[Object.keys(domain_contact_through_search_data.attributes)[0]]);
        }
    }, [domain_contact_through_search_status, domain_contact_through_search_data]);

    useEffect(() => {
        if (domain_contact_data && useSameAsContact) {
            const defaultOptions = detailsOptions.map(({ value }) => value);
            const {
                attributes: { admin, billing, registrant, tech }
            } = domain_contact_data;

            const getSameContactDetails = () => {
                switch (useSameAsContact) {
                    case 'profile':
                        return app_user_data;

                    case 'admin':
                        return admin;

                    case 'billing':
                        return billing;

                    case 'registrant':
                        return registrant;

                    case 'tech':
                        return tech;

                    default:
                        return app_user_data;
                }
            };

            setDetails(getSameContactDetails());

            if (!defaultOptions.includes(useSameAsContact)) {
                const options = [
                    ...detailsOptions,
                    {
                        label: useSameAsContact,
                        value: useSameAsContact
                    }
                ];

                setDetailsOptions(options);
            }
        }
    }, [domain_contact_data, useSameAsContact]);

    /***** RENDER HELPERS *****/
    const domain = domain_information_data?.attributes?.domain ?? '';
    const hasDomainContactData = Object.keys(domain_contact_data?.attributes ?? {}).length > 0;
    const hasDomainSearchContactData = Object.keys(details ?? {})?.length > 0;

    const renderEmptyDetails = () => {
        return (
            <div className="form__container">
                <div className="form__details empty">
                    <i className="icon icon-blog-search" />
                    <div className="form__details--message">No contact information found</div>
                </div>
            </div>
        );
    };

    const validDetailsData = [
        ['FIRST NAME', details.firstname],
        ['LAST NAME', details.lastname],
        ['ADDRESS 1', details.address1],
        ['ADDRESS 2', details.address2 ?? ''],
        ['ORGANISATION', (details.company || details.organisation) ?? ''],
        ['SUBURB', (useSameAsContact === 'profile' ? details.city : details.suburb) ?? ''],
        ['STATE', details.state],
        ['COUNTRY', details.country],
        ['POSTCODE', details.postcode],
        ['PHONE', details.phone],
        ['EMAIL', details.email]
    ];

    const renderValidDetails = () => {
        return (
            <div className="form__container">
                <div className="form__details">
                    <div className="form__grid">
                        {validDetailsData.map((detailsData, i) =>
                            detailsData.map((detailData, j) => (
                                <div key={`${i}${j}`} className="form__details--field">
                                    {detailData}
                                </div>
                            ))
                        )}
                    </div>
                </div>
                <OutlineButton
                    className="form__details--button"
                    type="onClick"
                    onClick={(e) => {
                        e.preventDefault();
                        handleApply();
                    }}
                >
                    Apply these details below
                </OutlineButton>
            </div>
        );
    };

    const renderDetails = () => {
        if (domain_contact_through_search_status === 'loading') {
            return <RequestLoader />;
        }

        return (
            <div className="form__item">
                <Text size--s secondary medium className="domainContactsForm__Title">
                    My Profile Details
                </Text>
                {hasDomainContactData || hasDomainSearchContactData ? renderValidDetails() : renderEmptyDetails()}
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <form className="service__contacts--form" onSubmit={handleSubmit}>
            <Field name="contactupdate" component={RenderField} type="hidden" />
            {domain.endsWith('.uk') ? (
                ''
            ) : (
                <div className="form__row same">
                    <div className="form__column half">
                        <DomainSelectionInfoList form={form} />

                        {domain_contact_status === 'loading' ? (
                            <RequestLoader message="Loading domain contact details" heightAuto />
                        ) : (
                            <Field
                                label="Use same details as"
                                name="contactType"
                                component={RenderSelectField}
                                type="select"
                                className="form__dropdown search"
                                disabled={!hasDomainContactData && !hasDomainSearchContactData}
                                options={detailsOptions}
                            />
                        )}
                    </div>
                    <div className="form__column half details">{useSameAsContact && renderDetails()}</div>
                </div>
            )}
            <div className="form__row rest">
                <div className="form__column half">
                    <Field
                        label="First Name"
                        name="firstname"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation, peopleNameFieldValidation]}
                        className="form__textfield"
                        readOnly={domain.endsWith('.uk')}
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="Last Name"
                        name="lastname"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation, peopleNameFieldValidation]}
                        className="form__textfield"
                        readOnly={domain.endsWith('.uk')}
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Organisation"
                        name="organisation"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        className="form__textfield"
                        readOnly={domain.endsWith('.uk')}
                    />
                </div>
            </div>

            <AutocompleteAddress
                form={form}
                selectedCountry={selectedCountry}
                selectedState={selectedState}
                onlyAUandNZ={false}
                halfWidthFields={true}
                fieldOrder={['address1', 'address2', 'suburb', 'state', 'country', 'postcode']}
                disabled={domain.endsWith('.uk')}
            />

            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="Phone"
                        name="phone"
                        component={RenderPhoneField}
                        required
                        type="tel"
                        placeholder="+61 455 555 555"
                        validate={[requiredFieldValidation, validateReactPhoneNumberInput]}
                        className="form__textfield"
                        disabled={domain.endsWith('.uk')}
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="Email Address"
                        name="email"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation, emailFieldValidation]}
                        className="form__textfield"
                        readOnly={domain.endsWith('.uk')}
                    />
                </div>
            </div>
            {domain.endsWith('.uk') ? (
                ''
            ) : (
                <>
                    {contact === 'registrant' && (
                        <div className="form__row">
                            <div className="form__column full">
                                <Field
                                    name="applyAll"
                                    label={`Apply these details to all contacts${domain ? ` for ${domain}` : ''}`}
                                    component={renderCheckboxField}
                                    type="checkbox"
                                    className="form__textfield"
                                />
                            </div>
                        </div>
                    )}

                    <div className="form__row">
                        <div className="form__column full">
                            {submitButton ? (
                                submitButton({ pristine, submitting, valid })
                            ) : (
                                <ReduxFormButton form={form} loading={domain_contact_update_status === 'loading'}>
                                    Save Changes
                                </ReduxFormButton>
                            )}
                        </div>
                    </div>
                </>
            )}
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ContactForm.propTypes = {
    /**
     * The form submit function passed as "onSubmit"
     */
    handleSubmit: PropTypes.func.isRequired,

    /**
     * The type of contact
     */
    contact: PropTypes.oneOf(['profile', 'registrant']),

    /**
     * Replacement Component for the submit button of the form in case you need to have custom conditions
     */
    submitButton: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),

    /**
     * Resets the contact data on load so that you start with a clean slate
     */
    resetContactDetailsOnLoad: PropTypes.bool
};

ContactForm = reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
    touchOnChange: true
})(ContactForm);

const mapStateToProps = (state, props) => {
    const formName = props.contact + 'serviceContactForm';
    const selector = formValueSelector(formName);
    const useSameAsContact = selector(state, 'contactType');
    const useSameAs = selector(state, 'useSameAs' + props.contact);
    const selectedState = selector(state, 'state');
    const selectedCountry = selector(state, 'country');

    return {
        app_user_data: state.app.app_user_data,
        domain_information_data: state.domain.domain_information_data,
        domain_contact_through_search_status: state.domain.domain_contact_through_search_status,
        domain_contact_through_search_data: state.domain.domain_contact_through_search_data,
        domain_contact_data: state.domain.domain_contact_data,
        domain_contact_status: state.domain.domain_contact_status,
        domain_contact_update_status: state.domain.domain_contact_update_status,
        form: formName,
        useSameAsContact,
        useSameAs,
        selectedState,
        selectedCountry
    };
};

ContactForm = connect(mapStateToProps)(ContactForm);

ContactForm = withRouter(ContactForm);

export default ContactForm;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { Component, type SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { bindActionCreators } from 'redux';
import { showNavigation } from 'utilities/consts';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { scrollOptions, scrollToComponent } from 'utilities/methods/commonActions';
import MobileSidebar from './MobileSidebar';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useLocation, useRouter } from '@tanstack/react-router';
import { focusableElementsSelector } from 'components/Lightboxes/Modal/consts';
import { useHistory } from 'utilities/hooks/router/useHistory';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_Sidebar.scss';

/**********************************************************************************************************
 *   DESKTOP COMPONENT START
 **********************************************************************************************************/
class DesktopSidebar extends Component {
    /************** LIFECYCLE METHODS **************/
    componentWillUnmount() {
        if (this.delay) {
            window.clearTimeout(this.delay);
        }
    }

    render() {
        const { links, title, icon, location, history, sidebarRefs } = this.props;
        const { pathname, hash } = location;
        const href = `${pathname}#${hash}`;

        /*   PROCESS LINKS FOR SECTION
         **********************************************************************************************************/
        const handleLinksRender = (listItems: Array<{ link: string; label: string }>) => {
            const handleClick = (link: string, index: number) => (e: SyntheticEvent) => {
                e.preventDefault();
                const isFirst = Number(index) === 0;
                const isChangingPage = link?.split('#')?.at(0) !== location.pathname;

                if (!sidebarRefs[link] && !isChangingPage && import.meta.env.DEV) {
                    const commonCause1 = 'ModuleRenderer does not include all paths that the module can render at';
                    const commonCause2 = 'ModuleRenderer is not used to render the module';
                    const commonCause3 =
                        'Some race condition with rendering which causes the component to unmount. Use the <ScrollableComponent /> component to handle these cases automatically';

                    console.warn(
                        `[Sidebar] No sidebarRef found for link: \n   - ${link} \n\n  Common Causes: \n   1. ${commonCause1}\n   2. ${commonCause2} \n   3. ${commonCause3}\n\n A list of registered keys is provided below for your convenience:\n   `,
                        Object.keys(sidebarRefs)
                    );
                }

                history.push(link);

                if (isChangingPage) {
                    return;
                }

                if (sidebarRefs[link]) {
                    scrollToComponent(sidebarRefs[link], link);

                    // Focus first focusable element in that module
                    const firstFocusableElementWithiModule = sidebarRefs[link].querySelector(focusableElementsSelector);
                    if (firstFocusableElementWithiModule?.focus) {
                        firstFocusableElementWithiModule.focus();
                    }
                    return;
                }

                scroll.scrollTo(0, scrollOptions);
            };

            const applySelectedClassName = (link: string) => {
                const linksForPathname = listItems.filter(({ link }) => link.split('#').at(0) === pathname) ?? [];
                const allHashesForPathname = linksForPathname.map(({ link }) => link.split('#').at(1));

                switch (true) {
                    case !hash && linksForPathname.at(0)?.link === link:
                    case !allHashesForPathname.includes(hash) && linksForPathname.at(0)?.link === link:
                    case link === href:
                        return 'selected';
                    default:
                        return '';
                }
            };

            if (!listItems?.length) {
                return null;
            }

            return listItems.map(({ link, label }, key) => (
                <li key={key}>
                    <a href={link} style={{ textDecoration: 'none' }}>
                        <Anchor onClick={handleClick(link, key)} className={applySelectedClassName(link)}>
                            <div>{label}</div>
                        </Anchor>
                    </a>
                </li>
            ));
        };

        /*   HANDLE SECTION RENDER
         **********************************************************************************************************/
        const handleSectionRender = () => {
            if (links && links.length > 0) {
                return links.map((item, index) => {
                    const { list_title, list_items } = item;

                    if (list_items.length <= 0) return '';

                    const firstItem = list_items[0];

                    return (
                        <div key={index} className="Sidebar__content">
                            {list_title ? (
                                <Anchor
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(firstItem.link);
                                        scroll.scrollTo(0);
                                    }}
                                    className={`Sidebar__heading${firstItem.link === href ? ' selected' : ''}`}
                                >
                                    {list_title}
                                </Anchor>
                            ) : (
                                ''
                            )}
                            <ul className="Sidebar__list">{handleLinksRender(list_items)}</ul>
                        </div>
                    );
                });
            }
            return '';
        };

        /*   HANDLE SIDEBAR HEADER RENDER
         **********************************************************************************************************/
        const handleHeaderRender = () => {
            if (title && icon) {
                return (
                    <div className="Sidebar__header">
                        <i className={`icon icon-${icon}`}></i>
                        <span className="text">{title}</span>
                    </div>
                );
            }

            return '';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <aside className={classNames('Sidebar', `Sidebar--sticky`, { noheader: !title })}>
                {handleHeaderRender()}
                {handleSectionRender()}
            </aside>
        );
    }
}

const mapStateToPropsSidebar = (state) => {
    return {
        sidebarRefs: state.sidebar.sidebarRefs
    };
};

const mapDispatchToPropsSidebar = (dispatch) =>
    bindActionCreators(
        {
            scrollToComponent
        },
        dispatch
    );

DesktopSidebar = connect(mapStateToPropsSidebar, mapDispatchToPropsSidebar)(DesktopSidebar);

/**********************************************************************************************************
 *   DESKTOP COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   EXPORT COMPONENT START
 **********************************************************************************************************/
const Sidebar = ({ links, title, icon }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const router = useRouter();
    const location = useLocation();
    const history = useHistory();

    if (!showNavigation) return null;

    if (isMobile) {
        return <MobileSidebar title={title} links={links} />;
    }

    return <DesktopSidebar title={title} icon={icon} links={links} location={location} history={history} router={router} />;
};

export default Sidebar;

/**********************************************************************************************************
 *   EXPORT COMPONENT END
 **********************************************************************************************************/

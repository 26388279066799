import type { NXUtils } from 'utilities/types';

export const INVOICE_ACTIONS = {
    PAY: 'pay',
    CANCEL: 'cancel'
} as const;

/**
 * Used to generate the url of the pay / cancel invoice lightbox.
 */
export function getInvoiceActionUrl(action: NXUtils.ValueOf<typeof INVOICE_ACTIONS>, invoiceId: number | string) {
    if (!Object.values(INVOICE_ACTIONS).includes(action)) throw new Error('Action must be one of the supported actions.');

    return `/billing/invoices/${invoiceId}/${action}`;
}

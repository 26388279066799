/**
 * @fileoverview
 * File contains middleware relating to the router. This includes
 *
 * - Sentry
 * - Document title updates
 */

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import 'core-js/features/promise/all-settled';
import 'core-js/features/string/replace-all';
import 'core-js/stable';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import * as Config from 'config/config';
import { router } from 'router/router';
import { getPageTitle } from 'utilities/methods/getPageTitle';

/**********************************************************************************************************
 * SENTRY START
 **********************************************************************************************************/
/**
 * Initializes Sentry with reference to the tanstack router for tracing.
 */
export const initializeSentry = () => {
    try {
        if (import.meta.env.MODE === 'production') {
            Sentry.init({
                dsn: import.meta.env.VITE_SENTRY_DSN,
                environment: import.meta.env.MODE,
                release: import.meta.env.VITE_GIT_SHA,
                integrations: [
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: true,
                        unmask: ['.sentry-un-mask', '[data-sentry-un-mask]']
                    }),
                    Sentry.browserTracingIntegration(),
                    Sentry.tanstackRouterBrowserTracingIntegration(router)
                ],
                tracePropagationTargets: [Config.api, /^\/api\//],
                ignoreErrors: ['ResizeObserver loop limit exceeded'],
                replaysSessionSampleRate: 0,
                replaysOnErrorSampleRate: 0,
                tracesSampleRate: Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE) ?? 0.05
            });
        }
    } catch (error) {
        console.error('Sentry initialisation failed.');
    }
};

/**********************************************************************************************************
 * DOCUMENT TITLE START
 **********************************************************************************************************/
/**
 * Runs whenever a route is loaded. 
 * 
 * Currently, This particular function handles updating the document title, but can be extended in the future.
 */
export const initializeRouteChangeHandler = () => {
    router.subscribe('onLoad', ({ pathChanged, toLocation }) => {
        if (pathChanged) {
            const { pathname } = toLocation;

            // update page title
            const newPageTitle = getPageTitle(pathname);
            if (newPageTitle) {
                document.title = newPageTitle;
            }
        }
    });
};

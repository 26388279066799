/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DeleteSectionConfirmationLightbox } from 'containers/katana/components/DeleteSectionConfirmationLightbox';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useDeleteKatanaSectionMutation } from 'containers/katana/queries/useDeleteKatanaSectionMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicChildPageData } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/useDynamicChildPageData';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
const { EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ValidFormSubmitButtonDeleteSectionButton = () => {
    /***** STATE *****/
    const [isShowingConfirmDeletion, setIsShowingConfirmDeletion] = useState(false);

    /***** HOOKS *****/
    const { page, katanaServiceId, childPage } = useKatanaParams();

    /***** QUERIES *****/
    const { mutate: mutateDeleteKatanaSection } = useDeleteKatanaSectionMutation(katanaServiceId);

    const { sectionID, isGetKatanaSiteSectionLoading } = useDynamicChildPageData();

    /***** RENDER HELPERS *****/
    const isEditor = page === EDITOR.to;

    /***** RENDER *****/
    if (!isEditor || !childPage) {
        return null;
    }
    if (isGetKatanaSiteSectionLoading) {
        return <RequestLoader message="Loading Section data..." />;
    }
    if (!sectionID) {
        return null;
    }
    return (
        <>
            <Anchor onClick={() => setIsShowingConfirmDeletion(true)} color="warn">
                DELETE SECTION
            </Anchor>
            <DeleteSectionConfirmationLightbox
                open={isShowingConfirmDeletion}
                setOpen={setIsShowingConfirmDeletion}
                onConfirm={() => {
                    mutateDeleteKatanaSection(sectionID);
                }}
            />
        </>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import RequestLoader from 'components/Loaders/Request';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private Component that renders a button with a variant
 * @type {React.FC<BaseButtonPropsWithVariant>}
 */
const BaseButton = ({
    variant = 'SolidButton',
    to,
    type,
    label,
    children,
    onClick,
    className,
    size,
    color,
    href,
    target,
    download,
    onMouseOver,
    isLoading = false,
    disabled = false,
    width = 'full',
    shape
}) => {
    /***** RENDER HELPERS *****/
    const isDisabled = isLoading ? isLoading : disabled;
    const buttonClassNames = classNames(variant, className, size, color, {
        [`${variant}--disabled`]: isDisabled,
        [`${variant}--isLoading`]: isLoading,
        [`${variant}--width-${width}`]: width,
        [`${variant}--shape-${shape}`]: shape
    });

    const content = isLoading ? <RequestLoader width={40} height={40} /> : label ?? children;

    const baseButtonProps = {
        className: buttonClassNames,
        onMouseOver,
        onFocus: onMouseOver,
        disabled: isDisabled
    };

    /***** RENDER *****/
    if (type) {
        switch (type) {
            case 'submit':
                return (
                    <button onClick={onClick} type={type} {...baseButtonProps}>
                        {content}
                    </button>
                );

            case 'onClick':
                return (
                    <button type="button" onClick={onClick} {...baseButtonProps}>
                        {content}
                    </button>
                );

            case 'anchor': {
                const conditionalProps = {};
                if (target) conditionalProps.target = target;
                if (download) conditionalProps.download = download;

                return (
                    <a href={href} {...conditionalProps} {...baseButtonProps}>
                        {content}
                    </a>
                );
            }
            default:
                return <button {...baseButtonProps}>{content}</button>;
        }
    }

    if (to) {
        return (
            <Link to={to} onClick={onClick} {...baseButtonProps}>
                {content}
            </Link>
        );
    }

    return (
        <button className={buttonClassNames} onClick={onClick} disabled={isDisabled}>
            {content}
        </button>
    );
};

/**********************************************************************************************************
 *   COMPONENT EXPORTS
 **********************************************************************************************************/

export const buttonVariants = /** @type {const} */ (['SolidButton', 'OutlineButton', 'RightBorderButton']);

export const BUTTON_TYPES = /** @type {const} */ ({
    SUBMIT: 'submit',
    ON_CLICK: 'onClick',
    ANCHOR: 'anchor'
});

export const BUTTON_COLORS = /** @type {const} */ ({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    CONFIRM: 'confirm',
    ERROR: 'error',
    WARN: 'warn',
    WARNING: 'warning',
    NOTICE: 'notice',
    INFO: 'info',
    WHITE: 'white'
});

export const BUTTON_SIZES = /** @type {const} */ ({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
});

/**********************************************************************************************************
 *   PROPTYPES
 **********************************************************************************************************/

export default BaseButton;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainRenewAutoDiscountDisplay from 'containers/domain/forms/renew/autoDiscountDisplay';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import PricingTable from 'components/PricingTable';
import PromoCode from 'components/PromoCode';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import RenewTable from './renewTable';

/*   ACTIONS
 *****************************************************/
import { bulkRenew } from '../../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { calculateBulkDomainPrice, getRenewData } from '../../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_renew.scss';
import { CLEAR_PROMO_CODE } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let BulkRenew = (props) => {
    const { dispatch } = store;
    const {
        selectedServices,
        preappliedPromoCode,
        /**
         * Redux Props
         */
        domain_bulk_info_data,
        domain_bulk_info_status,
        domain_bulk_action_status
    } = props;

    /***** STATE *****/
    const [appliedPromo, setAppliedPromo] = useState(preappliedPromoCode);
    const [servicesWithRenewDurationSelected, setServicesWithRenewDurationSelected] = useState([]);
    const [totals, setTotals] = useState({
        total: null,
        discount: null,
        subtotal: null
    });

    /***** FUNCTIONS *****/
    function calculatePrice() {
        setTotals(calculateBulkDomainPrice(servicesWithRenewDurationSelected));
    }

    function submitRenew() {
        if (servicesWithRenewDurationSelected && selectedServices?.length > 0) dispatch(bulkRenew(servicesWithRenewDurationSelected, appliedPromo));
    }

    function filterRenewDurationSelected() {
        const newServicesWithRenewDurationSelected = [...servicesWithRenewDurationSelected].filter(({ id }) =>
            selectedServices.find((service) => service.id === id)
        );
        setServicesWithRenewDurationSelected(newServicesWithRenewDurationSelected);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        calculatePrice();
    }, [servicesWithRenewDurationSelected, appliedPromo]);

    useEffect(() => {
        getRenewData({ selectedServices, appliedPromo, promoCode: preappliedPromoCode });
        filterRenewDurationSelected();
    }, [selectedServices]);

    useEffect(() => {
        if (!domain_bulk_info_data) return;
        const { promo_code, errors } = domain_bulk_info_data;
        const newAppliedPromoCode = promo_code && (!errors || errors.length <= 0) ? promo_code : null;
        setAppliedPromo(newAppliedPromoCode);
    }, [domain_bulk_info_data]);

    /***** RENDER HELPERS *****/
    function renderSubmitButton() {
        if (domain_bulk_action_status === 'loading') return <InactiveButton loading />;

        if (servicesWithRenewDurationSelected?.length > 0 && domain_bulk_info_status !== 'loading')
            return (
                <SolidButton type="onClick" onClick={submitRenew}>
                    Generate Renewal Invoice
                </SolidButton>
            );

        return <InactiveButton>Generate Renewal Invoice</InactiveButton>;
    }

    const pricingTableDiscounts = [
        {
            label: 'Subtotal',
            amount: `$${totals.subtotal} AUD`
        },
        {
            label: 'Discount',
            amount: `-$${totals.discount} AUD`
        }
    ];

    function isOneOrMorePricesWithAutoApplyDiscount() {
        const someHaveAutoDiscount = selectedServices.some(({ id: selectedServiceId }) => {
            return domain_bulk_info_data?.domains.some(({ attributes, id: bulkInfoId }) => {
                return bulkInfoId === selectedServiceId && attributes.prices.some(({ promo_code }) => promo_code && promo_code !== appliedPromo);
            });
        });

        return someHaveAutoDiscount;
    }

    /***** RENDER *****/
    return (
        <div className="bulkRenew">
            <RenewTable
                servicesWithRenewDurationSelected={servicesWithRenewDurationSelected}
                setServicesWithRenewDurationSelected={setServicesWithRenewDurationSelected}
            />

            <div className="bulkRenew__summary">
                <PromoCode
                    isDisabled={!(domain_bulk_info_data?.domains?.length > 0)}
                    customDisabledMessage={
                        domain_bulk_info_status === 'loading' ? 'Loading bulk info.' : 'Please select some domains in order to apply a coupon code.'
                    }
                    onApply={(_promoCode) => {
                        getRenewData({ selectedServices, promoCode: _promoCode, appliedPromo });
                    }}
                    removeAppliedCode={() => {
                        setAppliedPromo(null);
                        getRenewData({ selectedServices, promoCode: CLEAR_PROMO_CODE, appliedPromo });
                    }}
                    appliedPromoCode={domain_bulk_info_status === 'loading' ? undefined : appliedPromo}
                    isRemoveable
                />

                {isOneOrMorePricesWithAutoApplyDiscount() ? <DomainRenewAutoDiscountDisplay message="We found you the best available prices!" /> : ''}

                {domain_bulk_info_status !== 'loading' && (
                    <PricingTable
                        slim={true}
                        total={{
                            label: 'Total Amount Due',
                            amount: `$${totals.total || '0.00'} AUD`
                        }}
                        rows={totals.discount ? pricingTableDiscounts : null}
                    />
                )}

                {renderSubmitButton()}
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    domain_bulk_action_status: state.servicesBulk.domain_bulk_action_status,
    domain_bulk_info_status: state.servicesBulk.domain_bulk_info_status,
    domain_bulk_info_data: state.servicesBulk.domain_bulk_info_data
});

BulkRenew = connect(mapStateToProps)(BulkRenew);

BulkRenew = withRouter(BulkRenew);

export default BulkRenew;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PropInjector } from 'components/Utils/PropInjector';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { AllTokens, ColorTokens, ExcludePrimitiveToken } from 'config/tokens/base';
import { useStyle } from 'utilities/hooks/useStyle';
import { useTheme } from 'utilities/hooks/useTheme';
import './_Border.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RadiusTokens<T> = T extends `${string}border-radius${string}` ? T : never;
type BorderColors = 'notice' | 'warn';

type BorderComponent = React.FC<{
    children?: React.ReactNode;
    inject?: boolean;
    colour?: BorderColors | ExcludePrimitiveToken<ColorTokens>;
    radius?: RadiusTokens<AllTokens>;
    top?: boolean | number;
    right?: boolean | number;
    bottom?: boolean | number;
    left?: boolean | number;
    all?: boolean | number;
    dashed?: boolean;
    dotted?: boolean;
    className?: string;
}>;

const appliedStylingPropTypeKeys = ['top', 'right', 'bottom', 'left', 'all', 'dotted', 'dashed', 'inject', 'radius'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *  Adds a border to the child component or wraps in a div.
 */
const Border: BorderComponent = ({ children, className, ...props }) => {
    const { inject, colour, radius } = props;

    /***** HOOKS *****/
    const themeStyles = useTheme<BorderColors>({
        '--Border-color': colour,
        '--Border-radius': radius
    });

    const dynamicStyles = useStyle({
        '--Border-thickness-top': props.top,
        '--Border-thickness-right': props.right,
        '--Border-thickness-bottom': props.bottom,
        '--Border-thickness-left': props.left,
        '--Border-thickness-all': props.all
    });

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: appliedStylingPropTypeKeys,
        componentName: 'Border',
        delimiter: '--'
    });

    const styles = {
        ...themeStyles,
        ...dynamicStyles
    };

    const borderClasses = classNames('Border', className, appliedStylingClasses, {
        [`Border--colour-${colour}`]: colour
    });

    /***** RENDER *****/
    return (
        <PropInjector inject={inject} injectableProps={{ className: borderClasses }} injectable={children}>
            <div className={borderClasses} style={styles}>
                {children}
            </div>
        </PropInjector>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Border;

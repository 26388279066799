/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Suspense } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSuperUserActionKey } from 'components/StaffMenu/SuperUser/consts';

const DevTools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : React.lazy(() =>
              // Lazy load in development
              import('@tanstack/router-devtools').then((res) => ({
                  default: res.TanStackRouterDevtools
              }))
          );

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TanStackRouterDevtools = () => {
    /***** HOOKS *****/
    const { value } = useSuperUserActionKey('TOGGLE_ROUTER_DEVTOOLS', true);

    /***** RENDER *****/
    return <Suspense>{!!value && <DevTools />}</Suspense>;
};

// Mutations will go here
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   Mutations START
 **********************************************************************************************************/

/**********************************************************************************************************
 *   Create Katana Site
 **********************************************************************************************************/

export function useCreateKatanaSite(options = {}) {
    return useMutation({
        mutationKey: useCreateKatanaSite.mutationKey,
        mutationFn: KATANA_API.katana.POST,
        onSettled: (data) => {
            const serviceID = data?.data?.site?.id;
            if (serviceID) {
                katanaQuery.serviceID.getService.invalidateQueries(serviceID);
                katanaQuery.getSiteList.invalidateQueries();
                katanaQuery.getDomainsList.invalidateQueries();
            }
        },
        onSuccess: (data, domain) => {
            katanaQuery.checkDomain.invalidateQueries(domain);
        },
        ...options
    });
}
useCreateKatanaSite.mutationKey = ['useCreateKatanaSite'];

/**********************************************************************************************************
 *   Katana Enable Site
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 */
export function useEnableKatanaSiteMutation(serviceID, options = {}) {
    /**
     * @param {import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Enable.POST.Params['attributes']} attributes
     */
    function mutationFn(attributes) {
        return KATANA_API.katana.service_id.enable.POST({ serviceID, attributes });
    }
    return useMutation({
        mutationFn,
        mutationKey: ['useEnableKatanaSiteMutation'],
        onSettled: () => katanaQuery.serviceID.getService.invalidateQueries(serviceID),
        ...options
    });
}

/**********************************************************************************************************
 *   Katana Disable Site
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 */
export function useDisableKatanaSiteMutation(serviceID, options = {}) {
    return useMutation({
        mutationFn: () => KATANA_API.katana.service_id.disable.POST(serviceID),
        onSettled: () => katanaQuery.serviceID.getService.invalidateQueries(serviceID),
        mutationKey: ['useDisableKatanaSiteMutation'],
        ...options
    });
}

/**********************************************************************************************************
 *   Katana Reset Service
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 */
export function useResetKatanaService(serviceID) {
    return useMutation({
        mutationFn: () => KATANA_API.katana.service_id.reset.POST(serviceID),
        mutationKey: useResetKatanaService.mutationKey,
        onSuccess: () => {
            const queryKey = createBaseQueryKey(['katana', 'service', serviceID]);
            queryClient.cancelQueries({ queryKey });
            queryClient.invalidateQueries({ queryKey });
        }
    });
}
useResetKatanaService.mutationKey = ['useResetKatanaService'];

/**********************************************************************************************************
 *   Katana Update Section Order
 **********************************************************************************************************/

/**
 * @param {number} serviceID
 * @param {import('@tanstack/react-query').MutationOptions} options
 */
function useReorderSectionsMutation(serviceID, options = {}) {
    /**
     * @param {number[]} sectionIDs
     */
    async function mutationFn(sectionIDs) {
        const cancelQueries = [katanaQuery.serviceID.getSections.cancelQueries(serviceID), katanaQuery.serviceID.getService.cancelQueries(serviceID)];
        await Promise.allSettled(cancelQueries);

        katanaQuery.serviceID.getSections.optimisticSectionSort(serviceID, sectionIDs);
        katanaQuery.serviceID.getService.optimisticSectionSort(serviceID, sectionIDs);
        return KATANA_API.katana.site.service_id.sections.reorder.POST({ serviceID, sectionIDs });
    }

    return useMutation({
        mutationFn,
        mutationKey: useReorderSectionsMutation.mutationKey,
        onSuccess: () => {
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSections.invalidateQueries(serviceID);
        },
        ...options
    });
}
useReorderSectionsMutation.mutationKey = ['useReorderSectionsMutation'];

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useReorderSectionsMutation };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Navigate, Outlet, createRoute, useLocation } from '@tanstack/react-router';
import * as Config from 'config/config';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { z } from 'zod';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InvoiceUrlLightbox from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox';
import AppLoader from 'components/Loaders/App';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   ROUTER IMPORTS
 **********************************************************************************************************/
import { RootRoute } from 'router/__root';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { AppRouteTemplate } from 'App/components/AppTemplate';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleActivation, handleRootRoute } from 'App/methods';
import { useChildMatchById } from 'utilities/hooks/router/useChildMatchById';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import 'App/_app.scss';
import 'assets/icons/ccpicons.css';
import 'config/tokens/_cssVariables.scss';
import 'styles/_anim.scss';
import 'styles/_common.scss';
import 'styles/_form.scss';
import 'styles/_reset.scss';
import { useIsAuthenticated } from 'utilities/hooks/redux/useIsAuthenticated';

/**********************************************************************************************************
 *   ROUTE SCHEMA
 **********************************************************************************************************/
const appSchema = z.object({
    'token': z.string().optional(),
    'move_token': z.string().optional(),
    'ref': z.string().optional(),

    /**
     * Used to identify what module should be rendered from the current section. If this is not provided, all will be rendered.
     * This is used by the ModuleRenderer to determine what module to render.
     */
    'module': z.string().optional(),

    /**
     * Used to identify what action was performed on the invoice lightbox URL
     */
    'invoice-action': z.union([z.literal('cancel'), z.literal('pay')]).optional()
});

export type AppSchema = z.infer<typeof appSchema>;

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const AppRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/',
    validateSearch: appSchema.parse,
    beforeLoad({ location, cause, context }) {
        // Do not allow redirects in preload, less risk crashing the router due to bug
        if ('preload' === cause) return;

        if (location.pathname !== '/activate') {
            handleActivation({ context, location });
        }

        if (location.pathname === '/') {
            handleRootRoute();
        }
    },
    pendingComponent: () => <AppLoader fullHeight />,
    component: AppRouteComponent
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function AppRouteComponent() {
    /***** SELECTORS *****/
    const isAuthenticated = useIsAuthenticated();

    /***** HOOKS *****/
    const isActivate = useChildMatchById('/activate');
    const isResetPassword = useChildMatchById('/reset-password/$token');
    const isLogin = useChildMatchById('/login');
    const { pathname, hash } = useLocation();

    /***** REACTIVE REDIRECTS *****/
    if (!isAuthenticated) {
        if (!isLogin && !isActivate && !isResetPassword) {
            console.warn('[DEBUG | /src/App/index.tsx]: User is not authenticated, redirecting to login page');

            return (
                <Navigate
                    to="/login"
                    search={(prev) => ({
                        ...prev,
                        ref: `${pathname}${hash ? `#${hash}` : ''}`
                    })}
                />
            );
        }
    }

    /***** RENDER *****/
    return (
        <WrapWithComponent
            component={GoogleReCaptchaProvider}
            reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_CLIENT_KEY || ''}
            wrap={!isAuthenticated}
        >
            <InvoiceUrlLightbox />
            <AppRouteTemplate template={Config.application}>
                <Outlet />
            </AppRouteTemplate>
        </WrapWithComponent>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import { TextArea } from 'components/Form/TextArea';
import DialogNotification from 'components/Notifications/DialogNotification';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { IndentFlush } from 'containers/katana/components/indentFlush/indentFlush';
import KatanaDescription from 'containers/katana/formFields/description';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getServiceInfoDataFromPath } from 'containers/katana/components/dynamicFormFieldRenderer/methods';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      content: string;
 *      content_type?: 'service_data_path';
 *      description?: string;
 *      field_type: "text" | "textarea";
 *      caveats?: import('containers/katana/types').KatanaNamespace.SectionDefinitions.AllCaveats[];
 * }>}
 */
const DisabledInputFieldCaveatRenderer = (inputData) => {
    const { field_type, content_type, content, description, caveats } = inputData;

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** RENDER HELPERS *****/
    const fieldContent = content_type === 'service_data_path' ? getServiceInfoDataFromPath(katanaServiceId, content) : content;
    const indentFlush = useGetCaveat(CaveatTypes.INDENT_FLUSH, caveats);
    const dialogValueWhenNoValue = useGetCaveat(CaveatTypes.DIALOG_NOTIFICATION_WHEN_NO_VALUE, caveats);

    const elementProps = { disabled: true, value: fieldContent, name: fieldContent };

    function renderField() {
        switch (field_type) {
            case 'text':
                return <Input {...elementProps} />;

            case 'textarea':
                return <TextArea {...elementProps} />;

            default:
                return <div>{`field_type: ${field_type} doesn't exist`}</div>;
        }
    }

    function renderContent() {
        if (dialogValueWhenNoValue && typeof dialogValueWhenNoValue === 'string' && !fieldContent) {
            return (
                <DialogNotification noMargin type="warning">
                    <Text black bold size--xss lead--s>
                        {dialogValueWhenNoValue}
                    </Text>
                </DialogNotification>
            );
        }
        return (
            <>
                {renderField()}
                {description ? <KatanaDescription caveats={caveats}>{description}</KatanaDescription> : ''}
            </>
        );
    }

    /***** RENDER *****/
    switch (true) {
        case indentFlush:
            return <IndentFlush>{renderContent()}</IndentFlush>;

        default:
            return renderContent();
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { DisabledInputFieldCaveatRenderer };


/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BrowserBoxViewPortControls from 'components/Lightboxes/BrowserBox/viewPortControls';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSvelteStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SUPER_ACTIONS_KEYS, superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';
import './_BrowserBox.scss';
const { KATANA_IMAX } = SUPER_ACTIONS_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NBrowserBox.Props>}
 */
const BrowserBox = ({
    children,
    className,
    pageSlot,
    controlCirclesContent,
    viewportMode,
    setViewportMode,
    browserBoxPageRef,
    browserBoxHeaderRef,
    browserBoxContentRef
}) => {
    /***** STATE *****/
    const [superUserActions] = useSvelteStore(superUserSuperActionsState);

    /***** RENDER *****/
    return (
        <Flex
            fullHeight
            fullWidth
            justify="center"
            items="center"
            className={classNames('BrowserBox', `BrowserBox--viewportMode-${viewportMode}`, className)}
        >
            <div className="BrowserBox__page" ref={browserBoxPageRef}>
                <div className="BrowserBox__header" ref={browserBoxHeaderRef}>
                    <Flex className="BrowserBox__controls" items="center" gap={4}>
                        {!get(superUserActions, KATANA_IMAX) && (
                            <Flex className="BrowserBox__controlCircles" gap={1}>
                                <div className="BrowserBox__controlCircle">{controlCirclesContent?.[0]}</div>
                                <div className="BrowserBox__controlCircle">{controlCirclesContent?.[1]}</div>
                                <div className="BrowserBox__controlCircle">{controlCirclesContent?.[2]}</div>
                            </Flex>
                        )}
                    </Flex>

                    <BrowserBoxViewPortControls viewportMode={viewportMode} setViewportMode={setViewportMode} />

                    {!get(superUserActions, KATANA_IMAX) && (
                        <div className="BrowserBox__navArrows">
                            <PhosphorIcons.Arrow.Left.Bold faded-light-gray size={20} />
                            <PhosphorIcons.Arrow.Right.Bold faded-light-gray size={20} />
                        </div>
                    )}
                </div>
                <div className="BrowserBox__content" ref={browserBoxContentRef}>
                    {children}
                </div>
                {pageSlot}
            </div>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default BrowserBox;

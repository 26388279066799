/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PasswordChecker.scss';
import { passwordExcludeRequirements, passwordRequirements } from './consts';
import { defaultPasswordRequirements } from './default';

/**********************************************************************************************************
 *   PASSWORD VALIDATIONS
 **********************************************************************************************************/

// Util to perform a list of validations and return the errors
const performValidations = (value, requirements, required = true) => {
    if (!required && !value) return undefined;
    const errors = [];

    requirements.forEach((requirement) => {
        if (!value || !requirement.validation(value)) errors.push(requirement.error);
    });

    if (errors.length > 0) return errors[0];
    return undefined;
};

const validateRequirements = ({ require, exclude }) => {
    require.forEach((requirement) => {
        if (!Object.keys(passwordRequirements).includes(requirement)) {
            throw new Error('Invalid Requirement');
        }
    });

    exclude.forEach((requirement) => {
        if (!Object.keys(passwordExcludeRequirements).includes(requirement)) {
            throw new Error('Invalid Excluded');
        }
    });

    return {
        require,
        exclude
    };
};

/**
 * @param {any} value
 * @param {{
 *      require: string[],
 *      exclude: string[]
 * }} requirements
 * @param {boolean} [required]
 * @returns {String | undefined}
 */
export const validatePasswordCheckerCustomRequirements = (value, { require, exclude }, required = true) => {
    require.forEach((requirement) => {
        if (!Object.keys(passwordRequirements).includes(requirement)) {
            throw new Error('Invalid Requirement');
        }
    });

    exclude.forEach((requirement) => {
        if (!Object.keys(passwordExcludeRequirements).includes(requirement)) {
            throw new Error('Invalid Requirement');
        }
    });

    const requirementToValidate = [
        ...require.map((requirement) => passwordRequirements[requirement]),
        ...exclude.map((requirement) => passwordExcludeRequirements[requirement])
    ];

    return performValidations(value, requirementToValidate, required);
};

// Form field validation function, validates that "password" and "confirm password" are equal
export const validateConfirmValidation = (_, allValues) =>
    allValues && allValues.password_confirmation === allValues.password ? undefined : 'Passwords do not match';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// Helper function for PasswordChecker initial state
const getRequirementKeys = (requirements) => {
    const updatedRequirementKeys = { require: {}, exclude: {} };
    const { require, exclude } = validateRequirements(requirements);

    require.forEach((key) => {
        updatedRequirementKeys.require[key] = false;
    });

    exclude.forEach((key) => {
        updatedRequirementKeys.exclude[key] = false;
    });

    return updatedRequirementKeys;
};

// Password checker component
function PasswordChecker({ input, requirements = defaultPasswordRequirements }) {
    const { require, exclude } = requirements;

    const requirementToValidate = require.map((requirement) => passwordRequirements[requirement]);
    const excludeToValidate = exclude.map((requirement) => passwordExcludeRequirements[requirement]);

    /***** STATE *****/
    const [requirementKeys, setRequirementKeys] = useState(getRequirementKeys({ require, exclude }));

    /***** EFFECTS *****/
    useEffect(() => {
        const updatedRequirementKeys = { require: {}, exclude: {} };

        require.forEach((key, index) => {
            updatedRequirementKeys.require[key] = requirementToValidate[index].validation(input);
        });

        exclude.forEach((key, index) => {
            updatedRequirementKeys.exclude[key] = excludeToValidate[index].validation(input);
        });

        setRequirementKeys(updatedRequirementKeys);
    }, [input]);

    const renderRequirements = (isMet, flag, index) => {
        return (
            <div key={index} className={`PasswordChecker__resultitem${isMet ? '' : ' PasswordChecker__resultitem--invalid'}`}>
                <span className="PasswordChecker__resulticon icon-valid"></span>
                <span className="PasswordChecker__resulttext">{flag}</span>
            </div>
        );
    };

    const getIsMet = (index, type) => {
        const { require, exclude } = requirementKeys;

        if (type === 'require') {
            const key = Object.keys(require)[index];
            return require[key];
        }

        if (type === 'exclude') {
            const key = Object.keys(exclude)[index];
            return exclude[key];
        }

        return false;
    };

    /***** RENDER *****/
    return (
        <div className="PasswordChecker">
            <div className="PasswordChecker__wrapper">
                <div className="PasswordChecker__heading">Your password must contain</div>
                <div className="PasswordChecker__result">
                    {requirementToValidate.map((requirement, index) => renderRequirements(getIsMet(index, 'require'), requirement.flag, index))}
                    {excludeToValidate.map((requirement, index) => renderRequirements(getIsMet(index, 'exclude'), requirement.flag, index))}
                </div>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**
 * Proptypes
 */
PasswordChecker.propTypes = {
    /**
     * The value to check against, this can be undefined to handle empty values but is best set to an empty string
     */
    input: PropTypes.string,

    /**
     * The requirements to check against, this is an object with two arrays, one for requirements and one for exclusions
     */
    requirements: PropTypes.shape({
        require: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(passwordRequirements))),
        exclude: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(passwordExcludeRequirements)))
    })
};

export default PasswordChecker;

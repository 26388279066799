/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaServiceBanner } from 'containers/services/modules/katanaServicesList/katanaServiceBanner';
import KatanaServiceListTable from 'containers/services/modules/katanaServicesList/katanaServiceListTable';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useBooleanStore } from 'utilities/hooks/useBooleanStore';
import { scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServiceBannerStateWritable } from 'containers/services/modules/katanaServicesList/consts';
import './_katanaServicesList.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaServicesList = () => {
    /***** STATE *****/
    const { setTrue: openGetKatana } = useBooleanStore(katanaServiceBannerStateWritable);

    /***** FUNCTIONS *****/
    function handleOnClick() {
        openGetKatana();
        scrollToRef(document.querySelector('.KatanaServiceBanner'));
    }
    /***** RENDER *****/
    return (
        <div className="ServiceOverview KatanaServicesList">
            <Grid columns="1fr 160px">
                <NXBox.Title title="Manage VIPsites" />
                <SolidButton onClick={handleOnClick}>Add a new Website</SolidButton>
            </Grid>
            <KatanaServiceListTable />
            <KatanaServiceBanner />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

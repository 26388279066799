/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/domain';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {Parameters<typeof API.domain.POST.presetPreview>[0]} Params
 */

/**
 * @param {Params} params
 */
function createQueryKey({ id, preset, attributes }) {
    return createBaseQueryKey(/** @type {const} */ (['domain', id, 'dns', 'preset', preset, 'preview-changes', attributes]));
}

/**
 * @param {Params} params
 */
function createQueryOptions({ id, preset, attributes }) {
    return queryOptions({
        queryKey: createQueryKey({ id, preset, attributes }),
        queryFn: () => API.domain.POST.presetPreview({ id, preset, attributes }),
        enabled: Boolean(id && preset && attributes && TruthKeys(attributes)),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**
 * @param {Omit<Params, 'attributes'>} params
 */
function resetQueries({ id, preset }) {
    return queryClient.resetQueries({
        queryKey: createBaseQueryKey(/** @type {const} */ (['domain', id, 'dns', 'preset', preset, 'preview-changes']))
    });
}

/**
 * @param {Omit<Params, 'attributes'>} params
 */
function invalidate({ id, preset }) {
    return queryClient.invalidateQueries({
        queryKey: createBaseQueryKey(/** @type {const} */ (['domain', id, 'dns', 'preset', preset, 'preview-changes']))
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {Params} params
 */
function _useQuery(params) {
    return useQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getDNSPresetPreview = Object.freeze({
    useQuery: _useQuery,
    resetQueries,
    createQueryKey,
    createQueryOptions,
    invalidate
});

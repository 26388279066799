/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Tip from 'components/Tooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxToolTip.scss';

type NXToolTip = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides a tooltip for the NXBox Component. This is positioned absolutely, as such it will be put in the top right of it's closest non-static parent.
 */
const NXToolTip: NXToolTip = ({ children }) => {
    /***** RENDER *****/
    return (
        <div className="NXBoxTooltip">
            <Tip info={children} />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXToolTip;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo, useState } from 'react';
import { get as svelteGet } from 'svelte/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorRenderModules from '../contentEditorRenderModules';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { domainQuery } from 'containers/domain/queries/queryTree';
import { useApplyDNSPresetMutation } from 'containers/domain/queries/useApplyDNSPresetMutation';
import { useEnableKatanaSiteMutation } from 'containers/katana/queries/mutations';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { usePutDomainDNSTypeMutation } from 'containers/domain/queries/usePutDomainDNSTypeMutation';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { API } from 'utilities/api/domain';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    katanaTrialStateSimplifiedLaunch,
    simplifiedFlowLoadingMessage
} from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';

function createPresetPreviewData(check_enable_site_data: KatanaAPI.Katana.ServiceID.ServiceDetails.GET.ReplacedDomainDNSConfigName) {
    return {
        id: check_enable_site_data?.domain_id,
        preset: 'katana' as const,
        attributes: {
            remove_conflicting: true,
            replacements: {
                hostname: check_enable_site_data?.hostname,
                server_hostname: check_enable_site_data?.server_hostname
            }
        }
    };
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {FormHandlerComponent}
 */
const LaunchFormHandler = ({ subpageRouteData }) => {
    /***** STATE *****/
    const [isSubmitting, setIsSubmitting] = useState(false);

    /***** HOOKS *****/
    const { katanaServiceId, page, subpage } = useKatanaParams();
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { mutateAsync: mutateAsyncEnableKatanaSite } = useEnableKatanaSiteMutation(katanaServiceId);
    const { data: check_enable_site_data, isLoading: isCheckKatanaServiceDetailsLoading } =
        katanaQuery.serviceID.getServiceDetails.useQuery(katanaServiceId);
    const { data: get_dns_data, isLoading: isGetDNSLoading } = katanaQuery.serviceID.getDNS.useQuery(katanaServiceId);
    const { mutateAsync: mutatePutDomainDNSType } = usePutDomainDNSTypeMutation({
        id: check_enable_site_data?.domain_id,
        attributes: { dns_type: 'free_dns' }
    });

    const presetPreviewData = createPresetPreviewData(check_enable_site_data);
    const applyDNSPresetMutationOptions = useMemo(() => {
        return page === 'setup' ? { onSuccess: null } : {};
    }, [page]);

    const { mutateAsync: mutateAsyncApplyDNSPreset } = useApplyDNSPresetMutation(presetPreviewData, applyDNSPresetMutationOptions);
    const { isFetching: isGetDNSPresetPreviewFetching } = domainQuery.domainID.getDNSPresetPreview.useQuery(presetPreviewData);

    /***** FUNCTIONS *****/
    async function handleSubmit() {
        setIsSubmitting(true);

        if (svelteGet(katanaTrialStateSimplifiedLaunch)) {
            if (check_enable_site_data?.domain_id) {
                simplifiedFlowLoadingMessage.set('Changing DNS Settings...');

                await mutatePutDomainDNSType();

                simplifiedFlowLoadingMessage.set('Getting updated DNS info...');

                const serviceDetailsData = await katanaQuery.serviceID.getServiceDetails.fetchQuery(id);

                if (serviceDetailsData.status !== 200) {
                    setIsSubmitting(false);
                    simplifiedFlowLoadingMessage.set('');
                    return;
                }
                simplifiedFlowLoadingMessage.set('Applying DNS Presets...');

                await API.domain.POST.presetApply(createPresetPreviewData(serviceDetailsData.data));
            }

            simplifiedFlowLoadingMessage.set('🚀 Launching...');
            await mutateAsyncEnableKatanaSite({
                finalize: true
            });

            katanaTrialStateSimplifiedLaunch.set(false);
        } else {
            if (!get_dns_data?.is_dns_configured && check_enable_site_data?.domain_id) {
                try {
                    await mutateAsyncApplyDNSPreset();
                } catch (e) {
                    return;
                }
            }

            try {
                await mutateAsyncEnableKatanaSite({
                    finalize: true
                });
            } catch (e) {
                return;
            }
        }

        setIsSubmitting(false);
        simplifiedFlowLoadingMessage.set('');

        performRouting();

        return;
    }

    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isCheckKatanaServiceDetailsLoading && !isSubmitting, message: 'Checking Service Details...' },
        { condition: isGetDNSLoading && !isSubmitting, message: 'Loading DNS Records...' },
        { condition: isGetDNSPresetPreviewFetching && !isSubmitting, message: 'Loading DNS Preset...' }
    ]);

    /***** RENDER *****/
    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            <ContentEditorRenderModules subpageRouteData={subpageRouteData} subpage={subpage} onSubmit={handleSubmit} initialValues={{}} />
        </RequestLoader.MultiLoader>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { LaunchFormHandler };

